import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
const projectId = process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID;

// 2. Set chains
const optimism = {
  chainId: process.env.NEXT_PUBLIC_CONTRACT_CHAIN_ID,
  name: process.env.NEXT_PUBLIC_CONTRACT_CHAIN_NAME,
  currency: 'ETH',
  explorerUrl: process.env.NEXT_PUBLIC_CONTRACT_EXPLORER_URI,
  rpcUrl: process.env.CONTRACT_PROVIDER_URI
};

// 3. Create a metadata object
const metadata = {
  name: 'Artoys app',
  description: 'Artoys app',
  url: 'https://artoys.app',
  // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,
  /*Optional*/
  enableEIP6963: true,
  // true by default
  enableInjected: true,
  // true by default
  enableCoinbase: true,
  // true by default
  rpcUrl: '...',
  // used for the Coinbase SDK
  defaultChainId: 1 // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [optimism],
  projectId
  // enableAnalytics: true, // Optional - defaults to your Cloud configuration
  // enableOnramp: true // Optional - false as default
});
export const Web3Modal = ({
  children
}) => children;