export const goldPlans = {
    'gold.plans.text_0': 'Ваш стартовый пакет для путешествия по миру ChikoRoko! Эта скидка предоставляется только один раз, так что не упустите свой шанс!',
    'gold.plans.level_0': 'Стартовый пакет',
    'gold.plans.text_1': 'Маленькие шаги — верный путь к большим достижениям. Начните путешествие в мир ChikoRoko с этим паком!',
    'gold.plans.text_2': 'Этот пак для одержимых коллекционеров, которые знают проект и то, чего они хотят!',
    'gold.plans.text_3': 'Кто будет самым крутым и богатым ЧикоРокером? Может быть, вы? У вас есть шанс стать самым крутым с этим паком!',
    'gold.plans.text_4': 'Вы — король ChikoRoko! Мы уже готовим для вас корону!',
    'gold.plans.level_1': 'Новичок',
    'gold.plans.level_2': 'Коллекционер',
    'gold.plans.level_3': 'Супер-коллекционер',
    'gold.plans.level_4': 'Истинный ЧикоРокер',
}
