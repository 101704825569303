export const sellToyPage = {
    'sell.toy.page.title': 'Продажа игрушки',
    'sell.toy.page.confirm.description': '10% комиссии будет удержано от итоговой цены продажи в качестве роялти авторам и платформе',
    'sell.toy.page.label.toy.price': 'Цена игрушки',
    'sell.toy.page.label.select.currency': 'Выберите валюту',
    'sell.toy.page.input.placeholder': 'Введите цену игрушки',
    'sell.toy.page.minimum.price.description': 'Минимальная цена продажи: <price></price>',
    'sell.toy.page.modal.title': 'Доступно',
    'sell.toy.page.delete.modal.title': 'Снять с продажи',
    'sell.toy.page.delete.modal.text': 'Пожалуйста, подтвердите, что хотите снять эту игрушку с продажи. Вы можете вернуть её в продажу в любое время',
}
