import { dropsKeys } from '../en/drops'

export const drops: Record<dropsKeys, string> = {
    'drops.DAILY': '每日',
    'drops.WEEKLY': '每周',
    'drops.ARTCLUB': '艺术俱乐部',
    'drops.GIFT': '礼物',
    'drops.SECRET': '秘密',
    'drops.BLINDBOX': '盲盒',
    'drops.SECRET_PERSONAL': '个人私密',
    'drops.ONBOARDING': 'Welcome gift',
    'drops.RETRO': '怀旧',
    'drops.PARTNER': '合作伙伴',
    'drops.COLLECTION_BONUS': '收藏奖励',
    'drops.BUY': '付费掉落',

    'drops.QUEST': '',
    'drops.BLINDBOX_FULLSET': '',
    'drops.BLINDBOX_OPEN': '',
    'drops.BLINDBOX_RESERVATION': '',
    'drops.DIGITAL': '',
}
