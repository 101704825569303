import { enType } from '../en'
import { artistsPage } from './artistsPage'
import { authPage } from './authPage'
import { buttons } from './buttons'
import { cartPage } from './cartPage'
import { collectionPage } from './collectionPage'
import { collectionsPage } from './collectionsPage'
import { createToy } from './createToy'
import { drops } from './drops'
import { errorPages } from './errorPages'
import { errors } from './errors'
import { explorePage } from './explorePage'
import { faqPage } from './faqPage'
import { feedPage } from './feedPage'
import { footer } from './footer'
import { gamesPage } from './gamesPage'
import { goldPlans } from './goldPlans'
import { header } from './header'
import { myToysPage } from './myToysPage'
import { news } from './news'
import { paymentHistoryPage } from './paymentHistoryPage'
import { paymentsPage } from './paymentsPage'
import { postPage } from './postPage'
import { privacyPage } from './privacyPage'
import { profilePage } from './profilePage'
import { publicProfile } from './publicProfile'
import { referralAwards } from './referralAwards'
import { sellToyPage } from './sellToyPage'
import { settingsPage } from './settingsPage'
import { sorting } from './sorting'
import { termsOfUsePage } from './termsOfUsePage'
import { toyPage } from './toyPage'
import { toys } from './toys'
import { ui } from './ui'
import { withdrawPage } from './withdrawPage'
import { quiz } from './quiz'
import { block } from './block'
import { report } from './report'

const zh: Record<enType, string> = {
    ...quiz,
    ...ui,
    ...header,
    ...footer,
    ...feedPage,
    ...profilePage,
    ...explorePage,
    ...myToysPage,
    ...cartPage,
    ...paymentsPage,
    ...collectionPage,
    ...sellToyPage,
    ...withdrawPage,
    ...postPage,
    ...gamesPage,
    ...settingsPage,
    ...errorPages,
    ...authPage,
    ...toyPage,
    ...sorting,
    ...goldPlans,
    ...errors,
    ...buttons,
    ...toys,
    ...publicProfile,
    ...privacyPage,
    ...news,
    ...termsOfUsePage,
    ...faqPage,
    ...createToy,
    ...collectionsPage,
    ...artistsPage,
    ...paymentHistoryPage,
    ...drops,
    ...referralAwards,
    ...block,
    ...report,
}

export default zh
