export const report = {
    'report.reason.title': 'Отчет',
    'report.reason.subtitle': 'Почему вы отправляете отчет об этом {target}?',
    'report.reason.text': 'Ваш отчет анонимный. Не волнуйтесь. Пожалуйста, позвоните в экстренные службы, если кто-то в опасности.',
    'report.reason.dislike': 'Я просто не люблю это',
    'report.reason.bullying': 'Буллинг или нежелательный контакт',
    'report.reason.selfHarm': 'Самоубийство, самоповреждение или анорексия',
    'report.reason.violence': 'Насилие, ненависть или эксплуатация',
    'report.reason.restrictedItems': 'Продажа или пропаганда ограниченных товаров',
    'report.reason.adultContent': 'Нагота или сексуальная активность',
    'report.reason.scam': 'Мошенничество, мошенничество или спам',
    'report.reason.falseInformation': 'Неверная информация',
    'report.block.subtitle': 'Спасибо за ваш отзыв',
    'report.block.text': 'Мы используем эти отчеты, чтобы показать вам меньше этого вида контента в будущем',
    'report.block.question': 'Хотите заблокировать автора?',
    'report.block.yes': 'Да, заблокируйте этого артиста, пожалуйста',
    'report.block.no': 'Я не хочу блокировать этого автора.',
    'report.target.post': 'пост',
    'report.target.author': 'автор',
}
