export const ui = {
    'count.toys': '<count></count> 玩具',
    edit: '编辑',
    'toy.id': '玩具编号',
    gold: '金币',
    ruby: '红宝石',
    history: '历史',
    toys: '玩具',
    competition: '比赛',
    ads: '广告',
    subscription: '订阅',
    sell: '出售',
    sellers: '卖家',
    'select.your.country': '选择你的国家',
    'select.your.payment.method': '选择你的付款方式',
    'link.copied': '链接已复制',
    'empty.list.title': "It's empty",
    'empty.list.text': "You may have been looking for something, but it's not here",
    successful: '成功！',
    unsuccessful: '不成功',
    info: '信息',
    'any.count': '任何金额',
    collected: '已收集',
    'empty.request': '未找到与你的请求相关的内容。 <br/> 请更改你的查询',
    rubies: 'Ruby宝石',
    verified: '已验证',
    'kyc.processed': '申请 KYC（处理中）',
    'kyc.apply': '申请 KYC',
    'kyc.error': '失败，请重试',
    'provider.facebook': 'Facebook',
    'provider.google': 'Google',
    'provider.tiktok': 'Tiktok',
    'provider.telegram': 'Telegram',
    'provider.vk': 'VK',
    'provider.discord': 'Discord',
    'provider.wallet': '钱包',
    'provider.instagram': 'Instagram',
    'provider.x': 'X',
    'nihaopay.confirming.payment': '确认付款中...',
    'nihaopay.confirming.payment.failed': '无法确认付款！',
    'nihaopay.generate.invoice': '请稍等...我们创建账单中！',
    'video.ads.adBlock': '只有关闭 <span>AdBlock</span>你才能获得该奖励',
    'video.ads.success.watched': '已成功观看影片，请稍候',
    'video.ads.reject.watched': '你必须观看影片至少60秒才能赢得奖品',
    'social.chat.eng': '英文群',
    'social.chat.cn': '中文群',
    'social.chat.rus': '俄语群',
    'drag.to.move': '拖动以移动',
    'doublecheck.your.email': '请再次检查您的邮箱',
    'doublecheck.your.nickname': '请再次检查您的昵称',
    'file.uploaded': '{type} 已上传',
    'placeholder.title': '标题',
    'placeholder.write.your.thoughts': '写下你的想法...',
    'enter.collection.name': '输入收藏名称',
    'unavailable.post.title': '帖子不可用',
    'unavailable.post.text': '对不起，这个帖子目前不可用。它可能已被删除或隐藏。请稍后再试。',
    'enter.artist.name': '输入艺术家姓名',
}
