export const paymentHistoryPage = {
    'payment.history.page.title.purchase': 'Purchase',
    'payment.history.page.title.collected.drop': 'Collected {dropType} Drop',
    'payment.history.page.title.purchase.offer': 'Purchase Offer',
    'payment.history.page.title.sale': 'Sale',
    'payment.history.page.title.commission': 'Commission',
    'payment.history.page.title.burned': 'Burned',
    'payment.history.page.title.return': 'Return',
    'payment.history.page.title.subscription': 'Subscription',
    'payment.history.page.title.sharing': 'Sharing',
    'payment.history.page.title.referral': 'Referral',
    'payment.history.page.title.games': 'Games',
    'payment.history.page.title.social.networks': 'Social Networks',
    'payment.history.page.title.registration': 'Registration',
    'payment.history.page.title.drop.skipped': 'Drop Skipped',
    'payment.history.page.title.accrual': 'Accrual',
    'payment.history.page.title.royalty': 'Royalty',
    'payment.history.page.title.withdrawal': 'Withdrawal',
    'payment.history.page.title.error': 'Error',
    'payment.history.page.title.migration': 'Migration',
    'payment.history.page.title.quest': 'Quest',

    'payment.history.page.text.purchase': '{toyName} {packName} purchase',
    'payment.history.page.text.collected.drop': '{toyName} collected',
    'payment.history.page.text.purchase.offer': '{toyName} offer',
    'payment.history.page.text.gold.purchase': 'Gold Purchase',
    'payment.history.page.text.ruby.purchase': 'Ruby Purchase',
    'payment.history.page.text.sale': '{toyName} sale',
    'payment.history.page.text.commission': 'For {toyName} sale',
    'payment.history.page.text.burned': '{toyName} burned',
    'payment.history.page.text.blindbox.purchase': '{blindboxName} purchase',
    'payment.history.page.text.return': '{toyName} returned',
    'payment.history.page.text.subscription': 'Bonus gold for {monthCount} month subscription',
    'payment.history.page.text.sharing.confirmation': 'Bonus for sharing confirmation',
    'payment.history.page.text.sharing.post': 'Bonus for sharing post',
    'payment.history.page.text.board.purchase': '{boardName} purchase',
    'payment.history.page.text.games.won': 'Game won',
    'payment.history.page.text.games.lost': 'Game lost',
    'payment.history.page.text.registration': 'Bonus for registration',
    'payment.history.page.text.games.purchased': 'Game purchased',
    'payment.history.page.text.drop.skipped': 'Random drop timer skipped',
    'payment.history.page.text.account.connection': 'Bonus for account connection',
    'payment.history.page.text.accrual.ruby': 'Ruby accrual by the admin',
    'payment.history.page.text.accrual.gold': 'Gold accrual by the admin',
    'payment.history.page.text.return.ruby': 'Ruby return by the admin',
    'payment.history.page.text.return.gold': 'Gold return by the admin',
    'payment.history.page.text.accrual.gold.promocode': 'Gold accrual by promocode',
    'payment.history.page.text.accrual.ruby.promocode': 'Ruby accrual by promocode',
    'payment.history.page.text.royalty': 'Royalty for {toyName} buy',
    'payment.history.page.text.withdrawal.ruby': 'Ruby withdrawal',
    'payment.history.page.text.error': 'Withdrawal declined',
    'payment.history.page.text.referral.award': '{referralAward}',
    'payment.history.page.text.board': 'Board',
}
