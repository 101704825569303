export const buttons = {
    'btn.view.all': '查看全部',
    'btn.see.more': '查看更多',
    'btn.join': '加入',
    'btn.collect.now': '立即领取',
    'btn.collected': '已领取！',
    'btn.buy.now': '立即购买',
    'btn.join.now': '现在就加入！',
    'btn.share': '分享',
    'btn.bring.to.life': '赋予生命',
    'btn.to.figure': '查看玩偶',
    'btn.full.collection': '完整系列',
    'btn.confirm.sale': '确认出售',
    'btn.confirm': '确认',
    'btn.save': '保存',
    'btn.marketplace': '市场',
    'btn.accept': '确定',
    'btn.report': '举报',
    'btn.cancel': '取消',
    'btn.unblock': '取消阻止',
    'btn.reset': '重置',
    'btn.go.to.main': '返回主页',
    'btn.select': '选择玩偶',
    'btn.open.next': '打开下一个',
    'btn.close': '关闭',
    'btn.loading': '正在加载...',
    'btn.buy.ticket': '购买一张票',
    'btn.change.photo': '更改照片',
    'btn.change': '更改',
    'btn.delete': '删除',
    'btn.save.changes': '保存更改',
    'btn.sell.now': '立即出售',
    'btn.view.more': '查看更多',
    'btn.burn.now': '立即燃烧',
    'btn.ok': '确定',
    'btn.try.again': '请重试',
    'btn.skip': '跳过',
    'btn.collect': '收集',
    'btn.go.to.cart': '前往购物车',
    'btn.go.to.shop': '前往商店',
    'btn.play': '游戏',
    'btn.explore': '探索',
    'btn.create.board': '已创建展示板',
    'btn.put.on.sale': '出售',
    'btn.buy.gold': '购买金币',
    'btn.go.to.toys': '查看玩具',
    'btn.go.subscribe.now': '立即订阅',
    'btn.go.watch.a.video': '观看影片',
    'btn.go.complete.tasks': '完成任务',
    'btn.go.watch.ads': '观看视频',
    'btn.join.art.club': '加入艺术俱乐部',
    'btn.go.to.explore': '前往探索',
    'btn.continue': '继续',
    'btn.cancel.selling': '取消出售',
    'btn.sell': '出售',
    'btn.buy': '购买',
    'btn.burn': '燃烧',
    'btn.apply': '应用',
    'btn.see.all': '查看全部',
    'btn.cancel.subscription': '取消订阅',
    'btn.support.chat': '支持中心',
    'btn.send': '发送',
    'btn.send.request': '发送请求',
    'btn.check': '检查',
    'btn.load.more': '加载更多',
    'btn.download': '下载',
    'btn.renew.subscription': '续订',
    'btn.make.offer': '提出报价',
    'btn.claim': '认领',
    'btn.sign': '签署',
    'btn.signed': '已签署',
    'btn.connect.wallet': '连接钱包',
    'btn.claim.now': '立即认领',
    'btn.transfer': '转移',
    'btn.paid': '已支付',
    'btn.get.premium': '获取高级会员',
    'btn.invite.friends': '邀请好友并赚取',
    'btn.collect.toys': '收集玩具',
    'btn.buy.full.set': '购买全套',
    'btn.buy.one.more': '再买一个盲盒',
    'btn.sell.blindbox': '出售我的盲盒',
    'btn.how.it.works': '如何使用',
    'btn.open': '打开',
    'btn.not.available': '不可用',
    'btn.go.to.blindbox': '转到盲盒',
    'btn.edit.profile': '编辑个人资料',
    'btn.analytics': '分析',
    'btn.generate.qr': '生成二维码',
    'btn.download.qr': '下载二维码',
    'btn.change.image': '更改封面图片',
    'btn.edit.draft': '编辑草稿',
    'btn.on.moderation': '正在审核',
    'btn.change.info': '更改信息',
    'btn.show.analytics': '显示分析',
    'btn.upload.photo': '上传照片',
    'btn.edit': '编辑',
    'btn.buy.currency': '购买货币',
    'btn.go.to.my.toys': '查看我的玩具',
    'btn.new.post': '新帖子',
    'btn.crypto': '加密货币',
    'btn.credit.card': '信用卡',
    'btn.withdraw': '提现',
    'btn.go.to.chat': '转到聊天群',
    'btn.login': '登录',
    'btn.go.to.feed.page': '转到消息页面',
    'btn.wallet': '钱包',
    'btn.connect.to.wallet': '连接钱包',
    'btn.connect.to.facebook': '连接Facebook',
    'btn.connect.to.google': '连接谷歌',
    'btn.connect.to.telegram': '连接Telegram',
    'btn.connect.to.vk': '连接VK',
    'btn.connect.to.discord': '连接Discord',
    'btn.disconnect': '断开连接',
    'btn.support': '支持',
    'btn.delete.account': '删除账户',
    'btn.saved': '保存',
    'btn.back': '返回',
    'btn.show.more': '显示更多',
    'btn.show.less': '显示更少',
    'btn.publish': '发布',
    'btn.upload.image': '上传图片',
    'btn.upload.renders': '上传渲染图',
    'btn.remove': '删除',
    'btn.add.more': '添加更多',
    'btn.create.toy': '创建玩具',
    'btn.follow': '关注',
    'btn.following': '已关注',
    'btn.unfollow': '取消关注',
    'btn.store': '商店',
    'btn.generate': '生成',
    'btn.create.new': '创建新的',
    'btn.block.this.author': '阻止此作者',
    'btn.report.this.author': '举报该作者',
    'btn.go.to.author': '前往作者页面',
    'btn.block': '阻止',
}
