export const toyPage = {
    'toy.card.image.title': '{name} | Коллекционные дизайнерские игрушки на R.Toys',
    'toy.page.image.title': '{name} | Коллекционные дизайнерские игрушки на R.Toys',
    'toy.page.title': '{name} от {artistName} | Коллекционные дизайнерские игрушки на R.Toys',
    'toy.page.description': '{name} - уникальная игрушка, созданная {artistName}. Откройте для себя больше о коллекционных дизайнерских игрушках на R.Toys.',
    'toy.page.artist.by': 'от {artistName}',
    'toy.page.part.of.collection': 'Часть коллекции «{collectionName}»',
    'toy.page.stats.release': 'Выпуск',
    'toy.page.stats.collected': 'Собрано',
    'toy.page.stats.burned': 'Сожжено',
    'toy.page.about.toy.title': 'Об игрушке',
    'toy.page.collection.title': 'Коллекция',
    'toy.page.collection.stats.collected': 'Собрано',
    'toy.page.collection.stats.toys': 'Игрушки',
    'toy.page.artist.title': 'Об авторе',
    'toy.page.artist.stats.followers': 'Подписчики',
    'toy.page.artist.stats.toys': 'Игрушки',
    'toy.page.offers.title': '<count></count> предложений',
    'toy.page.history.title': 'История',
    'toy.page.history.date.text': '{date} в {time}',
    'toy.page.you.might.like': 'Вам может понравиться',
    'toy.page.added.to.cart.title': '«{name}» добавлен в корзину',
    'toy.page.added.to.cart.description': 'Перейдите в корзину для покупки',
    'toy.page.ar.unavailable.title': 'Просмотр в AR недоступен',
    'toy.page.ar.unavailable.description': 'Чтобы увидеть игрушку в AR, необходимо ее приобрести',
    'toy.page.ar.available.title': 'Просмотр в AR',
    'toy.page.ar.available.description': 'Чтобы просмотреть этот подарок в 3D и AR, откройте эту страницу на смартфоне',
    'toy.page.toy.collected.modal.title': '«{name}» теперь ваша!',
    'toy.page.toy.collected.modal.description': 'Вы успешно выполнили задание и получили эту игрушку. Ищите ее на странице Мои игрушки',
    'toy.page.collect.buying.modal.title': 'Покупка',
    'toy.page.created': 'Создано',
    'toy.page.task.title': 'Выполните задание и получите игрушку',
    'toy.page.task.follow': 'Подписаться на художника',
    'toy.page.task.complete': 'После выполнения вышеуказанных шагов вы можете забрать игрушку. Поздравляем!',
    'toy.page.sell.without.instance.id.description': "You can't sell this toy until an instance ID is assigned",
    'toy.page.digital.toy': 'Цифровая игрушка',
    'toy.page.digital.twin': 'Цифровой двойник',
    'toy.page.generate.code.modal.title': 'Сгенерировать код',
    'toy.page.generate.code.modal.input.label': 'Количество кодов',
    'toy.page.generate.code.modal.input.description': 'Введите количество кодов, которые нужно сгенерировать',
    'toy.page.generate.code.modal.amount.on.page': 'Количество элементов на странице',
    'toy.page.generate.code.modal.download.title': 'Готово!',
    'toy.page.generate.code.modal.download.text': 'Скачивание начнется автоматически через...',
    'toy.page.generated.codes.title': 'Сгенерированные коды',
    'toy.page.download.codes.modal.title': 'Скачать',
    'toy.page.generated.codes.empty.text': 'Пока нет сгенерированных кодов',
    'toy.page.collect.the.toy': 'Соберите игрушку',
}
