import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useRef } from 'react';
const PreviousURLContext = createContext<string | null>(null);
export const PreviousURLProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    asPath
  } = useRouter();
  const asPathRef = useRef<string | null>(null);
  useEffect(() => {
    asPathRef.current = asPath;
  }, [asPath]);
  return <PreviousURLContext.Provider value={asPathRef.current} data-sentry-element="unknown" data-sentry-component="PreviousURLProvider" data-sentry-source-file="PreviousURLContext.tsx">{children}</PreviousURLContext.Provider>;
};
const usePreviousURL = () => useContext(PreviousURLContext);
export default usePreviousURL;