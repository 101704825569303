import React, { FC, useState } from 'react';
import s from './QuizFinalStage.module.scss';
import { Button } from '@components/UIKit/Button/Button';
import { Textarea } from '@components/UIKit/Textarea/Textarea';
import { useTranslation } from '@hooks/useTranslation';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { useCreateQuiz } from '@hooks/TRPC_Hooks/useCreateQuiz';
import { QuizDataItem } from '../QuizQuestionsTypeData';
export interface IData {
  quizData: QuizDataItem[];
  convenient: number;
  comment: string;
}
interface QuizFinalStageProps {
  quizData: QuizDataItem[];
  handleEndSurvey: () => void;
}
export const QuizFinalStage: FC<QuizFinalStageProps> = ({
  handleEndSurvey,
  quizData
}) => {
  const {
    translate: tr
  } = useTranslation();
  const [convenient, setConvenient] = useState(0);
  const [comment, setComment] = useState('');
  const {
    mutateAsync: saveQuizResult,
    isLoading
  } = useCreateQuiz();
  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value
    } = e.target;
    setConvenient(Number(value));
  };
  const handleSaveResults = async () => {
    const quiz = JSON.stringify(quizData);
    const body = {
      quiz,
      convenient,
      comment
    };
    await saveQuizResult(body);
    handleEndSurvey();
  };
  const min = 0;
  const max = 5;
  return <>
            <div className={s.container}>
                <p className={s.container__ratingLabel}>{tr({
          id: 'quiz.how.convenient'
        })}</p>
                <div className={s.container__smileBox}>
                    <div className={s.container__smileIcon} style={{
          left: `calc(28px + (${(convenient - min) / (max - min)} * (100% - ${92 - convenient * 0.2}px)))`
        }}>
                        <SpriteIcon name={convenient <= 2 ? 'sad-smile' : convenient >= 5 ? 'happy-smile' : 'neutral-smile'} width={'20px'} height={'20px'} data-sentry-element="SpriteIcon" data-sentry-source-file="QuizFinalStage.tsx" />
                    </div>
                </div>

                <input className={s.container__slider} type='range' min={min} max={max} step={1} value={convenient} onChange={handleSliderChange} style={{
        background: convenient === max ? 'var(--Thamar-Black)' : `linear-gradient(to right, 
                    var(--Thamar-Black) calc(28px + (${convenient} * (100% - 56px) / ${max})),
                    white calc(28px + (${convenient} * (100% - 56px) / ${max}))
                )`
      }} />

                <Textarea label={tr({
        id: 'quiz.comment.label'
      })} value={comment} onPrint={setComment} placeholder={tr({
        id: 'quiz.comment.placeholder'
      })} className={s.container__textarea} maxLength={1000} data-sentry-element="Textarea" data-sentry-source-file="QuizFinalStage.tsx" />
            </div>
            <Button id='default_button' size='large' color='Black' loading={isLoading} onClick={handleSaveResults} data-sentry-element="Button" data-sentry-source-file="QuizFinalStage.tsx">
                {tr({
        id: 'btn.send'
      })}
            </Button>
        </>;
};