import { referralAwardsKeys } from '../en/referralAwards'

export const referralAwards: Record<referralAwardsKeys, string> = {
    'award.REFFERAL_5DROPS': 'Referral collected 5 or more toys',
    'award.REFERRAL_INVITE': 'The user provided the first referral',
    'award.REFERRAL_SUBSCRIBTION': 'Referral purchased a subscription',
    'award.REFERRAL_SUBSCRIBTION_MONTH': 'Referral purchased a 1-month subscription',
    'award.REFERRAL_SUBSCRIBTION_HALF_YEAR': 'Referral purchased a 6-month subscription',
    'award.REFERRAL_SUBSCRIBTION_YEAR': 'Referral purchased a 12-month subscription',
    'award.REFERRAL_GOLD_PURCHASE': 'Referral purchased a gold',
}
