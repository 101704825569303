export const createToy = {
    'create.toy.title': '创建玩具',
    'create.toy.general.information.title': '基本信息',
    'create.toy.digital.toy': '数字玩具',
    'create.toy.digital.twin': '数字孪生',
    'create.toy.upload.image.uploaded': '已上传',
    'create.toy.preferred.formats.description': '首选格式：{formats}',
    'create.toy.additional.files': '附加文件（可选）',
    'create.toy.toy.name.label': '玩具名称',
    'create.toy.toy.name.placeholder': '在此输入',
    'create.toy.toy.name.description': '创建玩具的名称',
    'create.toy.description.label': '描述（可选）',
    'create.toy.description.placeholder': '介绍一下你的玩具',
    'create.toy.alternative.collect.label': '替代收集方式',
    'create.toy.alternative.collect.description': '替代收集方式是通过执行特定操作（如订阅作者、观看视频或完成任务）来获得玩具的一种方法。',
    'create.toy.circulation.label': '流通量',
    'create.toy.circulation.description': '数量越少，稀有度越高',
    'create.toy.price.title': '价格',
    'create.toy.price.description': '输入 {minPrice} 到你的最大值之间的价格',
    'create.toy.communication.method': '首选沟通方式',
    'create.toy.default.email.label': '默认电子邮件',
    'create.toy.email.label': '电子邮件',
    'create.toy.required.email.label': '电子邮件（必填）',
    'create.toy.telegram.label': 'Telegram',
    'create.toy.instagram.label': 'Instagram',
    'create.toy.forgot.error': '你忘了加“@”',
    'create.toy.privacy.text': '提交此表单即表示您同意我们的<privacy>隐私政策</privacy>和<terms>使用条款</terms>。您的信息将被严格保密，未经您同意不会与第三方共享。',
    'create.toy.digital.toy.modal.title': '数字玩具',
    'create.toy.digital.toy.modal.description': '此类玩具可通过购买窗口（非购物车）在用户页面和探索页面上被其他用户购买。来自艺术家的报价总是优先显示。',
    'create.toy.digital.twin.modal.title': '数字孪生',
    'create.toy.digital.twin.modal.description': '此类玩具可通过扫描二维码被其他用户收集，生成一个用于收集的秘密链接。艺术家将这些二维码附在实体玩具上。',
    'create.toy.upload.render.error.max.files': '最多可上传 2 个文件。',
    'create.toy.upload.render.error.only.one': '只能上传 1 个 {type} 文件。',
    'create.toy.something.is.wrong': '出现问题',
    'create.toy.uploading.progress': '上传中...',
    'create.toy.might.take.bit.time': '这可能需要一点时间',
}
