export const footer = {
    'footer.title': '与我们一起轻松进入 Web3 ！',
    'footer.join': '加入 Telegram 中的全球收藏家社区！',
    'footer.choose.theme': '选择主题',
    'footer.dark': '深色',
    'footer.light': '浅色',
    'footer.link_1': '关于项目',
    'footer.link_2': '技术支持',
    'footer.link_3': '隐私政策',
    'footer.link_4': '常见问题',
    'footer.link_5': '成为合作伙伴',
    'footer.link_6': '成为艺术家',
    'footer.link_7': '使用条款',
    'footer.telegram.title': '加入Telegram上的国际收藏家社区！',
    'footer.telegram.placeholder': '选择你的语言聊天群',
}
