export const sellToyPage = {
    'sell.toy.page.title': 'Selling a toy',
    'sell.toy.page.confirm.description': 'A 10% commission will be taken from the final sale price for royalties to authors and the platform',
    'sell.toy.page.label.toy.price': 'Toy price',
    'sell.toy.page.label.select.currency': 'Select currency',
    'sell.toy.page.input.placeholder': 'Enter a toy price',
    'sell.toy.page.minimum.price.description': 'Minimum sale price: <price></price>',
    'sell.toy.page.modal.title': 'Available',
    'sell.toy.page.delete.modal.title': 'Remove from Sale',
    'sell.toy.page.delete.modal.text': 'Please confirm that you want to remove this toy from sale. You can return it at any time',
}
