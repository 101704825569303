export const block = {
    'block.modal.title': '阻止作者',
    'block.modal.description': '你真的要阻止这个作者吗？你可以在任何时候取消阻止',
    'block.success.title': '阻止作者',
    'block.success.description': '{author} 已被阻止。任何其他账户或创建的账户也将被阻止。',
    'unblock.modal.title': '解除 {author} 的阻止',
    'unblock.modal.description': '{author} 和其他账户现在可以查看你的资料并关注你。他们不会被通知你已经解除了对你的关注。',
    'unblock.success.title': '{author} 已解除阻止',
    'unblock.success.description': '你可以从他们的资料中阻止他们',
}
