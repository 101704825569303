export const ui = {
    'count.toys': '<count></count> игрушек',
    edit: 'Редактировать',
    'toy.id': 'ID игрушки',
    gold: 'Золото',
    ruby: 'Рубины',
    history: 'История',
    toys: 'Игрушки',
    competition: 'Соревнование',
    ads: 'Реклама',
    subscription: 'Подписка',
    sell: 'Продажа',
    sellers: 'Продавцы',
    'select.your.country': 'Выберите страну',
    'select.your.payment.method': 'Выберите способ оплаты',
    'link.copied': 'Ссылка скопирована',
    'empty.list.title': 'Нет предложений',
    'empty.list.text': 'Вы можете стать первым и разместить здесь своё предложение!',
    successful: 'Успешно!',
    unsuccessful: 'Не удалось',
    'unavailable.post.title': 'Пост недоступен',
    'unavailable.post.text': 'Извините, этот пост в настоящее время недоступен. Он может быть удален или скрыт автором. Пожалуйста, проверьте позже.',
    info: 'Информация',
    'any.count': 'Любое количество',
    collected: 'Собрано',
    'empty.request': 'По вашему запросу ничего не найдено. <br/> Пожалуйста, измените запрос.',
    rubies: 'Рубины',
    verified: 'Проверено',
    'kyc.processed': 'Подать заявку на KYC (в процессе)',
    'kyc.apply': 'Подать заявку на KYC',
    'kyc.error': 'Не удалось, попробуйте снова',
    'provider.facebook': 'Facebook',
    'provider.google': 'Google',
    'provider.tiktok': 'Tiktok',
    'provider.telegram': 'Telegram',
    'provider.vk': 'ВКонтакте',
    'provider.discord': 'Discord',
    'provider.wallet': 'Кошелек',
    'provider.instagram': 'Instagram',
    'provider.x': 'X',
    'nihaopay.confirming.payment': 'Подтверждение оплаты...',
    'nihaopay.confirming.payment.failed': 'Не удалось подтвердить оплату!',
    'nihaopay.generate.invoice': 'Подождите минуту... мы создаем счёт!',
    'video.ads.adBlock': 'Награда доступна только после отключения <span>AdBlock</span>',
    'video.ads.success.watched': 'видео было успешно просмотрено',
    'video.ads.reject.watched': 'Чтобы забрать подарок, смотрите видео не менее 60 секунд',
    'social.chat.eng': 'Английский чат',
    'social.chat.cn': 'Китайский чат',
    'social.chat.rus': 'Русский чат',
    'drag.to.move': 'Перетащите, чтобы переместить',
    'doublecheck.your.email': 'Перепроверьте ваш email',
    'doublecheck.your.nickname': 'Перепроверьте ваш никнейм',
    'file.uploaded': '{type} загружен',
    'placeholder.title': 'Заголовок',
    'placeholder.write.your.thoughts': 'Поделитесь своими мыслями...',
    'enter.collection.name': 'Введите название коллекции',
    'enter.artist.name': 'Введите имя художника',
}
