export const faqPage = {
    'faq.page.block_1.h1': '关于我们',
    'faq.page.block_1.item_1.question': '什么是 ArToys？',
    'faq.page.block_1.item_1.answer': '<li>                            它是汇集艺术玩具设计师、品牌和收藏家的唯一平台，在设计师玩具行业的数字和实体收藏品之间架起了一座桥梁。\n                        </li>',
    'faq.page.block_1.item_2.question': '我可以用数字玩具做什么？',
    'faq.page.block_1.item_2.answer':
        '\n                        <li>\n                            你可以用 3D 格式或增强现实（AR）技术查看它们，还可以用它们拍照和录影。在不久的将来，你还可以在游戏中定制和使用它们。\n                        </li>',
    'faq.page.block_2.h1': '网站',
    'faq.page.block_2.item_1.question': '如何领取数字收藏品？',
    'faq.page.block_2.item_1.answer': '\n                        <li>\n                            领取你的玩具，请登录并点击网站上的“立即领取”按钮。艺术玩具将被添加到你的收藏中。\n                        </li>',
    'faq.page.block_2.item_2.question': '哪些设备支持 AR？',
    'faq.page.block_2.item_2.answer':
        '\n                        <li>\n                            大多数现代设备都支持 AR 和 3D 展示。以下是支持 AR 的 <a href="https://www.apple.com/augmented-reality/" target="_blank" rel="noreferrer">iOS\n                            设备</a>  列表。 以下是支持 AR 的 <a href="https://developers.google.com/ar/devices" target="_blank" rel="noreferrer">安卓设备</a> that\n                            列表。 为了达到最佳\n                            体验，我们建议使用安装了激光雷达扫描仪的设备（iPhone 12+、iPad Pro）。\n                        </li>',
    'faq.page.block_2.item_3.question': '数字收藏品需要付费还是免费的？',
    'faq.page.block_2.item_3.answer': '\n                        <li>\n                            艺术玩具每天免费发放。\n                        </li>',
    'faq.page.block_2.item_4.question': '艺术玩具的数量是否有限？如果是，我如何知道还剩多少玩具？',
    'faq.page.block_2.item_4.answer': '\n                        <li>\n                            每种玩具的发行数量是有限的。流通中的玩具数量显示在玩具页面上。\n                        </li>',
    'faq.page.block_2.item_5.question': '用户是否可以在平台上更改自己的电子邮箱？',
    'faq.page.block_2.item_5.answer': '\n                        <li>\n                            很遗憾，电子邮箱无法更改。\n                            </li>',
    'faq.page.block_2.item_6.question': '你打算开发一款移动应用程序吗？',
    'faq.page.block_2.item_6.answer': '\n                        <li>\n                            是的！正在开发中。\n                        </li>',
    'faq.page.block_2.item_7.question': '我想完成一项任务，但收到一条“拒绝访问”的信息。',
    'faq.page.block_2.item_7.answer': '\n                        <li>\n                            你可能已启用 VPN 或代理。请禁用它们以完成任务。\n                        </li>',
    'faq.page.block_3.h1': '金币',
    'faq.page.block_3.item_1.question': '什么是金币，如何使用？',
    'faq.page.block_3.item_1.answer': '\n                        <li>\n                            金币是平台的内部货币。您可以用它来收集盲盒、在社区市场上购买玩具和开设新的展示板。\n                        </li>',
    'faq.page.block_3.item_2.question': '在哪里可以看到我账户中的金币？',
    'faq.page.block_3.item_2.answer': '\n                        <li>\n                            你可以在平台的右上角看到你拥有的金币数量。\n                        </li>',
    'faq.page.block_5.h1': '社区市场',
    'faq.page.block_5.item_1.question': '什么是社区市场，如何使用？',
    'faq.page.block_5.item_1.answer':
        '\n                        <li>\n                            社区市场是一个可以买卖玩具的地方。在这里，你可以看到不同的报价，找到自己需要的玩具。你还可以出售自己的玩具，赚取金币。\n                        </li>',
    'faq.page.block_5.item_2.question': '如何为我想出售的玩具设定价格？',
    'faq.page.block_5.item_2.answer': '\n                        <li>\n                            这是一个自由市场，所以价格由你来决定。在设定价格时，许多因素都很重要。\n                        </li>',
    'faq.page.block_5.item_3.question': '稀有度会影响玩具的价格吗？',
    'faq.page.block_5.item_3.answer': '\n                        <li>\n                            是的。玩具的价格会受到很多因素的影响。\n                        </li>',
    'faq.page.block_5.item_4.question': '我可以取消出售我的玩具吗？',
    'faq.page.block_5.item_4.answer': '\n                        <li>\n                            在玩具出售给其他用户之前，你可以随时取消出售。进入玩具页面，点击“取消出售”按钮。\n                        </li>',
    'faq.page.block_5.item_5.question': '我需要支付交易费用还是免费？',
    'faq.page.block_5.item_5.answer': '\n                        <li>\n                            我们收取玩具成交价格的 10%，在艺术家和平台之间分配。\n                        </li>',
    'faq.page.title': '常见 <span>问题</span>',
    'faq.page.block_.h1': '',
    'faq.page.block_.item_.question': '',
    'faq.page.block_.item_.answer': '',
}
