export const paymentsPage = {
    'payments.pages.currency.title': 'Buy currency',
    'payments.pages.tab.history': 'History',
    'payments.pages.checkout.currency.modal.title': 'Checkout',
    'payments.pages.checkout.currency.modal.to.buy': 'To buy',
    'payments.pages.checkout.currency.modal.price.in.dollars': 'Price in Dollars',
    'payments.pages.withdraw.card.title': 'Withdraw',
    'payments.pages.withdraw.card.description': 'You can always convert your rubies into dollars on our platform (minimum withdrawal amount 100 rubies)',
    'payments.pages.history.will.be.soon': 'This section will be coming soon',
    'payments.pages.payment.toast.success.text': 'Your balance has been replenished',
    'payments.pages.payment.toast.error.text': "There's been an error, try paying again.",
}
