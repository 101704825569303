export const report = {
    'report.reason.title': '举报',
    'report.reason.subtitle': '为什么你要举报这个{target}?',
    'report.reason.text': '您的举报是匿名的。请不要担心。如果您遇到紧急情况，请拨打紧急服务电话。',
    'report.reason.dislike': '我只是不喜欢这个',
    'report.reason.bullying': '欺凌或不受欢迎的接触',
    'report.reason.selfHarm': '自杀、自残或厌食症',
    'report.reason.violence': '暴力、仇恨或剥削',
    'report.reason.restrictedItems': '销售或推广限制性商品',
    'report.reason.adultContent': '裸露或性活动',
    'report.reason.scam': '诈骗、诈骗或垃圾邮件',
    'report.reason.falseInformation': '错误信息',
    'report.block.subtitle': '谢谢您的反馈',
    'report.block.text': '我们使用这些报告来显示更少的此类内容',
    'report.block.question': '你想封杀作者吗？',
    'report.block.yes': '是的，请封杀这位艺术家',
    'report.block.no': '我不想屏蔽这位作者',
    'report.target.post': '帖子',
    'report.target.author': '艺术家',
}
