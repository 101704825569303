export const block = {
    'block.modal.title': 'Заблокировать автора',
    'block.modal.description': 'Вы действительно хотите заблокировать этого автора? Вы можете разблокировать в любое время',
    'block.success.title': 'Автор заблокирован',
    'block.success.description': '{author} заблокирован. Любые другие аккаунты, которые он может иметь или создать, также заблокированы.',
    'unblock.modal.title': 'Разблокировать {author}',
    'unblock.modal.description': '{author} и другие аккаунты, которые он может иметь или создать, теперь смогут видеть ваш профиль и следовать. Они не будут уведомлены о том, что вы разблокировали их.',
    'unblock.success.title': '{author} разблокирован',
    'unblock.success.description': 'Вы можете заблокировать их из их профиля',
}
