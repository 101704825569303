export const cartPage = {
    'cart.page.title': 'Корзина',
    'cart.page.select.all': 'Выбрать все',
    'cart.page.delete.selected': 'Удалить выбранное',
    'cart.page.buy.this.toy.only': 'Купить только эту игрушку',
    'cart.page.to.buy': 'Купить',
    'cart.page.price.in.rubies': 'Цена в Рубинах',
    'cart.page.price.in.gold': 'Цена в Золоте',
    'cart.page.your.cart.is.empty.title': 'Ваша корзина пуста',
    'cart.page.your.cart.is.empty.description': 'Загляните в раздел Исследовать или воспользуйтесь поиском, чтобы найти арт-игрушки',
    'cart.page.available.modal.title': 'Доступно',
    'cart.page.available.modal.input.placeholder': 'Введите номер игрушки',
    'cart.page.delete.toy.modal.title': 'Удалить игрушку',
    'cart.page.delete.toy.modal.description': 'Вы уверены, что хотите удалить выбранную игрушку? Отменить это действие будет невозможно',
    'cart.page.delete.toys.modal.title': 'Удалить игрушки',
    'cart.page.delete.toys.modal.description': 'Вы уверены, что хотите удалить выбранные игрушки? Отменить это действие будет невозможно',
    'cart.page.insufficient.balance.modal.title': 'Недостаточно средств',
    'cart.page.insufficient.balance.modal.description': 'Купите необходимую сумму валюты для успешной покупки',
    'cart.page.insufficient.balance.modal.text': 'У вас недостаточно средств для покупки',
    'cart.page.purchase.modal.title': 'Вы собираетесь купить эту арт-игрушку',
    'cart.page.purchase.modal.description': 'Нажмите кнопку «Купить», чтобы завершить покупку',
    'cart.page.toy.unavailable.modal.title': 'Игрушка недоступна',
    'cart.page.toy.unavailable.modal.description': 'Игрушка с указанной ценой и номером больше недоступна',
}
