import React, { FC, useEffect, useState } from 'react';
import { Modal } from '@components/Modals/Modal';
import { QuizNotification } from './QuizNotification/QuizNotification';
import { QuizSurvey } from './QuizSurvey/QuizSurvey';
import { QuizEnd } from './QuizEnd/QuizEnd';
interface Props {
  closeHandler: () => void;
  isLoading?: boolean;
}
export const Quiz: FC<Props> = ({
  closeHandler,
  isLoading
}) => {
  const [screen, setScreen] = useState<string>('notification');
  useEffect(() => {
    // Блокируем скролл при открытии модального окна
    document.body.style.overflow = 'hidden';
    return () => {
      // Восстанавливаем скролл после закрытия модального окна
      document.body.style.overflow = '';
    };
  }, []);
  const onCloseHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    closeHandler();
  };

  // const onClick () => setScreen('survey')
  return <Modal isOpen onClose={closeHandler} shouldCloseOnOverlayClick={true} data-sentry-element="Modal" data-sentry-component="Quiz" data-sentry-source-file="Quiz.tsx">
            {screen === 'notification' ? <QuizNotification closeHandler={onCloseHandler} isLoading={isLoading} /> : screen === 'survey' ? <QuizSurvey handleEndSurvey={() => setScreen('end')} /> : <QuizEnd closeHandler={closeHandler} />}
        </Modal>;
};