import { FC, SVGProps } from 'react';
import cn from 'classnames';
import { ReformattingColors } from '@helpers/arToysHelpers';
import { ColorsEnumKeys } from '@specs/colors';
export interface SpriteIconProps extends Omit<SVGProps<SVGSVGElement>, 'fill' | 'fillRule' | 'fillOpacity'> {
  name: SpriteIconsKeys;
  color?: ColorsEnumKeys | string;
  colored?: boolean;
}
const defaultSize = 24;
const date = new Date().getTime();
export const SpriteIcon: FC<SpriteIconProps> = ({
  name,
  color = 'currentColor',
  colored = false,
  height = defaultSize,
  width = defaultSize,
  ...props
}) => {
  const fill = ReformattingColors(color.startsWith('--') ? `var(${color})` : color);
  const path = colored ? `/sprite_icons_colored.svg?#icon-${name}` : `/sprite_icons.svg?v=${date}#icon-${name}`;
  return <svg {...props} className={cn(props.className, `_icon`)} fill={colored ? undefined : fill} width={width} height={height} data-sentry-element="svg" data-sentry-component="SpriteIcon" data-sentry-source-file="SpriteIcon.tsx">
            <use href={path} data-sentry-element="use" data-sentry-source-file="SpriteIcon.tsx" />
        </svg>;
};
export enum SpriteIconsEnum {
  'feed',
  'feed_active',
  'explore',
  'explore_active',
  'games',
  'games_active',
  'plus',
  'logo',
  'arrow_down_small',
  'burger_menu',
  'close',
  'gold',
  'ruby',
  'settings',
  'faq',
  'support',
  'logout',
  'telegram',
  'instagram',
  'x',
  'tiktok',
  'discord',
  'youtube',
  'vk',
  'facebook',
  'medium',
  'twitch',
  'google',
  'posts',
  'toys',
  'user',
  'user_active',
  'search',
  'arrow_down',
  'warning',
  'cart',
  'history',
  'trash',
  'share',
  'minus',
  'close_small',
  'light',
  'arrow_right_medium',
  'check',
  'edit',
  'eye',
  'heart',
  'withdraw',
  'user-inactive',
  'upload',
  'wallet',
  'apple',
  'info',
  'ar_available',
  'ar_lock',
  'photo',
  'avatar_default',
  'arrow_up',
  'Link_XL',
  'bold',
  'italic',
  'blockquote',
  'link',
  'empty_toy',
  'dots',
  'common',
  'rare',
  'super',
  'epic',
  'legendary',
  'mythical',
  'one',
  'physical',
  'digital',
  'gold_out',
  'ruby_out',
  'ruby_in',
  'commission',
  'royalty',
  'gold_in',
  'declined',
  'burned_toy',
  'social_share',
  'sad-smile',
  'neutral-smile',
  'happy-smile',
}
export enum SpriteIconsColoredEnum {
  'avatar_admin',
  'empty_cart',
  'coming_soon',
  'avatar_platform',
}
export type SpriteIconsKeys = keyof typeof SpriteIconsEnum | keyof typeof SpriteIconsColoredEnum;