import { IntlShape, useIntl } from 'react-intl'
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core'
import { MessageFormatElement } from '@formatjs/icu-messageformat-parser'
import ruDictionary from '../lang/ru'
import enDictionary from '../lang/en'

export type Dictionary = keyof Pick<typeof enDictionary, keyof typeof ruDictionary>

interface MessageDescriptor {
    id?: Dictionary
    description?: string | object
    defaultMessage?: string | MessageFormatElement[]
}

export type translateType = (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>, opts?: IntlMessageFormatOptions) => string

interface useTranslationProps {
    translate: translateType
    intl: IntlShape
}

type useTranslationType = () => useTranslationProps

export const useTranslation: useTranslationType = () => {
    const intl = useIntl()
    return { translate: intl.formatMessage, intl }
}
