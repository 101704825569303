import { api, ReactQueryOptions, RouterInputs } from '@api'

type optionsType = ReactQueryOptions['SocialManager']['getPublicProfile']
type inputType = RouterInputs['SocialManager']['getPublicProfile']

export const useGetPublicProfile = (input: inputType, options?: optionsType) =>
    api.SocialManager.getPublicProfile.useQuery(input, {
        ...options,
        queryKey: ['SocialManager.getPublicProfile', input],
        refetchOnWindowFocus: false,
        // staleTime: 60 * 60 * 1000 * staleTimeIndex // 1 hour
        staleTime: 10 * 60 * 1000 // 10 mins  TODO удалить после обновления даннных художников
    })
