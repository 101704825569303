export const footer = {
    'footer.title': 'Ease into collecting toys with us!',
    'footer.join': 'Join a global community <br/> of collectors in Telegram!',
    'footer.choose.theme': 'Choose theme',
    'footer.dark': 'Dark',
    'footer.light': 'Light',
    'footer.link_1': 'About project',
    'footer.link_2': 'Technical Support',
    'footer.link_3': 'Privacy Policy',
    'footer.link_4': 'FAQ',
    'footer.link_5': 'Become a Partner',
    'footer.link_6': 'Apply as an Artist',
    'footer.link_7': 'Terms of use',
    'footer.telegram.title': 'Join the international community <br/> of collectors on Telegram!',
    'footer.telegram.placeholder': 'Select your language chat',
}
