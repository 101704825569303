export const explorePage = {
    'explore.page.title': '探索',
    'explore.page.image.title.default': '如需了解更多信息，请单击卡片',
    'explore.page.purchase.rejected.title': '购买失败',
    'explore.page.purchase.rejected.description': '购买失败。请重试',
    'explore.page.purchase.success.title': '购买成功！',
    'explore.page.purchase.success_many.description': '购买的玩具可以在 "我的玩具" 页面上找到',
    'explore.page.purchase.success_one.description': '购买的玩具可以在 "我的玩具" 页面上找到',
    'explore.page.trending.toys.title': '热门玩具',
    'explore.page.popular.artists.title': '知名艺术家',
    'explore.page.physical.artists.title': '实体玩具',
}
