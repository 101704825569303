import React, { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import s from './QuizNotification.module.scss';
import { useTranslation } from '@hooks/useTranslation';
import { Button } from '@components/UIKit/Button/Button';
interface Props {
  closeHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
}
const MOBILE_BREAKPOINT = 768;
export const QuizNotification: FC<Props> = ({
  closeHandler,
  isLoading
}) => {
  const router = useRouter();
  const {
    translate: tr
  } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const formUrl = router.locale === 'ru' ? 'https://forms.gle/wsFG9vue9VHDxg3f9' : 'https://forms.gle/CvFQPEYpuWgs3XV6A';
  const imgUrl = router.locale === 'ru' ? '/assets/images/quiz/survey-eng-min-ru.png' : '/assets/images/quiz/survey-eng-min.png';
  return <div className={s.container} data-sentry-component="QuizNotification" data-sentry-source-file="QuizNotification.tsx">
            <Image src={imgUrl} alt='Rate the app' width={isMobile ? 336 : 562} height={isMobile ? 204 : 342} objectFit='cover' priority={true} loading='eager' data-sentry-element="Image" data-sentry-source-file="QuizNotification.tsx" />
            <div className={s.container__content}>
                <h2 className={s.container__header}>{tr({
          id: 'quiz.greetings'
        })}</h2>
                <p className={s.container__text}>
                    {tr({
          id: 'quiz.description'
        })}
                    <span className={s.container__text__span}>{tr({
            id: 'quiz.time' as any
          })}</span>
                </p>
                <p>
                    🔗 &nbsp;
                    <a href={formUrl} className={s.container__link} target='_blank' rel='noopener noreferrer' onClick={e => closeHandler(e as any)}>
                        {tr({
            id: 'quiz.survey_link' as any
          })}
                    </a>
                </p>

                <p className={s.container__text}>
                    {tr({
          id: 'quiz.take_part' as any
        })}
                    <span className={s.container__text__span}>{tr({
            id: 'quiz.interview' as any
          })}</span>
                    {tr({
          id: 'quiz.take_part_2' as any
        })}
                </p>

                <div className={s.container__link_wrapper}>
                    ✉️
                    <div>
                        <span className={s.container__text__spacing}>Email: </span>
                        <a href='mailto:a.karasev@infty.art' target='_blank' rel='noopener noreferrer'>
                            a.karasev@infty.art
                        </a>
                    </div>
                </div>
                <div className={s.container__link_wrapper}>
                    <span>📱</span>
                    <button className={s.container__button_link} onClick={() => window.open('https://t.me/aakrsv', '_blank')} aria-label={tr({
          id: 'quiz.telegram'
        })}>
                        <p className={s.container__text__spacing_link}>{tr({
              id: 'quiz.telegram'
            })}</p>
                    </button>
                </div>
            </div>

            <Button className={s.container__btn} id='default_button' size='large' color='Black' onClick={closeHandler} loading={isLoading} data-sentry-element="Button" data-sentry-source-file="QuizNotification.tsx">
                {tr({
        id: 'quiz.go'
      })}
            </Button>
        </div>;
};