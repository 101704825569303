export const toyPage = {
    'toy.card.image.title': '{name} | Collectible designer toys on R.Toys',
    'toy.page.image.title': '{name} | Collectible designer toys at R.Toys',
    'toy.page.title': '{name} by {artistName} | Collectible designer toys at R.Toys',
    'toy.page.description': '{name} - is a unique toy created by {artistName} Discover more about collectible designer toys at R.Toys.',
    'toy.page.artist.by': 'by {artistName}',
    'toy.page.part.of.collection': 'Part of the collection “{collectionName}”',
    'toy.page.stats.release': 'Release',
    'toy.page.stats.collected': 'Collected',
    'toy.page.stats.burned': 'Burned',
    'toy.page.about.toy.title': 'About toy',
    'toy.page.collection.title': 'Collection',
    'toy.page.collection.stats.collected': 'Collected',
    'toy.page.collection.stats.toys': 'Toys',
    'toy.page.artist.title': 'About Artist',
    'toy.page.artist.stats.followers': 'Followers',
    'toy.page.artist.stats.toys': 'Toys',
    'toy.page.offers.title': '<count></count> offers',
    'toy.page.history.title': 'History',
    'toy.page.history.date.text': '{date} at {time}',
    'toy.page.you.might.like': 'You might like',
    'toy.page.added.to.cart.title': '“{name}” added to your cart',
    'toy.page.added.to.cart.description': 'Go to cart to purchase',
    'toy.page.ar.unavailable.title': 'AR view unavailable',
    'toy.page.ar.unavailable.description': 'To view the toy in AR it must be purchased',
    'toy.page.ar.available.title': 'View in AR',
    'toy.page.ar.available.description': 'To view this gift in 3D and AR, please open this page on your smartphone',
    'toy.page.toy.collected.modal.title': '{name} is now yours!',
    'toy.page.toy.collected.modal.description': 'You have successfully completed the assignment and you get this toy. Look for it on the My toys page',
    'toy.page.collect.buying.modal.title': 'Buying',
    'toy.page.created': 'Created',
    'toy.page.task.title': 'Complete task and get a toy',
    'toy.page.task.follow': 'Follow artist',
    'toy.page.task.complete': 'After completing the steps above, you can collect the toy. Congratulations!',
    'toy.page.sell.without.instance.id.description': "You can't sell this toy until an instance ID is assigned",
    'toy.page.digital.toy': 'Digital Toy',
    'toy.page.digital.twin': 'Digital Twin',
    'toy.page.generate.code.modal.title': 'Generate Code',
    'toy.page.generate.code.modal.input.label': 'Amount of Codes',
    'toy.page.generate.code.modal.input.description': 'Type the amount of codes you need to generate',
    'toy.page.generate.code.modal.amount.on.page': 'Amount of Elements on the Page',
    'toy.page.generate.code.modal.download.title': 'Done!',
    'toy.page.generate.code.modal.download.text': 'The download will start automatically in...',
    'toy.page.generated.codes.title': 'Generated Codes',
    'toy.page.download.codes.modal.title': 'Download',
    'toy.page.generated.codes.empty.text': 'No generated codes yet',
    'toy.page.collect.the.toy': 'Collect the toy',
}
