import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { getCookie, setCookie } from 'cookies-next';
import { Quiz } from '@components/Modals/Variants/Quiz/Quiz';
import { useProfile } from '@hooks/TRPC_Hooks/useProfile';
import { useGetPublicProfile } from '@hooks/TRPC_Hooks/useGetPublicProfile';
interface Props {
  children: ReactNode;
}
export const FeedbackProvider: FC<Props> = ({
  children
}) => {
  const {
    data: profile,
    isLoading: profileLoading
  } = useProfile();
  const {
    data: publicProfile,
    isLoading: publicProfileLoading
  } = useGetPublicProfile({
    shortId: profile?.shortid ?? ''
  }, {
    enabled: !!profile
  });
  const artistProfileId = publicProfile?.artistProfile?.id;
  const isLoadingData = profileLoading || publicProfileLoading;
  const {
    status
  } = useSession();
  const {
    pathname
  } = useRouter();
  const [isOpenModal, setIsOpenModal] = useState(false);
  useEffect(() => {
    if (isLoadingData || typeof window === 'undefined' || status !== 'authenticated' || pathname.includes('/admin')) {
      return;
    }
    const viewedUsersCookie = getCookie('quizViewedUsers');
    const viewedUsers = viewedUsersCookie ? JSON.parse(viewedUsersCookie as string) : {};
    if (artistProfileId && !viewedUsers[artistProfileId]) {
      setIsOpenModal(true);
    }
  }, [artistProfileId, isLoadingData, status, pathname]);
  const handleCloseModal = () => {
    if (isLoadingData || !artistProfileId) return;
    const viewedUsersCookie = getCookie('quizViewedUsers');
    const viewedUsers = viewedUsersCookie ? JSON.parse(viewedUsersCookie as string) : {};
    viewedUsers[artistProfileId] = true;
    setCookie('quizViewedUsers', JSON.stringify(viewedUsers), {
      maxAge: 60 * 60 * 24 * 365 * 5,
      // 5 years
      path: '/'
    });
    setIsOpenModal(false);
  };
  return <>
            {children}
            {isOpenModal && <Quiz closeHandler={handleCloseModal} isLoading={isLoadingData} />}
        </>;
};