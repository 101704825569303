import React, { FC, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import s from './checkbox.module.scss';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'checked'> {
  checked: boolean;
  boxClassName?: string;
  labelClassName?: string;
  label?: React.ReactNode;
  variant?: 'circle' | 'square';
}
export const Checkbox: FC<Props> = ({
  label,
  checked,
  boxClassName,
  labelClassName,
  type = 'checkbox',
  variant = 'square',
  className,
  disabled,
  onChange,
  ...props
}) => {
  return <label tabIndex={0} className={cn(s.checkbox, variant === 'circle' && s.checkbox_circle, checked && s.checkbox_active, disabled && s.checkbox_disabled, '_noneSelect', boxClassName)} onKeyDown={e => {
    if (e.key !== 'Enter') return;
    const input = (e.target as HTMLLabelElement).getElementsByTagName('input')[0];
    input.click();
  }} data-sentry-component="Checkbox" data-sentry-source-file="Checkbox.tsx">
            <span className={s.checkbox__indicator}>
                {checked && variant !== 'circle' && <SpriteIcon name={'check'} width={16} height={16} color={'--white'} />}
            </span>
            {label && <span className={cn(s.checkbox__text, labelClassName)}>{label}</span>}
            <input type={type} checked={checked} className={cn(s.checkbox__input, className)} disabled={disabled} onChange={onChange} {...props} />
        </label>;
};