export const settingsPage = {
    'settings.page.title': 'Settings',
    'settings.page.upload': 'Upload photo',
    'settings.page.username': 'Username',
    'settings.page.email': 'Email',
    'settings.page.name': 'Name',
    'settings.page.surname': 'Surname',
    'settings.page.country': 'Country',
    'settings.page.social.media': 'Connect social media',
    'settings.page.delete.account': 'Delete Account',
    'settings.page.save': 'Save',
    'settings.page.social.unlink.modal.title': 'Disconnect social network',
    'settings.page.social.unlink.modal.description': 'Do you confirm that you want to disconnect a social media account from your profile? It will not be possible to cancel this action',
    'settings.page.usernameinfo.modal.title': 'Username info',
    'settings.page.delete.account.modal.title': 'Delete Account',
    'settings.page.delete.account.modal.description': 'Do you really want to delete your account on the R.Toys platform? Please contact our support team to do so',
    'settings.page.delete.avatar.modal.title': 'Delete a photo',
    'settings.page.delete.avatar.modal.description': 'Are you sure you want to delete an uploaded photo? It is not possible to cancel this action',
    'settings.page.bio': 'Bio',
    'settings.page.bio.placeholder': 'Tell us about yourself. Unless you’ll have an ugly white block on your profile :(',
}
