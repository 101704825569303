export const withdrawPage = {
    'withdraw.page.title': 'Вывод',
    'withdraw.page.tab.withdraw': 'вывод',
    'withdraw.page.tab.history': 'История',
    'withdraw.page.country.input.title': 'Страна',
    'withdraw.page.country.input.placeholder': 'Выберите страну',
    'withdraw.page.account.holder.input.title': 'Владелец счёта (ФИО)',
    'withdraw.page.account.holder.input.placeholder': 'Введите ФИО владельца счёта',
    'withdraw.page.bank.name.input.title': 'Название банка',
    'withdraw.page.bank.name.input.placeholder': 'Введите название банка',
    'withdraw.page.account.number.input.title': 'Номер счёта',
    'withdraw.page.account.number.input.placeholder': 'Введите номер счёта',
    'withdraw.page.iban.input.title': 'IBAN',
    'withdraw.page.iban.input.placeholder': 'Введите IBAN',
    'withdraw.page.swift.input.title': 'SWIFT код',
    'withdraw.page.swift.input.placeholder': 'Введите SWIFT код',
    'withdraw.page.manually.title': 'Ручной ввод',
    'withdraw.page.min.withdrawal.description': 'Минимальная сумма для вывода: <br/> {count} рубинов',
    'withdraw.page.input.amount.ruby.placeholder': 'Введите количество рубинов',
    'withdraw.page.kyc.title': 'Сначала пройдите верификацию',
    'withdraw.page.your.balance': 'Ваш баланс',
    'withdraw.page.summary.title': 'Всего',
    'withdraw.page.summary.support': 'Возникла проблема? Обратитесь в нашу поддержку',
}
