export const authPage = {
    'auth.page.incorrectly.email': 'Адрес введен некорректно',
    'auth.page.title': 'Начать',
    'auth.page.tab.login': 'Войти',
    'auth.page.tab.register': 'Регистрация',
    'auth.page.email.input.label': 'Электронная почта',
    'auth.page.email.input.placeholder': 'Введите вашу электронную почту',
    'auth.page.checkbox.label': 'Я согласен на обработку моих персональных данных в соответствии с <privacy>Политикой конфиденциальности</privacy> и <terms>Условиями использования</terms>',
    'auth.page.having.trouble': 'Возникли проблемы?',
    'auth.page.code.title': 'Проверьте вашу почту <br/> для подтверждения аккаунта',
    'auth.page.code.text': 'Мы отправили вам код из 4 цифр',
    'auth.page.code.resend': 'Отправить код повторно',
    'auth.page.continue.with': 'Продолжить с',
    'auth.page.sing.in.wallet': 'Войти через кошелек',
    'auth.page.connect.wallet': 'Подключить кошелек',
}
