export const privacyPage = {
    'privacy.page.title': 'Privacy policy',
    'privacy.page.block_1.h2': '',
    'privacy.page.block_1.text': `
        <p>Last updated October 4, 2024</p>
        <p>
           This Privacy Notice for Infty Art FZ LLC ("we," "us," or "our"), describes how and why we might access,
            collect, store, use, and/or share ("process") your personal information when you use our services ("Services"), including when you:
        </p>
        <ul>
            <li>Visit our website at r.toys, or any website of ours that links to this Privacy Notice</li>
            <li>Download and use our Facebook application (R.Toys), or any other application of ours that links to this Privacy Notice</li>
            <li>Engage with us in other related ways, including any sales, marketing, or events</li>
        <p>
            <b>Questions or concerns?</b> Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services.
        </p>`,

    'privacy.page.block_2.h2': 'SUMMARY OF KEY POINTS',
    'privacy.page.block_2.text': `
        <b>
            This summary provides key points from our Privacy Notice, but you can find out more details 
            about any of these topics by clicking the link following each key point or by using our <a>table of contents</a> 
            below to find the section you are looking for.
        </b>
        <p>
        <b>What personal information do we process?</b> When you visit, use, or navigate our Services, 
        we may process personal information depending on how you interact with us and the Services, the choices you make, 
        and the products and features you use. Learn more about <a>personal information you disclose to us</a>.
        </p>
        <p><b>Do we process any sensitive personal information?</b> Some of the information may be considered "special" or "sensitive" in certain jurisdictions, for example your racial or 
        ethnic origins, sexual orientation, and religious beliefs. We do not process sensitive personal information.
        </p>
        <p><b>Do we collect any information from third parties?</b> We do not collect any information from third parties. </p>
        <p><b>How do we process your information?</b> We process your information to provide, improve, and administer our Services, communicate with you, 
        for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about  <a>how we process your information</a>.
        </p>
        <p><b>In what situations and with which types of parties do we share personal information?</b> We may share information in specific 
            situations and with specific categories of third parties. <a>Learn more about when and with whom we share your personal information.</a>
        </p>
        <p><b>How do we keep your information safe?</b> We have adequate organizational and technical processes and procedures in place to protect your personal information. 
            However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, 
            so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our 
            security and improperly collect, access, steal, or modify your information. <a>Learn more about how we keep your information safe</a>.
        </p>
        <p>
            <b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain 
            rights regarding your personal information. Learn more about <a>your privacy rights</a>.
        </p>
        <p>
            <b>How do you exercise your rights?</b> The easiest way to exercise your rights is by contacting us. We will consider and act upon any 
            request in accordance with applicable data protection laws.
            Want to learn more about what we do with any information we collect? <a>Review the Privacy Notice in full</a>.
        </p>

        `,

    'privacy.page.block_3.h2': 'TABLE OF CONTENTS',
    'privacy.page.block_3.text': `
        <ol>
            <li>1. <a href="#personal-information">WHAT INFORMATION DO WE COLLECT?</a></li>
            <li>2. <a href="#third-party-services">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
            <li>3. <a href="#cookies">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a></li>
            <li>4. <a href="#data-portability">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
            <li>5. <a href="#third-party-links">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
            <li>6. <a href="#changes-to-this-privacy-policy">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></li>
            <li>7. <a href="#contact-us">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a></li>
            <li>8. <a href="#children">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
            <li>9. <a href="#your-rights">HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
            <li>10. <a href="#your-rights">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
            <li>11. <a href="#your-rights">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
            <li>12. <a href="#your-rights">DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
            <li>13. <a href="#your-rights">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
            <li>14. <a href="#your-rights">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
        </ol>
    `,

    'privacy.page.block_4.h2': '1. WHAT INFORMATION DO WE COLLECT?',
    'privacy.page.block_4.text': `
        <p><b>Personal information you disclose to us</b></p>
        <p>
            <b>In Short:</b> We collect personal information that you provide to us.
        </p>
        <p>
           We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
        </p>
        <p>
        <b>Privacy of children</b>. We do not knowingly collect any Personal Information from children under the age of 18. If you are under the age of 18, please do not submit any Personal Information through the Website and Services. If you have reason to believe that a child under the age of 18 has provided Personal Information to us through the Website and Services, please contact us to request that we delete that child’s Personal Information from our Services.
        </p>
        <p>
            <b>Personal Information Provided by You.</b> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following: <
        </p>
        <ul>
            <li>names</li>
            <li>phone numbers</li>
            <li>email addresses</li>
            <li>mailing addresses</li>
            <li>usernames</li>
            <li>passwords</li>
            <li>contact preferences</li>
            <li>contact or authentication data
            <li>billing addresses</li>
            <li>debit/credit card numbers</li>
        </ul>
        <p><b>Sensitive Information. We do not process sensitive information.</b></p>
        <p>
            <b>Payment Data.</b> 
            We may collect data necessary to process your payment if you choose to make purchases, such as your payment 
            instrument number, and the security code associated with your payment instrument. All payment data is handled 
            and stored by Stripe, Robokassa, Nihaopay. You may find their privacy notice link(s) 
            here: <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>,  <a href="https://robokassa.com/privacy-policy">https://robokassa.com/privacy-policy/</a>, <a href="https://privacy-center.nihaopay.com/privacy-center.html#page=PrivacyPolicy">https://privacy-center.nihaopay.com/privacy-center.html#page=PrivacyPolicy</a>.
        </p>
        <p>
        <b>Social Media Login Data.</b> We may provide you with the option to register with us using your existing social media 
        account details, like your Facebook, X, or other social media account. If you choose to register in this way, we will collect certain profile information about you from the social media provider, as described in the section called "<a>HOW DO WE HANDLE YOUR SOCIAL LOGINS?"</a> below. account
        </p>
        <p>
            <b>Application Data.</b> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
        </p>
        <ul>
            <li>
            <p>Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, 
            and manufacturer), operating system, version information and system configuration information, device 
            and application identification numbers, browser type and version, hardware model Internet service provider 
            and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), 
            we may also collect information about the phone network associated with your mobile device, your mobile device’s 
            operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and 
            information about the features of our application(s) you accessed.
            <br>
            <br>
            This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.
            <br>
            <br>
            All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
            <br>
            <br>
            <b>Information automatically collected</b>
            <br>
            <br>
            <b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services. 

            <br>
            <br>
            We automatically collect certain information when you visit, use, or navigate the Services. 
            This information does not reveal your specific identity (like your name or contact information) but may 
            include device and usage information, such as your IP address, browser and device characteristics, operating 
            system, language preferences, referring URLs, device name, country, location, information about how and when you 
            use our Services, and other technical information. This information is primarily needed to maintain the security 
            and operation of our Services, and for our internal analytics and reporting purposes.
            <br>
            <br>
            Like many businesses, we also collect information through cookies and similar technologies. The information we collect includes:
            </p>
            </li>
            <li>
            Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).
            </li>
            <li>
            Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
            </li>
            <li>
            <p>
            Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
            <br>
            <br>
            <b>Google API</b>
            <br>
            <br>
            Our use of information received from Google APIs will adhere to <a>Google API Services User Data Policy</a>, including the <a>Limited Use requirements</a>. 
            <br>
            <br>
            <b>Information collected when you use our Facebook application(s).</b> We by default access your Facebook basic 
            account information, including your name, email, gender, birthday, current city, and profile picture URL, 
            as well as other information that you choose to make public. We may also request access to other permissions 
            related to your account, such as friends, check-ins, and likes, and you may choose to grant or deny us access to 
            each individual permission. For more information regarding Facebook permissions, refer to the 
            <a>Facebook Permissions Reference page</a>.
            </p>
            </li>
        </ul>
        `,

    'privacy.page.block_5.h2': '2. HOW DO WE PROCESS YOUR INFORMATION?',
    'privacy.page.block_5.text': `
        <p>
            <b>In Short:</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
        </p>
        <p>
        <b>
We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
</b>
        </p>
        <ul>
            <li>
                <p>
                    <b>To facilitate account creation and authentication and otherwise manage user accounts.</b> 
            We may process your information so you can create and log in to your account, as well as keep your account in 
            working order.
                </p>
            </li>
            <li>
                <p>
                    <b>To deliver and facilitate delivery of services to the user.</b> 
            We may process your information to provide you with the requested service.
                </p>
            </li>
            <li>
                <p>
                    <b>To respond to user inquiries/offer support to users.</b> 
            We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
                </p>
            </li>
            <li>
                <p>
                    <b>To send administrative information to you.</b> 
            We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
                </p>
            </li>
            <li>
                <p>
                    <b>To fulfill and manage your orders. </b> 
            We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.
                </p>
            </li>
            <li>
                <p>
                    <b>To request feedback. </b> 
           We may process your information when necessary to request feedback and to contact you about your use of our Services.
                </p>
            </li>
            <li>
                <p>
                    <b>To send you marketing and promotional communications.</b> 
            We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see "<a>WHAT ARE YOUR PRIVACY RIGHTS?</a>" below.
                </p>
            </li>
            <li>
                <p>
                    <b>To save or protect an individual's vital interest.</b> 
           We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
                </p>
            </li>
        </ul>`,

    'privacy.page.block_6.h2': '3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?',
    'privacy.page.block_6.text': `
        <p>
            <b>In Short:</b> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
        </p>
        <p>
        The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
        </p>
        <ul>
            <li>
                <p>
                    <b>Consent.</b> 
           We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about <a>withdrawing your consent</a>.
                </p>
            </li>
            <li>
                <p>
                    <b>Performance of a Contract. </b> 
           We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.
                </p>
            </li>
            <li>
                <p>
                    <b>Performance of a Contract. </b> 
            We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
                </p>
            </li>
            <li>
                <p>
                    <b>Legitimate Interests. </b> 
          We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
                <br />
                <br />
                - Send users information about special offers and discounts on our products and services
                <br />
                <br />
                - Understand how our users use our products and services so we can improve user experience
                </p>
            </li>
                        <li>
                <p>
                    <b>Legal Obligations. </b> 
          We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
                <br />
                <br />
                - Send users information about special offers and discounts on our products and services
                <br />
                <br />
                - Understand how our users use our products and services so we can improve user experience
                </p>
            </li>
            <li>
                <p>
                    <b>Vital Interests. </b> 
           We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
                </p>
            </li>
        </ul>
        `,

    'privacy.page.block_7.h2': '4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?',
    'privacy.page.block_7.text': `
        <p>
            <b>In Short:</b> We may share information in specific situations described in this section and/or with the following categories of third parties.
        </p>
        <p>
           <b>Vendors, Consultants, and Other Third-Party Service Providers.</b> We may share your data with third-party 
           vendors, service providers, contractors, or agents ("third parties") who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.
        </p>
        <p>
            The categories of third parties we may share personal information with are as follows:
        </p>
        <ul>
            <li>Data Analytics Services</li>
            <li>Data Storage Service Providers</li>
            <li>Payment Processors</li>
            <li>User Account Registration & Authentication Services</li>
        </ul>
        <p>We also may need to share your personal information in the following situations:</p>
        <ul>
            <li><p><b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p></li>
        </ul>
        `,

    'privacy.page.block_8.h2': '5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?',
    'privacy.page.block_8.text': `
        <p>
            <b>In Short:</b> We may use cookies and other tracking technologies to collect and store your information
        </p>
        <p>
            We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that 
            would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right 
            to erasure, the right to rectification, and the right to data portability cannot be enforced after the expiration of the retention period.
        </p>
        <p>
            We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
        </p>
        <p>
            We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.
        </p>
        <p>
            Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
        </p>
        <p>
            <b>Google Analytics</b>
        </p>
        <p>
            We may share your information with Google Analytics to track and analyze the use of the Services. 
            The Google Analytics Advertising Features that we may use include: Remarketing with Google Analytics. 
            To opt out of being tracked by Google Analytics across the Services, visit <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>. 
            You can opt out of Google Analytics Advertising Features through Ads Settings and Ad Settings for mobile apps. Other opt out means
            include <a href="http://optout.networkadvertising.org">http://optout.networkadvertising.org/</a> and <a href="http://www.networkadvertising.org/mobile-choice">http://www.networkadvertising.org/mobile-choice</a>. 
            For more information on the privacy practices of Google, please visit the <a>Google Privacy & Terms page</a>.
        </p>
        `,

    'privacy.page.block_9.h2': '6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?',
    'privacy.page.block_9.text': `
        <p>
            <b>In Short:</b> If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.
        </p>
        <p>
            Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or X logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform. If you log in using Facebook, we may also request access to other permissions related to your account, such as your friends, check-ins, and likes, and you may choose to grant or deny us access to each individual permission.
        </p>
        <p>
            We will use the information we receive only for the purposes that are described in this Privacy Notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
        </p>
        `,

    'privacy.page.block_10.h2': '7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?',
    'privacy.page.block_10.text': `
        <p>
           <b>In Short:</b> We may transfer, store, and process your information in countries other than your own.
        </p>
        <p>
            Our servers are located in Germany. If you are accessing our Services from outside the United Arab Emirates, please be aware that your 
            information may be transferred to, stored by, and processed by us in our facilities and in the facilities of the third parties with whom 
            we may share your personal information (see "<a>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>" above), in and other countries.
        </p>
        <p>
        If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this Privacy Notice and applicable law.
        </p>
        <p>
            European Commission's Standard Contractual Clauses:
        </p>
        <p>
        We have implemented measures to protect your personal information, including by using the European Commission's Standard Contractual Clauses for transfers of personal information between our group companies and between us and our third-party providers. These clauses require all recipients to protect all personal information that they process originating from the EEA or UK in accordance with European data protection laws and regulations. Our Standard Contractual Clauses can be provided upon request. We have implemented similar appropriate safeguards with our third-party service providers and partners and further details can be provided upon request.
        </p>
        `,

    'privacy.page.block_11.h2': '8. HOW LONG DO WE KEEP YOUR INFORMATION?',
    'privacy.page.block_11.text': `
        <p>
            <b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this Privacy Notice unless otherwise required by law.
        </p>
        <p>
            We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.
        </p>
        <p>
        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
        </p>
        `,

    'privacy.page.block_12.h2': '9. HOW DO WE KEEP YOUR INFORMATION SAFE?',
    'privacy.page.block_12.text': `
        <p>
            <b>In Short:</b> We aim to protect your personal information through a system of organizational and technical security measures.
        </p>
        <p>
        We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
        </p>
        `,

    'privacy.page.block_13.h2': '10. WHAT ARE YOUR PRIVACY RIGHTS?',
    'privacy.page.block_13.text': `
        <p>
           <b>In Short:</b> In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Switzerland, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
        </p>
        <p>
           In some regions (like the EEA, UK, and Switzerland), you have certain rights under applicable data protection laws. 
           These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; 
           (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated 
           decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. 
           You can make such a request by contacting us by using the contact details provided in the section "<a>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.
        </p>
        <p>
        We will consider and act upon any request in accordance with applicable data protection laws.
        </p>
        <p>
        If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, 
        you also have the right to complain to your <a>Member State data protection authority</a> or <a>UK data protection authority</a>.
        </p>
        <p>
        If you are located in Switzerland, you may contact the <a>Federal Data Protection and Information Commissioner</a>.
        </p>
        <p>
        <b>Withdrawing your consent:</b> If we are relying on your consent to process your personal information, you have the right to withdraw your 
        consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the 
        section "<a>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below or updating your preferences.
        </p>
        <p>
        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
        </p>
        <p>
        <b>Opting out of marketing and promotional communications:</b> You can unsubscribe from our marketing and promotional communications at 
        any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the 
        section "<a>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below. You will then be removed from the marketing lists. 
        However, we may still communicate with you — for example, to send you service-related messages that are necessary for the 
        administration and use of your account, to respond to service requests, or for other non-marketing purposes.
        </p>
        <p>
        <b>Account Information</b>
        </p>
        <p>
        If you would at any time like to review or change the information in your account or terminate your account, you can:
        </p>
        <ul>
            <li>Log in to your account settings and update your user account.</li>
            <li>Contact us using the contact information provided.</li>
        </ul>
        <p>
        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
        </p> 
        <p>
        <b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.
        </p>
        `,

    'privacy.page.block_14.h2': '11. CONTROLS FOR DO-NOT-TRACK FEATURES',
    'privacy.page.block_14.text': `
        <p>
Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.
        </p>`,

    'privacy.page.block_15.h2': '12. DO WE MAKE UPDATES TO THIS NOTICE?',
    'privacy.page.block_15.text': `
        <p>
        <b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.
        </p>
        <p>
           We may update this Privacy Notice from time to time. The updated version will be indicated by an updated "Revised" 
           date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently 
           posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to 
           be informed of how we are protecting your information.`,

    'privacy.page.block_16.h2': '13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?',
    'privacy.page.block_16.text': `
        <p>
If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO) by email at <a>legal@infty.art</a>, or contact us by post at: PO Box: 769786, Abu Dhabi, UAE
        </p>`,

    'privacy.page.block_17.h2': '14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?',
    'privacy.page.block_17.text': `
        <p>
            Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please contact us legal@infty.art.
        </p>`,
}
