import { api, ReactQueryOptions, RouterInputs } from '@api';
import { staleTimeIndex } from '@specs/constants';
import { useSession } from '@shared/hooks/useSession';
type optionsType = ReactQueryOptions['UserManager']['getProfile'];
type inputType = RouterInputs['UserManager']['getProfile'];
export const useProfile = (input: inputType, options?: optionsType) => {
  const {
    status
  } = useSession();
  return api.UserManager.getProfile.useQuery(input, {
    ...options,
    queryKey: ['UserManager.getProfile', input],
    refetchOnWindowFocus: false,
    staleTime: 2 * 60 * 60 * 1000 * staleTimeIndex,
    // 2 hours
    enabled: status === 'authenticated'
  });
};