export const quiz = {
    'quiz.message': 'Пожалуйста, пройдите короткий опрос о удобстве использования приложения.',
    'quiz.go': 'Ok',
    'quiz.survey': 'Опрос',
    'quiz.end.title': 'Спасибо за ваш ответ!',
    'quiz.end.text': 'Ваше мнение важно для нас, и мы обязательно учтём его для улучшения нашего продукта.',
    'quiz.how.convenient': 'Насколько удобно вам пользоваться продуктом?',
    'quiz.comment.label': 'Пожалуйста, оставьте свои комментарии здесь',
    'quiz.comment.placeholder': 'Расскажите, что вам понравилось',
    'quiz.question.1': 'Как долго вы используете R.Toys?',
    'quiz.answer.1.1': 'Менее месяца',
    'quiz.answer.1.2': '1-6 месяцев',
    'quiz.answer.1.3': 'Более 6 месяцев',
    'quiz.question.2': 'Как часто вы пользуетесь R.Toys?',
    'quiz.answer.2.1': 'Каждый день',
    'quiz.answer.2.2': 'Несколько раз в неделю',
    'quiz.answer.2.3': 'Несколько раз в месяц',
    'quiz.answer.2.4': 'Реже',
    'quiz.question.3': 'Откуда вы узнали о R.Toys?',
    'quiz.answer.3.1': 'Реклама',
    'quiz.answer.3.2': 'Рекомендации друзей или знакомых',
    'quiz.answer.3.3': 'Социальные сети',
    'quiz.answer.3.4': 'Другие источники',
     //
     'quiz.greetings': 'Помогите нам улучшить R.Toys!',
     'quiz.description': 'Мы изучаем, как коллекционеры находят и покупают арт-игрушки. Пройдите наш опрос',
     'quiz.time': ' (5–7 минут).',
     'quiz.survey_link': 'Ссылка на опрос',
     'quiz.take_part': 'Или примите участие в ',
     'quiz.interview': 'интервью (20–30 минутный звонок) ',
     'quiz.take_part_2': ', чтобы поделиться своими мыслями. Участники получат эксклюзивную цифровую арт-игрушку! Чтобы записаться, пишите на:',
     'quiz.telegram': 'Telegram ссылка',

}
