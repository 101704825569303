export const explorePage = {
    'explore.page.title': 'Explore',
    'explore.page.image.title.default': 'Find more about section, click on the card',
    'explore.page.purchase.rejected.title': 'The purchase failed',
    'explore.page.purchase.rejected.description': 'Failed to complete the purchase. Please, try again',
    'explore.page.purchase.success.title': 'Purchase successful!',
    'explore.page.purchase.success_many.description': 'Purchased toys can be found on the "My toys" page',
    'explore.page.purchase.success_one.description': 'Purchased toy can be found on the "My toys" page',
    'explore.page.trending.toys.title': 'Trending toys',
    'explore.page.popular.artists.title': 'Popular artists',
    'explore.page.physical.artists.title': 'Physical Toys',
}
