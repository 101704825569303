import React, { FC, useState } from 'react';
import { QuizProgressBar } from './QuizProgressBar/QuizProgressBar';
import { QuizLayout } from '../QuizLayout/QuizLayout';
import { QuizQuestions } from './QuizQuestions/QuizQuestions';
import { QuizFinalStage } from './QuizFinalStage/QuizFinalStage';
import { AnswerId, QuestionId, questions, QuizDataItem } from './QuizQuestionsTypeData';
interface Props {
  handleEndSurvey: () => void;
}
export const QuizSurvey: FC<Props> = ({
  handleEndSurvey
}) => {
  const totalStages = questions.length + 1;
  const [stage, setStage] = useState(1);
  const [quizData, setQuizData] = useState<QuizDataItem[]>(() => questions.map(el => ({
    questionId: el.questionId,
    answerId: null
  })));
  const moveToPrevStage = () => {
    setStage(prev => Math.max(1, prev - 1));
  };
  const isLastStage = stage === totalStages;
  const handleSelect = ({
    questionId,
    answerId
  }: {
    questionId: QuestionId;
    answerId: AnswerId;
  }) => {
    setQuizData(prev => prev.map(el => el.questionId === questionId ? {
      ...el,
      answerId
    } : el));
    setStage(prev => Math.min(totalStages, prev + 1));
  };
  return <QuizLayout data-sentry-element="QuizLayout" data-sentry-component="QuizSurvey" data-sentry-source-file="QuizSurvey.tsx">
            <QuizProgressBar total={totalStages} current={stage} data-sentry-element="QuizProgressBar" data-sentry-source-file="QuizSurvey.tsx" />
            {isLastStage ? <QuizFinalStage quizData={quizData} handleEndSurvey={handleEndSurvey} /> : <QuizQuestions questionData={questions[stage - 1]} handleSelect={handleSelect} selectedValue={quizData[stage - 1]?.answerId} moveToPrevStage={moveToPrevStage} stage={stage} />}
        </QuizLayout>;
};