import { referralAwardsKeys } from '../en/referralAwards'

export const referralAwards: Record<referralAwardsKeys, string> = {
    'award.REFFERAL_5DROPS': 'Реферал собрал 5 и более игрушек',
    'award.REFERRAL_INVITE': 'Пользователь привёл первого реферала',
    'award.REFERRAL_SUBSCRIBTION': 'Реферал купил подписку',
    'award.REFERRAL_SUBSCRIBTION_MONTH': 'Реферал купил подписку на 1 месяц',
    'award.REFERRAL_SUBSCRIBTION_HALF_YEAR': 'Реферал купил подписку на 6 месяцев',
    'award.REFERRAL_SUBSCRIBTION_YEAR': 'Реферал купил подписку на 12 месяцев',
    'award.REFERRAL_GOLD_PURCHASE': 'Реферал купил золото',
}
