export const explorePage = {
    'explore.page.title': 'Поиск',
    'explore.page.image.title.default': 'Чтобы узнать больше о разделе, нажмите на карточку',
    'explore.page.purchase.rejected.title': 'Покупка не удалась',
    'explore.page.purchase.rejected.description': 'Не удалось завершить покупку. Пожалуйста, попробуйте снова',
    'explore.page.purchase.success.title': 'Покупка успешна!',
    'explore.page.purchase.success_many.description': 'Купленные игрушки можно найти на странице «Мои игрушки»',
    'explore.page.purchase.success_one.description': 'Купленную игрушку можно найти на странице «Мои игрушки»',
    'explore.page.trending.toys.title': 'Трендовые игрушки',
    'explore.page.popular.artists.title': 'Популярные художники',
    'explore.page.physical.artists.title': 'Физические игрушки',
}
