import React, { type FC, type InputHTMLAttributes, useState } from 'react';
import s from './textarea.module.scss';
import cn from 'classnames';
export interface TextareaProps extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'value'> {
  value: string;
  onPrint: (value: string) => void;
  boxClassName?: string;
  label?: React.ReactNode;
}
const TextareaMemo: FC<TextareaProps> = ({
  onPrint,
  boxClassName,
  className,
  onChange,
  label,
  value,
  ...props
}) => {
  const [isEmpty, setIsEmpty] = useState(!value);
  return <div className={cn(s.textareaBox, boxClassName)} data-sentry-component="TextareaMemo" data-sentry-source-file="Textarea.tsx">
            {label && <div className={s.textareaBox__label}>{label}</div>}
            <textarea className={cn(s.textareaBox__textarea, s.textareaBox__row, props?.disabled && s.textareaBox__row_disabled, !isEmpty && s.textareaBox__row_filled, className)} onChange={e => {
      onChange?.(e);
      onPrint(e.target.value);
      setIsEmpty(!e.target.value);
    }} value={value} {...props} />
        </div>;
};
export const Textarea = React.memo(TextareaMemo);