export const sellToyPage = {
    'sell.toy.page.title': '出售玩具',
    'sell.toy.page.confirm.description': '将从最终售出价格中抽取 10% 作为作者和平台的版税',
    'sell.toy.page.label.toy.price': '玩具价格',
    'sell.toy.page.label.select.currency': '选择货币',
    'sell.toy.page.input.placeholder': '输入玩具价格',
    'sell.toy.page.minimum.price.description': '最低售价: <price></price>',
    'sell.toy.page.modal.title': '可用的',
    'sell.toy.page.delete.modal.title': '下架商品',
    'sell.toy.page.delete.modal.text': '请确认您要将此玩具下架。您可以随时重新上架',
}
