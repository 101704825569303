export const header = {
    'header.feed': 'Лента',
    'header.explore': 'Поиск',
    'header.games': 'Игры',
    'header.my.toys': 'Мои игрушки',
    'header.account.settings': 'Настройки аккаунта',
    'header.faq': 'Часто задаваемые вопросы',
    'header.support': 'Поддержка',
    'header.logout': 'Выйти',
    'header.your.balance': 'Ваш баланс',
    'header.change.language': 'Сменить язык',
    'header.english': 'English',
    'header.russian': 'Русский',
    'header.chinese': '中文',
    'header.terms.of.use': 'Условия использования',
    'header.privacy.policy': 'Политика конфиденциальности',
}
