import React, { FC, ReactNode } from 'react';
import s from './QuizLayout.module.scss';
import { useTranslation } from '@hooks/useTranslation';
interface Props {
  children: ReactNode;
}
export const QuizLayout: FC<Props> = ({
  children
}) => {
  const {
    translate: tr
  } = useTranslation();
  return <div className={s.container} data-sentry-component="QuizLayout" data-sentry-source-file="QuizLayout.tsx">
            <h3 className={s.container__header}>R.Toys {tr({
        id: 'quiz.survey'
      })}</h3>
            {children}    
        </div>;
};