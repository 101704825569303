export const errors = {
    'error.default': '出错了。请稍后再试。',
    'error.metamask': '未安装MetaMask!',
    'main.drops.notFound': '未找到掉落',
    'main.drops.noToy': '没有玩具掉落',
    'main.drops.notAvailable': '所有玩具已被收集',
    'main.drops.alreadyOwned': '你已拥有该玩具',
    'main.drops.artClub': '只限艺术俱乐部会员',
    'main.drops.region.restricted': '你所在地区没有这款玩具',
    'main.drops.wrongSecret': '秘密掉落的代码错误',
    'main.blindbox.limit.exhausted': '盲盒开启限制已用尽',
    'main.blindbox.balance.notenough': '金币不足',
    'main.blindbox.toys.empty': '所有玩具已被收集',
    'main.blindbox.color.dublicate': '此颜色已开启过',
    'main.bonusToy.alreadyOwned': '已收集奖励玩具',
    'main.bonusToy.notBonus': '此玩具不是奖励玩具',
    'main.bonusToy.notAvailable': '没有可领取的玩具了',
    'main.bonusToy.notAllCollected': '未收集所有玩具',
    'main.bonusToy.notfound': '未找到玩具',
    'main.profiles.shortId.conflict': '此名称已被使用',
    'main.profiles.shortId.length.min': '名称长度必须超过 5 个字符',
    'main.profiles.shortId.length.max': '名称长度必须小于 20 个字符',
    'main.profiles.shortId.filter': '名称只能包含字符和数字',
    'main.rack.not.owner': '只有拥有者才能编辑展示架',
    'main.rack.free.limit.exhausted': '只有3个展示架可免费使用',
    'main.rack.balance.notenough': '金币不足',
    'main.rack.profile.not.found': '未找到个人资料。请重新登录',
    'main.leaderboard.stats.notFound': '未找到该用户的排行榜的统计信息。对于新用户来说是正常的',
    'main.profiles.notFound': '未找到个人资料',
    'burn.not.owner': '你必须是拥有者',
    'burn.toy.notfound': '未找到玩具',
    'burn.toy.one.restricted': '无法销毁独一无二玩具',
    'burn.config.rarity.notfound': '技术原因：未找到稀有度',
    'burn.config.rate.notfound': '技术原因：未找到稀有率',
    'burn.toy.supplied.null': '技术原因：无供应玩具',
    'burn.toy.limit.exhausted': '你每天只能销毁50个玩具',
    'burn.toy.store.exists': '你无法销毁正在销售的玩具。需先取消销售',
    'main.unauthurised': '你需要先登录',
    'main.upload.no.nontent-type': '内容类型标题不存在',
    'main.upload.not.image': '无图像',
    'main.upload.not.supported': '不支持该文件类型',
    'main.rack.worng.price': '价格不匹配',
    'cloud.intent.error': '外部服务出错。无法启动上传。',
    'cloud.intent.not.found': '通过当前 ID 未找到上传的文件',
    'cloud.intent.draft': '上传尚未完成',
    'burn.toy.rack.member': '你需要先从你的展示架上移除这个玩具。',
    'main.store.toy.restricted': '此物品限制出售',
    'main.store.purchase.price.mismatch': '价格已更改',
    'main.store.purchase.item.not.found': '未找到该销售物品。可能已经有人购买了。',
    'main.kyc.required': '需要先通过KYC验证',
    'main.profiles.shortId.limit': '你在24小时内只能更改一次用户名',
    'main.store.notFound': '在商店中找不到该物品',
    'main.store.price.negative': '价格不能低于 50',
    'main.store.price.fractional': '价格不能包含小数部分',
    'main.store.ownership.not.found': '你必须是拥有者',
    'main.store.dublicate': '此玩具已在销售中',
    'system.ban.dublicate': '已被封禁',
    'system.profile.notfound': '未找到个人资料',
    'system.transaction.error': '系统错误。请重试或联系技术支持',
    'store.item.not.available': '此价格和编号的玩具已不可用',
    'store.item.ended': '对不起，你来得太晚了。此玩具已结束销售。',
    'cart.is.full': '对不起，你的购物车已满！你的购物车中只能添加 15 件物品',
    'main.rack.variation.not.found': '未找到具有指定 id 的展示架',
    'main.competitions.no.available.': '系统。没有可用的模式竞赛。全部已完成',
    'main.competitions.not.completed': '尚未完成所有操作',
    'main.competitions.already.completed': '此比赛已完成',
    'main.competitions.wrong.mechanics': '通过比赛收集只适用于每日和每周掉落',
    'main.competitions.service.error': '外部服务不可用。请稍后再试',
    'payments.product.not.found': '系统错误。请求的产品未配置。请联系技术支持',
    'payments.subscription.not.found': '此用户没有激活的订阅计划',
    'payments.provider.not.supported': '不支持该提供商',
    'main.drops.retro.timer': '（修改我）随机掉落时间未到',
    'drops.retro.skipped.dublicate': '随机掉落已跳过',
    'payments.stripe.product.delete.error': '在 Stripe 中删除产品时发生错误',
    'payments.stripe.product.not.found': '在 Stripe 中找不到匹配的产品',
    'payments.stripe.price.not.found': '在 Stripe 中找不到匹配的价格',
    'main.subscription.required': '此操作需要激活的订阅',
    'promocode.generate.error': '生成促销代码失败',
    'promocode.transaction.error': '应用促销代码失败',
    'promocode.cannot.find.active': '无法找到激活的促销代码',
    'system.idempotency.error': '此操作已被执行',
    'main.ads.notWatched': '广告视频未被观看',
    'main.ads.wrong.mechanics': '通过观看广告收集仅适用于每日和每周掉落',
    'main.draft.donotFind': '未找到草稿',
    'main.draft.trx.error': '处理草稿失败',
    'main.balance.red': '你的余额不足',
    'main.store.purchase.balance.red': '你的余额不足',
    'personal.account.myToy.upload.model.need.to.upload': '你需要上传所有媒体文件',
    'personal.account.myToy.upload.model.need.to.allFileds': '你必须填写基本字段',
    'payout.balance.limit.exhausted': '最低提现金额为 100 个Ruby宝石。请稍后再试',
    'gold.give.error': '更新的内容不能多于输入的内容。',
    'swapUserEmails.old.user.not.found': '未找到旧帐户',
    'swapUserEmails.new.user.not.found': '未找到新帐户',
    'swapUserEmails.cannot.swap.regular.users': '用普通邮件替换用户配置文件失败',
    'swapUserEmails.cannot.swap.accounts.users': '替换包含账户的用户配置文件失败',
    'swapUserEmails.cannot.swap.regular.account.users': '替换包含旧谷歌 | 苹果账户的用户配置文件和包含普通邮件的新用户配置文件失败',
    'swapUserEmails.cannot.swap.account.regular.users': '用新的谷歌 | 苹果账户和用普通邮件的旧账户替换用户配置文件失败',
    'swapUserEmails.first.package.error': '第一个交易包出错',
    'swapUserEmails.second.package.error': '第二个交易包出错',
    'swapUserEmails.profiles.not.found': '检索用户配置文件失败',
    'swapUserEmails.profile.artists.swap': '宣布用户为艺术家失败',
    'swapUserEmails.profile.avatar.swap': '无法宣布用户头像',
    'kyc.always.green': 'KYC始终为绿色',
    'payments.subscription.isPendingFinish': '订阅已经取消',
    'share.not.valide.code': '这不是本帖子的有效代码',
    'share.not.exist': '无法找到分享的帖子',
    'toy.not.exist': '无法找到该玩具',
    'artist.not.exist': '无法找到该艺术家',
    'cannot.create.share.post': '创建分享帖子失败',
    'share.cannot.transfer.100.gold': '无法发送 100 金币',
    'share.cannot.transfer.25.gold': '无法发送 25 金币',
    'share.cannot.transfer.wright.reward': '未能记录确认信息',
    'share.cannot.found.any.posts': '找不到任何分享帖子',
    'share.cannot.apply.ur.own.code': '你无法验证你的代码',
    'openEdition.toy.is.used.in.drop': '该玩具已在另一个空投中使用，因此不能在开放版机制中使用',
    'openEdition.is.not.found': '未找到开放版状态',
    'rarity.not.found': '未找到该稀有度',
    'openEdition.incorrect.time.range': '开放版时间范围不正确',
    'elastic.update.index.error': '弹性更新索引错误',
    'elastic.update.index.success': '弹性更新索引成功',
    'collection.pack.save.rates.error': '无法保存数据包稀有率',
    'collection.pack.save.rates.success': '成功保存数据包稀有率',
    'partner.line.collection.dont.match': '合作伙伴收藏品系列不匹配',
    'partner.dont.find': '未找到合作伙伴',
    'partner.line.length.dont.metch': '行的长度应与合作伙伴其他行的长度相同，请确保输入正确的值',
    'partner.pack.delay': '距离上次购买后必须至少超过了 2 分钟',
    'main.partners.line.notcollected': '并非每个玩具都会被收集',
    dateNotAllowed: '发行日期尚未到达',
    'convert.usd.error': '无法将美元转换为其他货币',
    'convert.currency.do.not.found': '货币未找到',
    'offers.price.min.gold': '价格必须大于 50 金币',
    'offers.price.min.ruby': '价格必须大于 1 Ruby宝石',
    'offers.instanceid.not.found': '此编号的玩具未被收集',
    'offers.offer.not.found': '未找到报价',
    'offers.is.not.owner': '此报价由其他用户创建。无法执行操作',
    'offers.ownership.not.found': '未找到拥有权',
    'partner.line.lineMechanics.dont.match': '系列机制不匹配',
    'shippingRequest.create.error': '发送出货请求失败 ',
    'partner.line.mechanic.dont.exist': '合作伙伴系列机制不存在',
    'partner.line.rarites.dont.match': '合作伙伴系列机制不匹配',
    'ownership.not.owner': '你必须是该资产的拥有者',
    'ownership.already.minted': '该NFT已被铸造',
    'system.wallet.not.connected': '你必须连接钱包才能执行此操作',
    'wallet.connected.to.another.account': '抱歉，你无法连接此钱包，请连接你之前提供的钱包',
    'system.contract.error': '区块链交互错误。请联系技术支持',
    'store.nft.not.owner': '此玩具已被铸造NFT。拥有者地址必须与你连接的钱包地址相同，才能执行此操作。',
    'ownership.mint.partner.required': '目前铸造只适用于合作伙伴收藏品',
    'toy.mint.disabled': '此玩具无法铸造',
    'quest.not.completed': '任务要求未完成',
    'quest.already.completed': '任务已完成',
    'quest.finished': '任务已结束',
    'quest.profile.already.have.subscription': '此任务仅适用于未订阅的用户',
    'offersBanner.not.found': '未找到此类型的优惠横幅',
    'blindbox.is.not.exist': '未找到可用的盲盒',
    'blindbox.totalProbability.is.not.correct': '掉落概率出现问题，请联系支持',
    'blindbox.collect.noToy': '未能将盲盒连接到随机玩具',
    'blindbox.collect.notAvailable': '所有玩具已被收集',
    'blindbox.collect.trx.error': '处理盲盒失败',
    'not.optimal.ruby.set': '无法形成最佳产品组合',
    'main.invoice.notFound': '未找到账单',
    'main.coupon.notFound': '未找到优惠券',
    'main.form.fields.required': '此字段为必填字段',
    'main.not.qr.exists': '此名称已被使用',
    'coupon.noVerify.requires.subscription': '无法验证优惠券。需要订阅',
    'coupon.validFrom.not.come': '优惠券有效期尚未到来',
    'coupon.validUntil.end': '优惠券有效期已结束',
    'coupon.alredy.used': '优惠券已被使用',
    'coupon.purchaseLimit.miss': '超过优惠券购买限额',
    'coupon.minimumSpendThreshold.miss': '未达到优惠券的最低消费限额',
    'coupon.currencyType.dont.match': '某些产品的货币类型不正确',
    'coupon.not.reusable': '优惠券不可重复使用在此产品上',
    'personal.account.myToy.toy.name.min': '玩具名称长度必须超过 5 个字符',
    'personal.account.myToy.toy.name.max': '玩具名称长度必须少于 20 个字符',
    'toy.mint.no.contract': '此玩具未配置合同',
    'main.social.delete.error': '要断连社交媒体，请先为你的账户添加其他登录方式',
    'main.store.price.too.high': '数字太长。请重试',
    'post.not.author': '这不是您的帖子，无法编辑',
    'upload.image.413': '请求实体太大',
    'personal.account.myToy.upload.model.error': '上传 {type} 模型出错',
    'create.toy.invalid.form': '表单无效',
    'create.toy.toy.name.min.error': '玩具名称长度必须超过 {min} 个字符',
    'create.toy.toy.name.max.error': '玩具名称长度必须少于 {max} 个字符',
    'create.toy.upload.image.error': '无法上传。请检查您的文件',
    'create.toy.price.error': '请输入高于最低价格的价格',
    'create.toy.circulation.error': '需要填写流通量',
    'crop.image.error': '裁剪图片出错',
    'no.file.provided': '未提供文件',
    'upload.failed': '上传失败',
    'please.check.your.file': '请检查您的 {type} 文件',
    'generate.qr.codes.max.length': '二维码最大数量为200',
    'error.block.alreadyBlocked': '你已屏蔽此用户',
}
