export const paymentsPage = {
    'payments.pages.currency.title': '购买货币',
    'payments.pages.tab.history': '历史',
    'payments.pages.checkout.currency.modal.title': '结账',
    'payments.pages.checkout.currency.modal.to.buy': '购买',
    'payments.pages.checkout.currency.modal.price.in.dollars': '以美元计价',
    'payments.pages.withdraw.card.title': '提现',
    'payments.pages.withdraw.card.description': '你可以随时在我们的平台上将你的Ruby宝石兑换成美元（最低提现金额为 100Ruby宝石）',
    'payments.pages.history.will.be.soon': '此部分即将推出',
    'payments.pages.payment.toast.success.text': '你的余额已充值',
    'payments.pages.payment.toast.error.text': '出现错误，请尝试重新支付',
}
