export const faqPage = {
    'faq.page.title': 'Frequently asked <span>questions</span>',

    'faq.page.block_1.h1': 'About us',
    'faq.page.block_1.item_1.question': 'What is ArToys?',
    'faq.page.block_1.item_1.answer':
        '<li>' +
        '                            It is the one and only platform that brings together art toy designers, brands, and collectors, bridging the gap between digital and physical collectibles in the designer toys industry.\n' +
        '                        </li>',
    'faq.page.block_1.item_2.question': 'What can I do with the digital toys?',
    'faq.page.block_1.item_2.answer':
        '\n' +
        '                        <li>\n' +
        "                            You can view them in 3D format or using Augmented Reality (AR), as well as take photos and videos with them. In the near future, you'll be able to customize and use them in games.\n" +
        '                        </li>',

    'faq.page.block_2.h1': 'Website',
    'faq.page.block_2.item_1.question': 'How can I claim the digital collectible?',
    'faq.page.block_2.item_1.answer':
        '\n' +
        '                        <li>\n' +
        '                            To claim your toy, please log in and click on the “Collect now” button on the website. The art toy will then be added to your collection.\n' +
        '                        </li>',
    'faq.page.block_2.item_2.question': 'What devices support AR?',
    'faq.page.block_2.item_2.answer':
        '\n' +
        '                        <li>\n' +
        '                            Most modern devices support AR and 3D displays. Here is a list of <a href="https://www.apple.com/augmented-reality/" target="_blank" rel="noreferrer">iOS\n' +
        '                            devices</a> that support AR. Here is a list of <a href="https://developers.google.com/ar/devices" target="_blank" rel="noreferrer">Android devices</a> that\n' +
        '                            support AR. For the best\n' +
        '                            experience, we recommend devices with a Lidar scanner installed (iPhone 12+, iPad Pro).\n' +
        '                        </li>',
    'faq.page.block_2.item_3.question': 'Do I have to pay for the digital collectible or is it free of charge?',
    'faq.page.block_2.item_3.answer': '\n' + '                        <li>\n' + '                            The art toys are distributed for free every day.\n' + '                        </li>',
    'faq.page.block_2.item_4.question': 'Is the number of art toys limited? If so, how do I know how many toys are left?',
    'faq.page.block_2.item_4.answer':
        '\n' +
        '                        <li>\n' +
        '                            The number of each toy issued is limited. The number of toys in circulation is displayed on the toy’s page.\n' +
        '                        </li>',
    'faq.page.block_2.item_5.question': 'Is it possible for the user to change their email address on the platform?',
    'faq.page.block_2.item_5.answer': '\n' + '                        <li>\n' + '                            Unfortunately, the email cannot be changed.\n' + '                            </li>',
    'faq.page.block_2.item_6.question': 'Are you going to develop a mobile app?',
    'faq.page.block_2.item_6.answer': '\n' + '                        <li>\n' + '                            Yes! It’s under development.\n' + '                        </li>',
    'faq.page.block_2.item_7.question': 'I wanted to complete a task, but I got an "Access Denied" message.',
    'faq.page.block_2.item_7.answer':
        '\n' + '                        <li>\n' + '                            You might have a VPN or proxy enabled. Please disable them to complete the task.\n' + '                        </li>',

    'faq.page.block_3.h1': 'Gold',
    'faq.page.block_3.item_1.question': 'What is Gold, and how can I use it?',
    'faq.page.block_3.item_1.answer':
        '\n' +
        '                        <li>\n' +
        '                            Gold is the internal currency of the platform. You can use it to collect blindboxes, buy toys on the community market, and open new boards.\n' +
        '                        </li>',
    'faq.page.block_3.item_2.question': 'Where can I see Gold in my account?',
    'faq.page.block_3.item_2.answer':
        '\n' + '                        <li>\n' + '                            You can see the number of Gold you have at the top right corner of the platform.\n' + '                        </li>',

    'faq.page.block_5.h1': 'Community market',
    'faq.page.block_5.item_1.question': 'What is a Community Market and how can I use it?',
    'faq.page.block_5.item_1.answer':
        '\n' +
        '                        <li>\n' +
        '                            Community market is a place where you can buy and sell toys. There you can see different offers and find the toys you need for your collection. Also you can sell your toys and earn some Gold.\n' +
        '                        </li>',
    'faq.page.block_5.item_2.question': 'How can I set a price for the toy that I want to sell?',
    'faq.page.block_5.item_2.answer':
        '\n' + '                        <li>\n' + '                            It’s a free market, so it’s your choice. Many factors will matter when you are setting the price.\n' + '                        </li>',
    'faq.page.block_5.item_3.question': 'Does rarity affect the price of a toy?',
    'faq.page.block_5.item_3.answer': '\n' + '                        <li>\n' + '                            Yes.The price of a toy can be influenced by many factors.\n' + '                        </li>',
    'faq.page.block_5.item_4.question': 'Can I cancel the sale of my toy?',
    'faq.page.block_5.item_4.answer':
        '\n' +
        '                        <li>\n' +
        '                            You can cancel the sale of your toy at any time before it is sold to the other user. Go to the toy’s page and press the “Cancel selling” button.\n' +
        '                        </li>',
    'faq.page.block_5.item_5.question': 'Do I have to pay for the trade or is it free of charge?',
    'faq.page.block_5.item_5.answer':
        '\n' + '                        <li>\n' + "                            We charge 10% of the toy's price, which is distributed between the artist and the platform.\n" + '                        </li>',
}
