import { UserAgent, useUserAgent } from 'next-useragent';
import React, { FC, ReactNode } from 'react';
export const AgentContext = React.createContext<{
  agent: null | UserAgent;
}>({
  agent: null
});
export async function getServerSideProps() {}
interface AgentProviderProps {
  children: ReactNode;
  agent?: string;
}
const AgentProvider: FC<AgentProviderProps> = ({
  children,
  agent
}) => {
  const ua = useUserAgent(typeof window !== 'undefined' ? window?.navigator?.userAgent : agent || '');
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AgentContext.Provider value={{
      agent: ua
    }} data-sentry-element="unknown" data-sentry-component="AgentProvider" data-sentry-source-file="AgentProvider.tsx">{children}</AgentContext.Provider>
  );
};
export default AgentProvider;