function convertParam(boolValue, defaultValue) {
  return (boolValue === undefined ? defaultValue : boolValue) ? 'true' : 'false';
}
function YandexMetrikaTag({
  yid,
  clickmap = true,
  trackLinks = true,
  accurateTrackBounce = true,
  webvisor = true,
  uid
}) {
  /// Tag version of the Yandex Metrika.
  /// Used when there is support for the JavaScript to fully track user.
  /// Will cause loading and injecting tag script, and activate Yandex Metrika by constructor.

  // Tag options, see your Yandex Metrika recommendations for setup.
  const stringClickmap = convertParam(clickmap, true);
  const stringTrackLinks = convertParam(trackLinks, true);
  const stringAccurateTrackBounce = convertParam(accurateTrackBounce, true);
  const stringWebvisor = convertParam(webvisor, true);

  // Script that injects Yandex Metrika tag script.
  const scriptInjectorHTML = `
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
  `;
  return <script dangerouslySetInnerHTML={{
    __html: `
            ${scriptInjectorHTML}
            ym(${yid}, "init", {
                clickmap:${stringClickmap},
                trackLinks:${stringTrackLinks},
                accurateTrackBounce:${stringAccurateTrackBounce},
                webvisor:${stringWebvisor}
            });

            ym(${yid}, 'userParams', {
                auth: ${!!uid},
                UserID: ${uid}
                });  
            `
  }} data-sentry-component="YandexMetrikaTag" data-sentry-source-file="Yandex.tsx" />;
}
function YandexMetrikaPixel({
  yid
}) {
  /// Pixel version of the Yandex Metrika.
  /// Used when there is no JavaScript on the target browser.
  /// This will cause the Yandex Metrika to track user by calling loading of the pixel image (with target params).

  // Target source to load pixel from.
  const pixelSource = `https://mc.yandex.ru/watch/${yid}`;

  /* eslint-disable @next/next/no-img-element */
  return <noscript data-sentry-component="YandexMetrikaPixel" data-sentry-source-file="Yandex.tsx">
            <div>
                <img src={pixelSource} style={{
        position: 'absolute',
        left: '-9999px'
      }} alt='' />
            </div>
        </noscript>;
}
export default function YandexMetrika({
  yid,
  clickmap = true,
  trackLinks = true,
  accurateTrackBounce = true,
  webvisor = true,
  uid
}) {
  /// Yandex Metrika service.
  return <>
            <YandexMetrikaTag yid={yid} clickmap={clickmap} trackLinks={trackLinks} accurateTrackBounce={accurateTrackBounce} webvisor={webvisor} uid={uid} data-sentry-element="YandexMetrikaTag" data-sentry-source-file="Yandex.tsx" />
            <YandexMetrikaPixel yid={yid} data-sentry-element="YandexMetrikaPixel" data-sentry-source-file="Yandex.tsx" />
        </>;
}