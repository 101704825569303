export const paymentHistoryPage = {
    'payment.history.page.title.purchase': 'Покупка',
    'payment.history.page.title.collected.drop': 'Собран дроп {dropType}',
    'payment.history.page.title.purchase.offer': 'Предложение о покупке',
    'payment.history.page.title.sale': 'Продажа',
    'payment.history.page.title.commission': 'Комиссия',
    'payment.history.page.title.burned': 'Сжигание',
    'payment.history.page.title.return': 'Возврат',
    'payment.history.page.title.subscription': 'Подписка',
    'payment.history.page.title.sharing': 'Публикация поста',
    'payment.history.page.title.referral': 'Реферал',
    'payment.history.page.title.games': 'Игры',
    'payment.history.page.title.social.networks': 'Социальные сети',
    'payment.history.page.title.registration': 'Регистрация',
    'payment.history.page.title.drop.skipped': 'Дроп пропущен',
    'payment.history.page.title.accrual': 'Начисление',
    'payment.history.page.title.royalty': 'Роялти',
    'payment.history.page.title.withdrawal': 'Вывод рубинов',
    'payment.history.page.title.error': 'Ошибка',
    'payment.history.page.title.migration': 'Migration',
    'payment.history.page.title.quest': 'Квест',
    'payment.history.page.text.purchase': 'Покупка игрушки {toyName} из пакета {packName}',
    'payment.history.page.text.collected.drop': 'Собрана игрушка {toyName}',
    'payment.history.page.text.purchase.offer': 'Предложение о покупке игрушки {toyName}',
    'payment.history.page.text.gold.purchase': 'Покупка золота',
    'payment.history.page.text.ruby.purchase': 'Покупка рубинов',
    'payment.history.page.text.sale': 'Продажа игрушки {toyName}',
    'payment.history.page.text.commission': 'За продажу игрушки {toyName}',
    'payment.history.page.text.burned': 'Сожжена игрушка {toyName}',
    'payment.history.page.text.blindbox.purchase': 'Покупка {blindboxName}',
    'payment.history.page.text.return': 'Возврат игрушки {toyName}',
    'payment.history.page.text.subscription': 'Бонусное золото за покупку подписки',
    'payment.history.page.text.sharing.confirmation': 'Бонус за подтверждение поста',
    'payment.history.page.text.sharing.post': 'Бонус за публикацию поста',
    'payment.history.page.text.board.purchase': 'Покупка витрины {boardName}',
    'payment.history.page.text.games.won': 'Выигрыш в игре',
    'payment.history.page.text.games.lost': 'Проигрыш в игре',
    'payment.history.page.text.verification': '',
    'payment.history.page.text.registration': 'Бонус за регистрацию',
    'payment.history.page.text.games.purchased': 'Покупка игры',
    'payment.history.page.text.drop.skipped': 'Ускорение таймера Рандом-дропа',
    'payment.history.page.text.account.connection': 'Бонус за подключение аккаунта',
    'payment.history.page.text.accrual.ruby': 'Начисление рубинов админом',
    'payment.history.page.text.accrual.gold': 'Начисление золота админом',
    'payment.history.page.text.return.ruby': 'Возврат рубинов админом',
    'payment.history.page.text.return.gold': 'Возврат золота админом',
    'payment.history.page.text.accrual.gold.promocode': 'Начисление золота по промокоду',
    'payment.history.page.text.accrual.ruby.promocode': 'Начисление рубинов по промокоду',
    'payment.history.page.text.royalty': 'Роялти за продажу игрушки {toyName}',
    'payment.history.page.text.withdrawal.ruby': 'Вывод рубинов',
    'payment.history.page.text.error': 'Вывод отклонён',
    'payment.history.page.text.referral.award': '{referralAward}',
    'payment.history.page.text.board': 'Витрина',
}
