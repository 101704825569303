export const block = {
    'block.modal.title': 'Block the author',
    'block.modal.description': 'Are you sure you want to block this author? You can unblock in any time',
    'block.success.title': 'Block the author',
    'block.success.description': '{author} is blocked. Any other accounts they may have or create are also blocked.',
    'unblock.modal.title': 'Unblock {author}?',
    'unblock.modal.description': '{author} and other accounts they have or create will now be able to see your profile and follow. They won’t be notified that you unblocked them',
    'unblock.success.title': '{author} unblocked',
    'unblock.success.description': 'You can block them from their profile',
}
