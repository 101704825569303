import { Dictionary } from '@hooks/useTranslation';
import ruDictionary from '../../../../../lang/ru';

// 1. Получаем номера вопросов из Dictionary
type ExtractQuestionId<T> = T extends `quiz.question.${infer N extends number}` ? N : never;
export type QuestionId = ExtractQuestionId<keyof Dictionary>;

// 2. Получаем номера ответов для каждого вопроса из Dictionary
type ExtractAnswerId<T> = T extends `quiz.answer.${number}.${infer N extends number}` ? N : never;
export type AnswerId = ExtractAnswerId<keyof Dictionary>;

// 3. Интерфейс для структуры вопроса
export interface IQuestion {
  questionId: QuestionId;
  answers: readonly AnswerId[];
}

// 4. Создаем массив questions на основе словаря
export const questions: IQuestion[] = Object.keys(ruDictionary).filter(key => key.startsWith('quiz.question.')).map(key => {
  const questionId = parseInt(key.split('.')[2]) as QuestionId;
  const answers = Object.keys(ruDictionary).filter(answerKey => answerKey.startsWith(`quiz.answer.${questionId}.`)).map(answerKey => parseInt(answerKey.split('.')[3]) as AnswerId);
  return {
    questionId,
    answers
  };
});

// 5. Интерфейс для элемента данных квиза
export interface QuizDataItem {
  questionId: QuestionId;
  answerId: AnswerId | null;
}