export const postPage = {
    'post.create.page.title': 'Новый пост',
    'post.copy.link.to.post': 'Скопировать ссылку на пост',
    'post.edit.post': 'Редактировать пост',
    'post.delete.post.modal.title': 'Удалить пост',
    'post.block.this.author': 'Пожаловаться на автора',
    'post.block.this.post': 'Пожаловаться на пост',
    'post.delete.post.modal.description': 'Вы уверены, что хотите удалить пост? К сожалению, его нельзя будет восстановить.',
    'post.validate.message':
        'Обратите внимание, что пост должен включать как минимум одно изображение, максимум можно добавить 10 изображений. Каждое изображение должно иметь соотношение сторон 16:9, а размер файла не должен превышать 5 МБ. Максимальное количество символов — 10 000.',
    'post.page.title': '{name} - Идеи и тренды в индустрии дизайнерских игрушек | ToyVerse',
    'post.page.description': '{name}. Узнайте больше о последних трендах, дизайнерах и коллекционных игрушках на ToyVerse.',
    'post.image.title': 'Пост {title} | Читай больше на R.Toys',
    'post.image.title.default': 'Пост | Читай больше на R.Toys',
}
