export const cartPage = {
    'cart.page.title': 'Cart',
    'cart.page.select.all': 'Select all',
    'cart.page.delete.selected': 'Delete selected',
    'cart.page.buy.this.toy.only': 'Buy this toy only',
    'cart.page.to.buy': 'To buy',
    'cart.page.price.in.rubies': 'Price in Rubies',
    'cart.page.price.in.gold': 'Price in Gold',
    'cart.page.your.cart.is.empty.title': 'Your cart is empty',
    'cart.page.your.cart.is.empty.description': 'Check out the Explore or use search to find the art toys',
    'cart.page.available.modal.title': 'Available',
    'cart.page.available.modal.input.placeholder': 'Enter toy number',
    'cart.page.delete.toy.modal.title': 'Delete the toy',
    'cart.page.delete.toy.modal.description': 'Are you sure you want to remove the selected toy? It will not be possible to cancel this action',
    'cart.page.delete.toys.modal.title': 'Delete the toys',
    'cart.page.delete.toys.modal.description': 'Are you sure you want to remove the selected toys? It will not be possible to cancel this action',
    'cart.page.insufficient.balance.modal.title': 'Insufficient balance',
    'cart.page.insufficient.balance.modal.description': 'Buy the necessary amount of currency for a successful purchase',
    'cart.page.insufficient.balance.modal.text': "You don't have enough to buy",
    'cart.page.purchase.modal.title': "You're about to purchase this art toy",
    'cart.page.purchase.modal.description': 'Click on the "Buy" button to complete the purchase',
    'cart.page.toy.unavailable.modal.title': 'The toy is unavailable',
    'cart.page.toy.unavailable.modal.description': 'The toy with price and number is no longer available',
}
