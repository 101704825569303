export const goldPlans = {
    'gold.plans.text_0': '畅游 ChikoRoko 世界的入门礼包！你的第一步从这里开始。优惠仅此一次，千万不要错过这个机会！',
    'gold.plans.level_0': '入门礼包',
    'gold.plans.text_1': '小步骤是取得大成就的正确途径。这个伟大的礼包肯定会对启动您的旅程有所帮助。',
    'gold.plans.text_2': '这个礼包是为那些了解游戏，知道他们想要什么的热情收藏家准备的！',
    'gold.plans.text_3': '谁将会成为最酷、最富有的 ChikoRokers ？也许是您？有了这个礼包，您的玩偶们肯定会看起来最酷！',
    'gold.plans.text_4': '您就是 ChikoRoko 的皇室成员！就在我们说话的时候，我们已经在准备您的王冠！长寿与繁荣！',
    'gold.plans.level_1': '新手',
    'gold.plans.level_2': '收藏家级别',
    'gold.plans.level_3': '超级收藏家级别',
    'gold.plans.level_4': '真正的 chikoroker 级别',
}
