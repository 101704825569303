export const ui = {
    'count.toys': '<count></count> Toys',
    edit: 'Edit',
    'toy.id': 'Toy ID',
    gold: 'Gold',
    ruby: 'Ruby',
    history: 'History',
    toys: 'Toys',
    competition: 'Competition',
    ads: 'Advertisements',
    subscription: 'Subscription',
    sell: 'Sell',
    sellers: 'Sellers',
    'select.your.country': 'Select your country',
    'select.your.payment.method': 'Select your payment method',
    'link.copied': 'Link copied',
    'empty.list.title': "It's empty",
    'empty.list.text': "You may have been looking for something, but it's not here",
    'unavailable.post.title': 'Post Unavailable',
    'unavailable.post.text': 'Sorry, this post is currently unavailable. It might have been removed or hidden by the author. Please check back later.',
    successful: 'Successful!',
    unsuccessful: 'Unsuccessful',
    info: 'Info',
    'any.count': 'Any amount',
    collected: 'Collected',
    'empty.request': 'Nothing was found for your request. <br/> Please change your query.',
    rubies: 'Rubies',
    verified: 'Verified',
    'kyc.processed': 'Apply for KYC (processing)',
    'kyc.apply': 'Apply for KYC',
    'kyc.error': 'Failed, please try again',
    'provider.facebook': 'Facebook',
    'provider.google': 'Google',
    'provider.tiktok': 'Tiktok',
    'provider.telegram': 'Telegram',
    'provider.vk': 'VK',
    'provider.discord': 'Discord',
    'provider.wallet': 'Wallet',
    'provider.instagram': 'Instagram',
    'provider.x': 'X',
    'nihaopay.confirming.payment': 'Confirming payment...',
    'nihaopay.confirming.payment.failed': 'Payment could not be confirmed!',
    'nihaopay.generate.invoice': 'Wait a minute... we create an invoice!',
    'video.ads.adBlock': 'You can get the award only after turning off <span>AdBlock</span>',
    'video.ads.success.watched': 'video has been successfully viewed, stand by',
    'video.ads.reject.watched': 'You have to watch the video for at least 60 seconds to win the prize',
    'social.chat.eng': 'English chat',
    'social.chat.rus': 'Russian chat',
    'social.chat.cn': 'Chinese chat',
    'drag.to.move': 'Drag to move',
    'doublecheck.your.email': 'Doublecheck your email',
    'doublecheck.your.nickname': 'Doublecheck your nickname',
    'file.uploaded': '{type} uploaded',
    'placeholder.title': 'Title',
    'placeholder.write.your.thoughts': 'Write your thoughts...',
    'enter.collection.name': 'Enter collection name',
    'enter.artist.name': 'Enter artist name',
}
