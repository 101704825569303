export const toys = {
    'toys.title': 'Begin  <span>your collection</span> now',
    'toys.collected.title': 'Collected today',
    'toys.blindbox.title': 'BLINDBOX',
    'toys.partner.title': 'PARTNER DROP',
    'toys.real.title': 'REAL TOY',
    'toys.real.text': 'Sales start in',
    'toys.subscription.only': 'Subscription <br/> only',
    'toys.blindbox.text': 'First free',
    'toys.collect.free': 'Collect free toys every 24 hours',
    'toys.welcome.title': 'Welcome to ArToys!',
    'toys.rarity.title': '<span>Rarity</span> chart',
    'toys.rarity.subtitle': 'Each of our toys has its circulation:',
    'toys.about.us.title': 'People <span>about us</span>',
    'toys.toys.title': 'Explore our <span>toys</span>',
    'toys.leaders.title': 'Top leaders <br /> of the',
    'toys.leaders.collected': 'Collected',
    'toys.leaders.earned': 'Earned',
    'toys.leaders.burned': 'Burned',
    'toys.choose.hours': 'Choose how many hours are needed?',
    'toys.choose.hours.error': "You don't have enough gold for this time. <br / > Add Gold or choose a shorter time.",
    'toys.retro.title': 'RANDOM  TOY',
    'toys.month.price': 'Only 9.99$/Month',
    'toys.ruby.for.friends': 'Ruby for friends',
    'toys.premium': 'Premium',
    'toys.toys.collected': 'Toys <br/> Collected',
    'toys.friends.invited': 'Friends <br/> Invited',
    'toys.free.toys': 'FREE TOYS',
    'toys.top.offers': 'TOP OFFERS',
    'toys.set.gifts': 'SET GIFTS',
    'toys.collected.all.description': 'You collected all toys',
    'toys.daily.title': 'DAILY TOY',
    'toys.weekly.title': 'WEEKLY TOY',
    'toys.artclub.title': 'ART CLUB',
    'toys.premium.label': 'No ads with Premium',
    'toys.skip': 'Skip',
    'toys.subscription.description': 'With a subscription you can sell your toys on the marketplace',
    'toys.subscription.title': 'Subscription',
    'toys.welcome.answer1':
        'ArToys is a hub for toy collectors, artists, and brands to come together. We offer a distinctive experience with easy access, daily surprises, and a lively community. Created by passionate toy collectors for collectors.',
}
