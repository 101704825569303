import { LinksEnum } from '@specs/navigation'

const url = `${process.env.NEXT_PUBLIC_BASE_URL}${LinksEnum.API_GA4_AD_FORM_COOKIE}`

export const useSetAdformCookie = () => {
    const fetchAdForm = async (id: string = '') => {
        try {
            await fetch(url, { method: 'POST', body: JSON.stringify({ id }) })
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error)
        }
    }

    return { fetchAdForm }
}
