export const publicProfile = {
    'public.profile.settings': 'Настройки',
    'public.profile.username': 'Ник',
    'public.profile.name': 'Имя',
    'public.profile.surname': 'Фамилия',
    'public.profile.email': 'E-mail',
    'public.profile.validation': 'В нике можно использовать только латинские буквы (A-Z), цифры, знаки подчеркивания и точки. При смене ника, ссылка на ваш профиль также изменится.',
    'public.profile.symbols': 'От 5 до 20 знаков',
    'public.profile.upload.avatar.413': 'Размер запроса превышает допустимый предел',
    'public.profile.followed': 'Подписки',
    'public.profile.copy.link.to.profile': 'Скопировать',
    'public.profile.followers.count': '<count></count> подписчиков',
    'public.profile.following.count': '<count></count> подписки',
    'public.profile.empty.title': 'Ого, как пусто',
    'public.profile.empty.text': 'Давай это исправим!',
    'public.profile.filter.all': 'Все',
    'public.profile.filter.created': 'Создано',
    'public.profile.filter.collected': 'Собрано',
    'public.profile.filter.under.moderation': 'На модерации',
    'public.profile.filter.on.sale': 'В продаже',
}
