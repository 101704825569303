import React, { FC } from 'react';
import s from './QuizEnd.module.scss';
import { useTranslation } from '@hooks/useTranslation';
import { Button } from '@components/UIKit/Button/Button';
import { QuizLayout } from '../QuizLayout/QuizLayout';
interface Props {
  closeHandler: () => void;
}
export const QuizEnd: FC<Props> = ({
  closeHandler
}) => {
  const {
    translate: tr
  } = useTranslation();
  return <QuizLayout data-sentry-element="QuizLayout" data-sentry-component="QuizEnd" data-sentry-source-file="QuizEnd.tsx">
            <div className={s.container}>
                <p className={s.container__title}> {tr({
          id: 'quiz.end.title'
        })}</p>
                <p className={s.container__text}> {tr({
          id: 'quiz.end.text'
        })}</p>
                <Button className={s.container__btn} id='default_button' size={'large'} color='Black' onClick={closeHandler} data-sentry-element="Button" data-sentry-source-file="QuizEnd.tsx">
                    {tr({
          id: 'btn.close'
        })}
                </Button>
            </div>
        </QuizLayout>;
};