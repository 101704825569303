export const postPage = {
    'post.page.title': '{name} - 设计师玩具行业的见解和趋势 | ToyVerse',
    'post.page.description': '{name}。在 ToyVerse 了解最新趋势、设计师和收藏玩具。',
    'post.create.page.title': '新帖子',
    'post.copy.link.to.post': '复制帖子链接',
    'post.edit.post': '编辑帖子',
    'post.block.this.author': '举报作者',
    'post.block.this.post': '举报帖子',
    'post.delete.post.modal.title': '删除帖子',
    'post.delete.post.modal.description': '您确定要删除此帖子吗？无法恢复。',
    'post.validate.message': '请注意，帖子必须包含至少一张图片，最多允许 10 张图片。每张图片的比例必须为 16:9，文件大小不得超过 5MB。最大字符数为 10,000。',
    'post.image.title': '帖子 {title} | 在 R.Toys 阅读更多内容',
    'post.image.title.default': '帖子 | 在 R.Toys 阅读更多内容',
}
