export const publicProfile = {
    'public.profile.settings': '设置',
    'public.profile.username': '用户名',
    'public.profile.name': '名字',
    'public.profile.surname': '姓氏',
    'public.profile.email': '电子邮箱',
    'public.profile.validation': '只能包含拉丁字母 (A-Z) ，数字，下划线与点。当你更改了用户名后，你的个人资料链接也会改变。',
    'public.profile.symbols': '5 至 20 个字符。',
    'public.profile.upload.avatar.413': '请求实体太大',
    'public.profile.followed': '已关注',
    'public.profile.copy.link.to.profile': '复制个人资料链接',
    'public.profile.followers.count': '<count></count> 位关注者',
    'public.profile.following.count': '关注 <count></count> 人',
    'public.profile.empty.title': '哇，这么空',
    'public.profile.empty.text': '让我们来填充一下！',
    'public.profile.filter.all': '全部',
    'public.profile.filter.created': '已创建',
    'public.profile.filter.collected': '已收集',
    'public.profile.filter.under.moderation': '审核中',
    'public.profile.filter.on.sale': '出售',
}
