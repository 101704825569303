export const createToy = {
    'create.toy.title': 'Создать игрушку',
    'create.toy.general.information.title': 'Общая информация',
    'create.toy.digital.toy': 'Цифровая игрушка',
    'create.toy.digital.twin': 'Цифровой двойник',
    'create.toy.upload.image.uploaded': 'Загружено',
    'create.toy.preferred.formats.description': 'Предпочтительные форматы: {formats}',
    'create.toy.additional.files': 'Дополнительные файлы (необязательно)',
    'create.toy.toy.name.label': 'Название игрушки',
    'create.toy.toy.name.placeholder': 'Введите здесь',
    'create.toy.toy.name.description': 'Придумайте название для вашей игрушки',
    'create.toy.description.label': 'Описание (необязательно)',
    'create.toy.description.placeholder': 'Расскажите нам о своей игрушке',
    'create.toy.alternative.collect.label': 'Альтернативный сбор',
    'create.toy.alternative.collect.description': 'Альтернативный сбор — это способ получения игрушек на платформе, выполняя определенные действия, такие как подписка на автора, просмотр видео или выполнение квеста.',
    'create.toy.circulation.label': 'Тираж',
    'create.toy.circulation.description': 'Чем меньше количество, тем выше редкость',
    'create.toy.price.title': 'Цена',
    'create.toy.price.description': 'Введите значение от {minPrice} до вашего максимума',
    'create.toy.communication.method': 'Предпочтительный способ связи',
    'create.toy.default.email.label': 'Основной E-mail',
    'create.toy.email.label': 'E-mail',
    'create.toy.required.email.label': 'E-mail (обязательно)',
    'create.toy.telegram.label': 'Telegram',
    'create.toy.instagram.label': 'Instagram',
    'create.toy.forgot.error': 'Вы забыли «@»',
    'create.toy.privacy.text':
        'Отправляя эту форму, вы соглашаетесь с нашей <privacy>Политикой конфиденциальности</privacy> и <terms>Условиями использования</terms>. Ваша информация будет храниться в секрете и не будет передана третьим лицам без вашего согласия.',
    'create.toy.digital.toy.modal.title': 'Цифровая игрушка',
    'create.toy.digital.toy.modal.description':
        'Этот тип игрушки могут покупать другие пользователи на странице пользователя и на странице с рекомендациями через модальное окно покупки (не через корзину). Предложения от художника всегда имеют приоритет.',
    'create.toy.digital.twin.modal.title': 'Цифровой двойник',
    'create.toy.digital.twin.modal.description':
        'Этот тип игрушки можно собрать другим пользователям, сканируя QR-код, который создает секретную ссылку для сбора. Художники размещают эти QR-коды с физическими игрушками.',
    'create.toy.upload.render.error.max.files': 'Вы можете загрузить максимум 2 файла.',
    'create.toy.upload.render.error.only.one': 'Можно загрузить только 1 файл {type}.',
    'create.toy.something.is.wrong': 'Что-то пошло не так',
    'create.toy.uploading.progress': 'Загрузка в процессе...',
    'create.toy.might.take.bit.time': 'Это может занять немного времени',
}
