import React, { FC } from 'react';
import s from './QuizProgressBar.module.scss';
interface Props {
  total: number;
  current: number;
}
export const QuizProgressBar: FC<Props> = ({
  total,
  current
}) => {
  const validCurrent = Math.max(0, Math.min(current, total));
  const progressPercentage = validCurrent / total * 100;
  return <div className={s.container} data-sentry-component="QuizProgressBar" data-sentry-source-file="QuizProgressBar.tsx">
            <p className={s.container__stage}>{validCurrent}/{total}</p>
            <div className={s.bar}>
              <div className={s.bar__filled} style={{
        width: `${progressPercentage}%`
      }} />
            </div>
        </div>;
};