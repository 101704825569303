export const toyPage = {
    'toy.card.image.title': '{name} | R.Toys上的珍藏版设计师玩具',
    'toy.page.title': '由{name}製作的{artistName} | R.Toys上的典藏設計師玩具',
    'toy.page.image.title': '{name} | R.Toys上的珍藏版设计师玩具',
    'toy.page.description': '{name}是由{artistName}創造的獨特玩具。在R.Toys探索更多關於收藏設計師玩具的資訊。',
    'toy.page.artist.by': '以 {artistName}',
    'toy.page.part.of.collection': ' “{collectionName}”收藏品的一部分',
    'toy.page.stats.release': '发布',
    'toy.page.stats.collected': '已收集',
    'toy.page.stats.burned': '已烧毁',
    'toy.page.about.toy.title': '关于玩具',
    'toy.page.collection.title': '收藏品',
    'toy.page.collection.stats.collected': '已收集',
    'toy.page.collection.stats.toys': '玩具',
    'toy.page.artist.title': '关于艺术家',
    'toy.page.artist.stats.followers': '关注者',
    'toy.page.artist.stats.toys': '玩具',
    'toy.page.offers.title': '<count></count> 报价',
    'toy.page.history.title': '历史',
    'toy.page.history.date.text': '{date} 在 {time}',
    'toy.page.you.might.like': '你可能会喜欢',
    'toy.page.added.to.cart.title': '“{name}” 已添加到你的购物车',
    'toy.page.added.to.cart.description': '进入购物车购买',
    'toy.page.ar.unavailable.title': 'AR视图不可用',
    'toy.page.ar.unavailable.description': '要使用AR查看玩具，必须先拥有该玩具',
    'toy.page.ar.available.title': '在 AR 中查看',
    'toy.page.ar.available.description': '要在 3D 和 AR 中查看此礼物，请在智能手机上打开此页面',
    'toy.page.toy.collected.modal.title': '{name} 现在属于你的了',
    'toy.page.toy.collected.modal.description': '您已成功完成任务，并获得该玩具。请在“我的玩具”页面查看',
    'toy.page.collect.buying.modal.title': '购买',
    'toy.page.created': '创建',
    'toy.page.task.title': '完成任务并获得一个玩具',
    'toy.page.task.follow': '关注艺术家',
    'toy.page.task.complete': '完成上述步骤后，你就可以领取玩具了。恭喜你！',
    'toy.page.sell.without.instance.id.description': "You can't sell this toy until an instance ID is assigned",
    'toy.page.digital.toy': '数字玩具',
    'toy.page.digital.twin': '数字孪生',
    'toy.page.generate.code.modal.title': '生成代码',
    'toy.page.generate.code.modal.input.label': '代码数量',
    'toy.page.generate.code.modal.input.description': '输入需要生成的代码数量',
    'toy.page.generate.code.modal.amount.on.page': '页面上的元素数量',
    'toy.page.generate.code.modal.download.title': '完成！',
    'toy.page.generate.code.modal.download.text': '下载将自动开始于...',
    'toy.page.generated.codes.title': '生成的代码',
    'toy.page.download.codes.modal.title': '下载',
    'toy.page.generated.codes.empty.text': '尚未生成任何代码',
    'toy.page.collect.the.toy': '领取玩具',
}
