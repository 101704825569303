export const quiz = {
    'quiz.message': '请参加一个关于应用程序可用性的简短调查。',
    'quiz.go': '开始',
    'quiz.survey': '调查',
    'quiz.end.title': '感谢您的反馈！',
    'quiz.end.text': '您的意见对我们非常重要，我们一定会听取您的建议来改进我们的产品。',
    'quiz.how.convenient': '您觉得这个产品使用起来有多方便？',
    'quiz.comment.label': '请在此留下您的评论',
    'quiz.comment.placeholder': '告诉我们您喜欢什么',
    'quiz.question.1': '您使用 R.Toys 多久了？',
    'quiz.answer.1.1': '不到一个月',
    'quiz.answer.1.2': '1-6 个月',
    'quiz.answer.1.3': '超过 6 个月',
    'quiz.question.2': '您多久使用一次 R.Toys？',
    'quiz.answer.2.1': '每天',
    'quiz.answer.2.2': '每周几次',
    'quiz.answer.2.3': '每月几次',
    'quiz.answer.2.4': '更少',
    'quiz.question.3': '您是如何得知 R.Toys 的？',
    'quiz.answer.3.1': '广告',
    'quiz.answer.3.2': '朋友或熟人推荐',
    'quiz.answer.3.3': '社交网络',
    'quiz.answer.3.4': '其他来源',
    //
     //
     'quiz.greetings': 'Help us improve R.Toys!',
     'quiz.description': `We're researching how collectors like you discover and buy art toys. Take our`,
     'quiz.time': ' 5–7 min survey',
     'quiz.survey_link': 'Survey link',
     'quiz.take_part': 'Or join a ',
     'quiz.interview': '20–30 min video chat ',
     'quiz.take_part_2': 'to share your insights! (Participants get an exclusive digital art toy!)',
     'quiz.telegram': 'Telegram link',
}
