export const postPage = {
    'post.page.title': '{name} - Insights & Trends in the Designer Toys Industry | R.Toys',
    'post.page.description': '{name}. Find out more about the latest trends, designers and collectible toys at R.Toys.',
    'post.create.page.title': 'New post',
    'post.copy.link.to.post': 'Copy link to post',
    'post.edit.post': 'Edit post',
    'post.delete.post.modal.title': 'Delete post',
    'post.block.this.author': 'Report this author',
    'post.block.this.post': 'Report this article',
    'post.delete.post.modal.description': 'Are you sure you want to delete the post? Unfortunately, it can’t be restored.',
    'post.validate.message':
        'Please note that the post must include at least one image, with a maximum of 10 images allowed. Each image must have a 16:9 ratio, and the file size must not exceed 5MB. The maximum character count is 10,000.',
    'post.image.title': 'Post {title} | Read more on R.Toys',
    'post.image.title.default': 'Post | Read more on R.Toys',
}
