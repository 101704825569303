// QuizQuestions.tsx
import React, { FC } from 'react';
import s from './QuizQuestions.module.scss';
import { Checkbox } from '@components/UIKit/Checkbox/Checkbox';
import { Button } from '@components/UIKit/Button/Button';
import { Dictionary, useTranslation } from '@hooks/useTranslation';
import { AnswerId, IQuestion, QuestionId } from '../QuizQuestionsTypeData';
interface QuizQuestionsProps {
  questionData: IQuestion;
  handleSelect: (data: {
    questionId: QuestionId;
    answerId: AnswerId;
  }) => void;
  selectedValue: AnswerId | null;
  moveToPrevStage: () => void;
  stage: number;
}
export const QuizQuestions: FC<QuizQuestionsProps> = ({
  questionData,
  handleSelect,
  selectedValue,
  moveToPrevStage,
  stage
}) => {
  const {
    translate: tr
  } = useTranslation();
  const questionKey = `quiz.question.${questionData.questionId}` as Extract<Dictionary, `quiz.question.${number}`>;
  return <div className={s.container} data-sentry-component="QuizQuestions" data-sentry-source-file="QuizQuestions.tsx">
            <p className={s.question}>{tr({
        id: questionKey
      })}</p>
            <div className={s.variants}>
                {questionData.answers.map(answerId => {
        const answerKey = `quiz.answer.${questionData.questionId}.${answerId}` as Extract<Dictionary, `quiz.answer.${number}.${number}`>;
        return <Checkbox key={`${questionData.questionId}-${answerId}`} variant='circle' checked={selectedValue === answerId} label={tr({
          id: answerKey
        })} onChange={() => handleSelect({
          questionId: questionData.questionId,
          answerId
        })} boxClassName={s.checkbox} labelClassName={s.label} />;
      })}
            </div>
            {stage > 1 && <Button className={s.btn} id='default_button' size={'large'} color={'White'} onClick={moveToPrevStage}>
                    {tr({
        id: 'btn.back'
      })}
                </Button>}
        </div>;
};