export const publicProfile = {
    'public.profile.settings': 'Settings',
    'public.profile.username': 'Username',
    'public.profile.name': 'Name',
    'public.profile.surname': 'Surname',
    'public.profile.email': 'Email',
    'public.profile.validation': 'Only latin letters (A-Z), numbers, underscores and dots can be used in the username. When you change your username, your profile link will also change.',
    'public.profile.symbols': '5 to 20 characters/symbols',
    'public.profile.upload.avatar.413': 'Request Entity Too Large',
    'public.profile.followed': 'Followed',
    'public.profile.copy.link.to.profile': 'Copy link to profile',
    'public.profile.followers.count': '<count></count> Followers',
    'public.profile.following.count': '<count></count> Following',
    'public.profile.empty.title': 'Wow such empty',
    'public.profile.empty.text': 'Let’s fix this!',
    'public.profile.filter.all': 'All',
    'public.profile.filter.created': 'Created',
    'public.profile.filter.collected': 'Collected',
    'public.profile.filter.under.moderation': 'Under Moderation',
    'public.profile.filter.on.sale': 'On Sale',
}
