import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-virtualized/styles.css'; // only needs to be imported once
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/free-mode';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import '../styles/index.scss';

// import 'swiper/css/navigation';
import parse from 'html-react-parser';
import { DefaultSeo, NextSeoProps } from 'next-seo';
import type { AppProps } from 'next/app';
//@ts-ignore
import { api } from '@api';
import YandexMetrika from '@components/Metrics/Yandex';
import { useSetAdformCookie } from '@hooks/useSetAdformCookie';
//@ts-ignore
import { ChikoSession, script } from '@specs/specs';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { useEffect } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import Providers from '../Providers';
const tagManagerArgs = {
  gtmId: 'GTM-PTZRWPZK'
} satisfies TagManagerArgs;
const DynamicToastContainer = dynamic(() => import('react-toastify').then(mod => mod.ToastContainer), {
  ssr: false
});
function App({
  Component,
  pageProps: {
    session,
    agent,
    meta,
    scripts,
    accept,
    ...pageProps
  }
}: AppProps<{
  agent: string;
  session: ChikoSession;
  meta: NextSeoProps;
  scripts: script[];
  accept: {
    webp: boolean;
  } | null | undefined;
}>) {
  const {
    fetchAdForm
  } = useSetAdformCookie();
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV !== 'prod' || typeof window?.Adform?._uid !== 'string') return;
    fetchAdForm(window.Adform._uid);
  }, []);
  return <>
            {scripts?.length > 0 && parse(scripts[0].data, {
      replace: domNode =>
      // @ts-ignore
      <Script strategy='lazyOnload' {...domNode.attribs} />,
      htmlparser2: {
        lowerCaseTags: false
      }
    })}

            <DefaultSeo title='R.Toys - The Ultimate App for the Designer Toys Industry | Uniting Designers, Brands, and Collectors' description={'R.Toys: the one and only platform that brings together art toy designers, brands, and collectors, bridging the gap between digital and physical collectibles in the designer toys industry. '} canonical={process.env.NEXT_PUBLIC_BASE_URL} openGraph={{
      title: '2',
      description: 'R.Toys: the one and only platform that brings together art toy designers, brands, and collectors, bridging the gap between digital and physical collectibles in the designer toys industry. ',
      type: 'website',
      url: `${process.env.NEXT_PUBLIC_BASE_URL}`,
      images: [{
        url: `${process.env.NEXT_PUBLIC_BASE_URL}/apple-touch-icon.png`,
        width: 512,
        height: 512,
        alt: 'R.Toys'
      }]
    }} twitter={{
      site: '@rtoysapp',
      handle: '@rtoysapp',
      cardType: 'summary_large_image'
    }} additionalMetaTags={[{
      name: 'twitter:title',
      content: 'R.Toys - The Ultimate App for the Designer Toys Industry | Uniting Designers, Brands, and Collectors'
    }, {
      name: 'twitter:description',
      content: 'R.Toys: the one and only platform that brings together art toy designers, brands, and collectors, bridging the gap between digital and physical collectibles in the designer toys industry. '
    }, {
      name: 'twitter:image',
      content: `${process.env.NEXT_PUBLIC_BASE_URL}/apple-touch-icon.png`
    }]} {...meta} data-sentry-element="DefaultSeo" data-sentry-source-file="_app.tsx" />

            <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
                {process.env.NEXT_PUBLIC_ENV === 'prod' && <YandexMetrika yid={'92789664'} uid={session?.uid} />}
                <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <link rel='icon' type='image/png' sizes='32x32' href={'/favicon.png'} />
                <link rel='preconnect' href='https://chikoroko.b-cdn.net' />
                <link rel='preconnect' href='https://imagedelivery.net' />
                <link rel='apple-touch-icon' type='image/png' sizes='512x512' href={'/apple-touch-icon.png'} />
                <meta name='apple-mobile-web-app-title' content={'R.Toys'} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
            </Head>
            {process.env.NEXT_PUBLIC_ENV === 'prod' && <GoogleAnalytics trackPageViews />}
            {process.env.NEXT_PUBLIC_ENV === 'prod' && <Script type='text/javascript' strategy='afterInteractive' src='https://onsite.optimonk.com/script.js?account=220964' async />}
            {process.env.NEXT_PUBLIC_ENV === 'prod' && <Script type='text/javascript' strategy='afterInteractive' src='https://track.adform.net/Serving/Cookie/?adfaction=getjs;adfcookname=uid' async />}
            <Providers session={session} accept={accept} data-sentry-element="Providers" data-sentry-source-file="_app.tsx">
                <Component {...pageProps} agent={agent} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                <DynamicToastContainer position='top-right' hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover closeButton={false} data-sentry-element="DynamicToastContainer" data-sentry-source-file="_app.tsx" />
            </Providers>
        </>;
}
export default api.withTRPC(App);