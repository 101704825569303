export const errors = {
    'error.default': 'Something went wrong. Try again later.',
    'error.metamask': 'MetaMask is not installed!',
    'main.drops.notFound': 'Drop not found',
    'main.drops.noToy': 'Drop have no toy',
    'main.drops.notAvailable': 'All toys already collected',
    'main.drops.alreadyOwned': 'You have this toy already',
    'main.drops.artClub': 'Only art club members',
    'main.drops.region.restricted': 'This drop is not available in your region',
    'main.drops.wrongSecret': 'Wrong secret code for secret drop',
    'main.blindbox.limit.exhausted': 'BlindBox opening limit exhausted',
    'main.blindbox.balance.notenough': 'Not enough gold',
    'main.blindbox.toys.empty': 'All toys are gone',
    'main.blindbox.color.dublicate': 'This color is already opened',
    'main.bonusToy.alreadyOwned': 'Bonus toy already recived',
    'main.bonusToy.notBonus': 'This toy is not a bonus',
    'main.bonusToy.notAvailable': 'No more available toys',
    'main.bonusToy.notAllCollected': 'Not all toys collected',
    'main.bonusToy.notfound': 'Toy not found',
    'main.profiles.shortId.conflict': 'This name is already taken',
    'main.profiles.shortId.length.min': 'Name length must have more than 5 symbols',
    'main.profiles.shortId.length.max': 'Name length be less than 20 symbols',
    'main.profiles.shortId.filter': 'Name can contain only symbols and digits',
    'main.rack.not.owner': 'Only owner can edit rack',
    'main.rack.free.limit.exhausted': 'Only 3 racks available for free',
    'main.rack.balance.notenough': 'Not enough gold',
    'main.rack.profile.not.found': 'Profile not found. Please login again',
    'main.leaderboard.stats.notFound': 'User statistic for leaderboad not found. This is ok for new user',
    'main.profiles.notFound': 'Profile not found.',
    'burn.not.owner': 'You must be owner',
    'burn.toy.notfound': 'Toy not found',
    'burn.toy.one.restricted': 'Cannot burn one and only toy',
    'burn.config.rarity.notfound': 'Technical: Rarity not found',
    'burn.config.rate.notfound': 'Technical: Rarity rates not found',
    'burn.toy.supplied.null': 'Technical: No Supplied toys',
    'burn.toy.limit.exhausted': 'You can burn only 50 figures per day',
    'burn.toy.store.exists': 'You cannot burn a figurine that is already on sale. Need to cancel sale first',
    'main.unauthurised': 'You need to login first',
    'main.upload.no.nontent-type': 'Content type header not present',
    'main.upload.not.image': 'Not image',
    'main.upload.not.supported': 'File type not supported',
    'main.rack.worng.price': 'Price mismatch',
    'cloud.intent.error': 'Error in external service. Cannot init upload.',
    'cloud.intent.not.found': 'Uploaded file not found by present id',
    'cloud.intent.draft': 'Upload not finished yet',
    'burn.toy.rack.member': 'You need to remove this figure from your rack first.',
    'main.store.toy.restricted': 'This item is restricted for sale',
    'main.store.purchase.price.mismatch': 'Price was changed',
    'main.store.purchase.item.not.found': 'Sale item not found. Probobly someone already bought it.',
    'main.kyc.required': 'Need to pass kyc first',
    'main.profiles.shortId.limit': 'You can only change your username once in 24 hrs',
    'main.store.notFound': 'Item not found in store',
    'main.store.price.negative': 'Price cannot be less than 50',
    'main.store.price.fractional': 'Price cannot have a fractional part',
    'main.store.ownership.not.found': 'You must bbe owner',
    'main.store.dublicate': 'This toy is already on sale',
    'system.ban.dublicate': 'Already banned',
    'system.profile.notfound': 'Profile not found',
    'system.transaction.error': 'System error. Please try again or contact tech support',
    'store.item.not.available': 'The toy with this price and number is no longer available.',
    'store.item.ended': "Sorry, you're too late. This toy has ended.",
    'cart.is.full': 'Sorry, your cart is full! You can only add 15 items to your cart',
    'main.rack.variation.not.found': 'Rack variation with provied id not found',
    'main.competitions.no.available.': 'System. No mode competitions available. All are completed',
    'main.competitions.not.completed': 'Not all actions been complete yet.',
    'main.competitions.already.completed': 'This competition already been completed',
    'main.competitions.wrong.mechanics': 'Collect with competition available only for Daily and Weekly Drops',
    'main.competitions.service.error': 'External service not available. Please try again later',
    'payments.product.not.found': 'System Error. Requested product not configured. Please contant tech support',
    'payments.subscription.not.found': 'This user have no active subscription plan',
    'payments.provider.not.supported': 'This provider not supported',
    'main.drops.retro.timer': '(change me) Retro Drop time not come',
    'drops.retro.skipped.dublicate': 'Retro drop already skipped',
    'payments.stripe.product.delete.error': 'An error occurred while deleting a product in Stripe',
    'payments.stripe.product.not.found': 'Could not find a matching product in Stripe',
    'payments.stripe.price.not.found': 'Failed to find a matching price in Stripe',
    'main.subscription.required': 'This action requires active subscription',
    'promocode.generate.error': 'Failed to generate Promo Codes',
    'promocode.transaction.error': 'Failed to apply Promo Codes ',
    'promocode.cannot.find.active': 'Could not find an active promo code ',
    'system.idempotency.error': 'This operation already been performed',
    'main.ads.notWatched': 'Ads Video was not watched',
    'main.ads.wrong.mechanics': 'Collect with Ads available only for Daily and Weekly Drops',
    'main.draft.donotFind': 'Draft did not found',
    'main.draft.trx.error': 'Failed to porcess Draft',
    'main.balance.red': 'You have not enough balance',
    'main.store.purchase.balance.red': 'You have not enough balance',
    'personal.account.myToy.upload.model.need.to.upload': 'You need to upload all media files',
    'personal.account.myToy.upload.model.need.to.allFileds': 'You must fill in the basic fields',
    'payout.balance.limit.exhausted': 'Minimum withdrawal amount is 100 rubies. Please try again later',
    'gold.give.error': 'Нельзя обновить больше чем пришло.',
    'swapUserEmails.old.user.not.found': 'old account not found',
    'swapUserEmails.new.user.not.found': 'new account not found',
    'swapUserEmails.cannot.swap.regular.users': 'Failed to swap user profiles with regular mail',
    'swapUserEmails.cannot.swap.accounts.users': 'failed to swap user profiles with profiles containing accounts',
    'swapUserEmails.cannot.swap.regular.account.users': 'failed to swap user profiles with old google | apple account and new one with regular mail',
    'swapUserEmails.cannot.swap.account.regular.users': 'failed to swap user profiles with the new google | apple account and the old one with regular mail',
    'swapUserEmails.first.package.error': 'error in the first transaction packet',
    'swapUserEmails.second.package.error': 'error in the second transaction packet',
    'swapUserEmails.profiles.not.found': 'failed to retrieve user profiles',
    'swapUserEmails.profile.artists.swap': 'failed to declare the users artists',
    'swapUserEmails.profile.avatar.swap': 'user avatars could not be announced.',
    'kyc.always.green': 'Kyc always Green',
    'payments.subscription.isPendingFinish': 'Подписка уже была отключена',
    'share.not.valide.code': 'This is not valide code to this post',
    'share.not.exist': 'Unable to find SharePost',
    'toy.not.exist': 'Couldnt find the toy',
    'artist.not.exist': 'Couldn`t find the artist',
    'cannot.create.share.post': 'Failed to create SharePost',
    'share.cannot.transfer.100.gold': 'cannot transfer 100 gold',
    'share.cannot.transfer.25.gold': 'cannot transfer 25 gold',
    'share.cannot.transfer.wright.reward': 'failed to record confirmation information',
    'share.cannot.found.any.posts': 'share.cannot.found.any.posts',
    'share.cannot.apply.ur.own.code': 'You can`t validate your code',
    'openEdition.toy.is.used.in.drop': 'Игрушка уже используется в другом дропе, ее нельзя использовать в механике openShare',
    'openEdition.is.not.found': 'У игорушки не было найдено активного openEdition статуса',
    'rarity.not.found': 'Rarity not found',
    'openEdition.incorrect.time.range': 'openEdition.incorrect.time.range',
    'elastic.update.index.error': 'elastic.update.index.error',
    'elastic.update.index.success': 'elastic.update.index.success',
    'collection.pack.save.rates.error': 'Cannot save pack rarity rates',
    'collection.pack.save.rates.success': 'Success save pack rarity rates',
    'partner.line.collection.dont.match': 'partner.line.collection.dont.match',
    'partner.dont.find': 'partner.dont.find',
    'partner.line.length.dont.metch': 'The lines should be the same length as the rest of the partners lines, make sure you enter the correct values',
    'partner.pack.delay': 'At least 2 minutes must have passed since the last purchase',
    'main.partners.line.notcollected': 'Not every toy of line is collected',
    dateNotAllowed: 'publication date has not yet arrived',
    'convert.usd.error': 'Cannot convert usd to different currency',
    'convert.currency.do.not.found': 'currency dont found',
    'offers.price.min.gold': 'Price must be greater than 50 gold',
    'offers.price.min.ruby': 'Price must be greater than 1 ruby',
    'offers.instanceid.not.found': 'This toy number is not collected',
    'offers.offer.not.found': 'Offer not found',
    'offers.is.not.owner': 'This offer was created by abother user. Cannot perform operation',
    'offers.ownership.not.found': 'Ownership not found',
    'partner.line.lineMechanics.dont.match': 'lineMechanics dont match',
    'shippingRequest.create.error': 'Failed to send Shipping request ',
    'partner.line.mechanic.dont.exist': 'partner.line.mechanic.dont.exist',
    'partner.line.rarites.dont.match': 'partner.line.rarites.dont.match',
    'ownership.not.owner': 'you must be owner of this asset',
    'ownership.already.minted': 'Nft is already minted',
    'system.wallet.not.connected': 'You must connect wallet for this operation',
    'wallet.connected.to.another.account': "Sorry, you can't connect this wallet.Please connect the wallet you provided before.",
    'system.contract.error': 'Blockchain interaction error. Please contact tech support',
    'store.nft.not.owner': 'This toy have minted nft. Owner address must be same as your connected wallet addres to perform this operation.',
    'ownership.mint.partner.required': 'Currently mint allowed only for partners collections',
    'toy.mint.disabled': 'mint for this toy is not possible',
    'quest.not.completed': 'Quest requirements not completed',
    'quest.already.completed': 'Quest already completed',
    'quest.finished': 'Quest is already finished',
    'quest.profile.already.have.subscription': 'This quest is available only for users without subscription',
    'offersBanner.not.found': 'Offers Banner with this type dont found',
    'blindbox.is.not.exist': 'no available blindBox was found',
    'blindbox.totalProbability.is.not.correct': 'problem with drop probabilities, contact support ',
    'blindbox.collect.noToy': 'failed to link the blindBox to a random toy',
    'blindbox.collect.notAvailable': 'All toys already collected',
    'blindbox.collect.trx.error': 'Failed to porcess Blindbox',
    'not.optimal.ruby.set': 'It was not possible to form an optimal set of products ',
    'main.invoice.notFound': 'Invoice not found',
    'main.coupon.notFound': 'Coupon not found',
    'main.form.fields.required': 'This field is required',
    'main.not.qr.exists': 'This name is already in use',
    'coupon.noVerify.requires.subscription': 'Coupon cannot be verified. Subscription required.',
    'coupon.validFrom.not.come': 'Coupon valid from date has not yet arrived.',
    'coupon.validUntil.end': 'Coupon validity has ended.',
    'coupon.alredy.used': 'Coupon has already been used.',
    'coupon.purchaseLimit.miss': 'Coupon purchase limit exceeded.',
    'coupon.minimumSpendThreshold.miss': 'Minimum spend threshold for coupon not met.',
    'coupon.currencyType.dont.match': 'The currency type of some products does not correct.',
    'coupon.not.reusable': 'Coupon is not reusable for this product.',
    'personal.account.myToy.toy.name.min': 'Toy name length must have more than 5 symbols',
    'personal.account.myToy.toy.name.max': 'Toy name length be less than 20 symbols',
    'toy.mint.no.contract': 'no configured contract for this toy',
    'main.social.delete.error': 'To unlink your Social Media, please add an alternative login method for your account.',
    'main.store.price.too.high': 'The number is too large. Try again',
    'post.not.author': 'This is not your post to edit',
    'upload.image.413': 'Request Entity Too Large',
    'personal.account.myToy.upload.model.error': 'Uploading {type} model error',
    'create.toy.invalid.form': 'Invalid form',
    'create.toy.toy.name.min.error': 'Toy name length must have more than {min} symbols',
    'create.toy.toy.name.max.error': 'Toy name length must have less than {max} symbols',
    'create.toy.upload.image.error': 'Unable to upload. Please check your file',
    'create.toy.price.error': 'Enter a price above the minimum price',
    'create.toy.circulation.error': 'Circulation is required',
    'crop.image.error': 'Crop image error',
    'no.file.provided': 'No file provided',
    'upload.failed': 'Upload failed',
    'please.check.your.file': 'Please check your {type} file',
    'generate.qr.codes.max.length': 'Max number of QR codes is 200',
    'error.block.alreadyBlocked': 'You have already blocked this user',
}
