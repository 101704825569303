import { product, toy } from '@specs/specs'
import { DropViewLight } from '@specs/views'
import { setCookie } from 'cookies-next'
import { LinksEnum } from '@specs/navigation'

export const CART_LIMIT = 15

export enum ListsLimitEnum {
    STORE_ITEMS = 10,
    GOLD = 10,
    SUBSCRIPTION = 10,
}

export enum DropsCardsSizeEnum {
    'small',
    'big',
}

export type DropsCardsSizeKeys = keyof typeof DropsCardsSizeEnum

export interface IDropDefault {
    id: number
    mechanic: DropViewLight['mechanic']
    collectAllowed: boolean
    size?: DropsCardsSizeKeys
    showFooter?: boolean
}

export const LeaderboardView = ['collected', 'burned', 'earned'] as const

export type LeaderboardViewType = (typeof LeaderboardView)[number]

export const ReformattingColors = (color: string) => (color.startsWith('--') ? `var(${color})` : color)

export interface ICartItemCurrency {
    productId: product['id']
    toyId: toy['id'] | null | undefined
    toyExpires: Date | null
    variant: 'gold' | 'ruby'
    price: number
    amount: number | null
    imageId: string
    amountOfProducts: number
}

export const formatNumber = (num: number) => (num / 100).toFixed(2)

export const getToyCollectedCount = ({ reserved, supplied, owned }: { reserved: number | null; owned: number; supplied: number | null }): number => {
    const reservedCountToDisplay = (reserved ?? 0) + owned >= (supplied ?? 0) ? (reserved ?? 0) : 0

    return owned + reservedCountToDisplay > (supplied ?? 0) ? (supplied ?? 0) : owned + reservedCountToDisplay
}

export const isRubyPage = (pathname: string) => pathname.includes(LinksEnum.PAYMENT_RUBY)

export const addCurrencyToCart = (cartList: ICartItemCurrency[]) => {
    setCookie('cartCurrencyItems', cartList, { maxAge: 60 * 60 * 24 * 365 })
    document.dispatchEvent(new CustomEvent('cart_currency_event', { detail: cartList as ICartItemCurrency[] }))
}
