export const settingsPage = {
    'settings.page.title': '设置',
    'settings.page.upload': '上传照片',
    'settings.page.username': '用户名',
    'settings.page.email': '电子邮件',
    'settings.page.name': '名字',
    'settings.page.surname': '姓',
    'settings.page.country': '国家',
    'settings.page.social.media': '连接社交媒体',
    'settings.page.delete.account': '删除帐户',
    'settings.page.save': '保存',
    'settings.page.social.unlink.modal.title': '断连社交网络',
    'settings.page.social.unlink.modal.description': '你确认要从个人资料中断开社交媒体帐户的连接吗？将无法取消此操作',
    'settings.page.usernameinfo.modal.title': '用户名信息',
    'settings.page.delete.account.modal.title': '删除帐户',
    'settings.page.delete.account.modal.description': '你确定要删除你在 R.Toys 平台上的账户吗？请联系我们的支持团队',
    'settings.page.delete.avatar.modal.title': '删除照片',
    'settings.page.delete.avatar.modal.description': '你确定要删除上传的照片吗？将无法取消此操作',
    'settings.page.bio': '简介',
    'settings.page.bio.placeholder': '介绍一下自己吧，否则你的个人资料会有个难看的空白块 :(',
}
