export const header = {
    'header.feed': '消息',
    'header.explore': '探索',
    'header.games': '游戏',
    'header.my.toys': '我的玩具',
    'header.account.settings': '账户设置',
    'header.faq': '常见问题',
    'header.support': '支持',
    'header.logout': '登出',
    'header.your.balance': '你的余额',
    'header.change.language': '更改语言',
    'header.english': 'English',
    'header.russian': 'Русский',
    'header.chinese': '中文',
    'header.terms.of.use': '使用条款',
    'header.privacy.policy': '隐私政策',
}
