export const settingsPage = {
    'settings.page.title': 'Настройки',
    'settings.page.upload': 'Загрузить фото',
    'settings.page.username': 'Имя пользователя',
    'settings.page.email': 'Электронная почта',
    'settings.page.name': 'Имя',
    'settings.page.surname': 'Фамилия',
    'settings.page.country': 'Страна',
    'settings.page.social.media': 'Подключить социальные сети',
    'settings.page.delete.account': 'Удалить аккаунт',
    'settings.page.save': 'Сохранить',
    'settings.page.social.unlink.modal.title': 'Отключить социальную сеть',
    'settings.page.social.unlink.modal.description': 'Вы подтверждаете, что хотите отключить социальную сеть от своего профиля? Отменить это действие будет невозможно',
    'settings.page.usernameinfo.modal.title': 'Информация о имени пользователя',
    'settings.page.delete.account.modal.title': 'Удаление аккаунта',
    'settings.page.delete.account.modal.description': 'Вы действительно хотите удалить свой аккаунт на платформе R.Toys? Для этого, пожалуйста, свяжитесь с нашей службой поддержки',
    'settings.page.delete.avatar.modal.title': 'Удалить фото',
    'settings.page.delete.avatar.modal.description': 'Вы уверены, что хотите удалить загруженное фото? Отменить это действие будет невозможно',
    'settings.page.bio': 'О себе',
    'settings.page.bio.placeholder': 'Расскажите о себе. Иначе у вас будет некрасивый белый блок в профиле :(',
}
