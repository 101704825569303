export const faqPage = {
    'faq.page.title': 'Часто задаваемые <span>вопросы</span>',
    'faq.page.block_1.h1': 'О нас',
    'faq.page.block_1.item_1.question': 'Что такое  ArToys?',
    'faq.page.block_1.item_1.answer':
        '<li>                            Это единственная платформа, которая объединяет дизайнеров художественных игрушек, бренды и коллекционеров, стирая границы между цифровыми и физическими предметами коллекционирования в индустрии дизайнерских игрушек.\n                        </li>',
    'faq.page.block_1.item_2.question': 'Что я могу делать с цифровыми игрушками?',
    'faq.page.block_1.item_2.answer':
        '\n                        <li>\n                            Вы можете просматривать их в формате 3D или с помощью дополненной реальности (AR), а также снимать с ними фото и видео. В ближайшем будущем вы сможете  их  персонализировать и использовать в играх.\n                        </li>',
    'faq.page.block_2.h1': 'Веб-сайт',
    'faq.page.block_2.item_1.question': 'Как я могу получить цифровую игрушку?',
    'faq.page.block_2.item_1.answer':
        '\n                        <li>\n                            Чтобы забрать игрушку, войдите в систему и нажмите кнопку «Добавить в коллекцию» на веб-сайте, после этого игрушка появится в вашей коллекции.\n                        </li>',
    'faq.page.block_2.item_2.question': 'Какие устройства поддерживают AR?',
    'faq.page.block_2.item_2.answer':
        '\n                        <li>\n                            Most modern devices support AR and 3D displays. Here is a list of <a href="https://www.apple.com/augmented-reality/" target="_blank" rel="noreferrer">список устройств\n                            iOS</a> поддерживающих AR, а здесь список <a href="https://developers.google.com/ar/devices" target="_blank" rel="noreferrer">устройств Android</a>\n                            Для получения наилучших результатов мы рекомендуем устройства с установленным сканером Lidar (iPhone 12+, iPad Pro).\n                        </li>',
    'faq.page.block_2.item_3.question': 'Должен ли я платить за сбор цифровой игрушки или это бесплатно?',
    'faq.page.block_2.item_3.answer': '\n                        <li>\n                            Игрушки раздаются бесплатно каждый день.\n                        </li>',
    'faq.page.block_2.item_4.question': 'Ограничено ли количество художественных игрушек? Если да, то как узнать, сколько игрушек осталось?',
    'faq.page.block_2.item_4.answer': '\n                        <li>\n                            Количество каждой выпущенной игрушки ограничено и отображается на её странице.\n                        </li>',
    'faq.page.block_2.item_5.question': 'Может ли пользователь изменить свой адрес электронной почты на платформе?',
    'faq.page.block_2.item_5.answer': '\n                        <li>\n                            К сожалению, адрес электронной почты изменить нельзя.\n                            </li>',
    'faq.page.block_2.item_6.question': 'Собираетесь ли вы разработать мобильное приложение?',
    'faq.page.block_2.item_6.answer': '\n                        <li>\n                            Да, на данный момент оно находится в разработке.\n                        </li>',
    'faq.page.block_2.item_7.question': 'Я хотел выполнить задание, но получил сообщение «Доступ запрещён».',
    'faq.page.block_2.item_7.answer':
        '\n                        <li>\n                            Проверьте, возможно, у вас включен VPN или прокси, отключите их, чтобы выполнить задание.\n                        </li>',
    'faq.page.block_3.h1': 'Золото',
    'faq.page.block_3.item_1.question': 'Что такое золото и как его использовать?',
    'faq.page.block_3.item_1.answer':
        '\n                        <li>\n                            Золото является внутренней валютой платформы. Вы можете использовать его для покупки блайндбоксов, игрушек на внутреннем маркете и открывать новые витрины.\n                        </li>',
    'faq.page.block_3.item_2.question': 'Где в своем аккаунте я могу посмотреть сколько у меня золота?',
    'faq.page.block_3.item_2.answer': '\n                        <li>\n                            Вы можете увидеть количество вашего золота в правом верхнем углу платформы.\n                        </li>',
    'faq.page.block_5.h1': 'Внутренний маркетплейс',
    'faq.page.block_5.item_1.question': 'Что такое маркет сообщества и как им пользоваться?',
    'faq.page.block_5.item_1.answer':
        '\n                        <li>\n                            Маркет сообщества — это внутренняя торговая площадка, где можно покупать и продавать игрушки. Здесь вы сможете просматривать различные предложения других пользователей и покупать нужные игрушки для своей коллекции или продавать свои, зарабатывая золото.\n                        </li>',
    'faq.page.block_5.item_2.question': 'Как я могу установить цену на игрушку, которую хочу продать?',
    'faq.page.block_5.item_2.answer':
        '\n                        <li>\n                            Это свободный рынок, на цену могут повлиять многие факторы, так что выбор полностью за вами.\n                        </li>',
    'faq.page.block_5.item_3.question': ' Влияет ли редкость на цену игрушки?',
    'faq.page.block_5.item_3.answer': '\n                        <li>\n                            Да. На цену игрушки может влиять множество факторов.\n                        </li>',
    'faq.page.block_5.item_4.question': 'Могу ли я отменить продажу своей игрушки?',
    'faq.page.block_5.item_4.answer':
        '\n                        <li>\n                            Вы можете отменить продажу своей игрушки в любой момент до её продажи другому пользователю. Зайдите во вкладку "В продаже" нажмите "Редактировать" на карточке с нужной игрушкой и затем, в появившемся окне нажмите кнопку «Отменить продажу».\n                        </li>',
    'faq.page.block_5.item_5.question': 'Нужно ли мне платить комиссию за сделку или это бесплатно?',
    'faq.page.block_5.item_5.answer':
        '\n                        <li>\n                            От стоимости игрушки мы берём комиссию 10%, которая распределяется между художником и платформой.\n                        </li>',
    'faq.page.block_.h1': '',
    'faq.page.block_.item_.question': '',
    'faq.page.block_.item_.answer': '',
}
