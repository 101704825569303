import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { ThemeProvider } from 'next-themes';
import React, { FC } from 'react';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { ParallaxProvider } from 'react-scroll-parallax';
import en from '../lang/en';
import ru from '../lang/ru';
import zh from '../lang/zh';
import superjson from 'superjson';
import AgentProvider from '../Providers/AgentProvider';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AcceptImageProvider from './AcceptProvider';
import { PreviousURLProvider } from '@Providers/PreviousURLContext';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { PersistedClient, PersistQueryClientProvider, removeOldestQuery } from '@tanstack/react-query-persist-client';
import { useRouter } from 'next/router';
import { langTypes } from '@specs/types';
import { Web3Modal } from '@Providers/Web3Modal';
import { ChikoSession } from '@specs/specs';
import { FeedbackProvider } from './FeedbackProvider';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 * 3,
      // 3 days
      staleTime: 1000 * 60 * 15,
      // 15 mins
      refetchOnWindowFocus: false,
      retry: 3
    }
  }
});
type PersistRetryer = (props: {
  persistedClient: PersistedClient;
  error: Error;
  errorCount: number;
}) => PersistedClient | undefined;
const PersistRetryer: PersistRetryer = ({
  persistedClient,
  error,
  errorCount
}) => removeOldestQuery({
  persistedClient,
  error,
  errorCount
});
const persister = createSyncStoragePersister({
  storage: typeof window !== 'undefined' ? window.localStorage : undefined,
  retry: PersistRetryer,
  serialize: superjson.stringify,
  deserialize: superjson.parse,
  throttleTime: 1 // 1ms
});
const messages: Record<langTypes, any> = {
  ru: ru,
  en: en,
  zh: zh
};
interface ProvidersProps extends React.PropsWithChildren {
  session: (Session & ChikoSession) | null | undefined;
  accept: {
    webp: boolean;
  } | null | undefined;
}
const Providers: FC<ProvidersProps> = ({
  children,
  session,
  accept
}) => {
  const router = useRouter();
  return <PreviousURLProvider data-sentry-element="PreviousURLProvider" data-sentry-component="Providers" data-sentry-source-file="index.tsx">
            <ParallaxProvider data-sentry-element="ParallaxProvider" data-sentry-source-file="index.tsx">
                <SessionProvider session={session} data-sentry-element="SessionProvider" data-sentry-source-file="index.tsx">
                    <PersistQueryClientProvider client={queryClient} persistOptions={{
          persister
        }} data-sentry-element="PersistQueryClientProvider" data-sentry-source-file="index.tsx">
                        <ReactQueryDevtools data-sentry-element="ReactQueryDevtools" data-sentry-source-file="index.tsx" />
                        <AgentProvider data-sentry-element="AgentProvider" data-sentry-source-file="index.tsx">
                            <AcceptImageProvider accept={accept} data-sentry-element="AcceptImageProvider" data-sentry-source-file="index.tsx">
                                <ThemeProvider defaultTheme='dark' enableSystem themes={['dark']} data-sentry-element="ThemeProvider" data-sentry-source-file="index.tsx">
                                    <IntlProvider locale={router.locale || 'en'} messages={messages[router.locale || 'en']} onError={e => {
                  // Игнорируем ошибку при отсутсвии default языка
                  if (e.code === ReactIntlErrorCode.MISSING_DATA) {
                    return;
                  }
                }} data-sentry-element="IntlProvider" data-sentry-source-file="index.tsx">
                                        <Web3Modal data-sentry-element="Web3Modal" data-sentry-source-file="index.tsx">
                                            <FeedbackProvider data-sentry-element="FeedbackProvider" data-sentry-source-file="index.tsx">{children}</FeedbackProvider>
                                        </Web3Modal>
                                    </IntlProvider>
                                </ThemeProvider>
                            </AcceptImageProvider>
                        </AgentProvider>
                    </PersistQueryClientProvider>
                </SessionProvider>
            </ParallaxProvider>
        </PreviousURLProvider>;
};
export default Providers;