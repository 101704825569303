export const staleTimeIndex = process.env.STALE_TIME_INDEX ?? 1
export const minRubyWithdrawAmount = 100
export const GOLD_FOR_COLLECT_DROP = 1
export const REGISTRATION_COMMENT = 'Registration'
export const COLLECT_MODAL_EVENT = 'collect_modal'
export const minRubyPriceForSelling = 1
export const minGoldPriceForSelling = 50
export const COLLECT_TOY_MODAL_NAME = 'ToyEarnStatus'
export const remotePath = '/mirror/avatars'
export const HEADER_ID = 'HEADER_ID'
