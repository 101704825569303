export const header = {
    'header.feed': 'Feed',
    'header.explore': 'Explore',
    'header.games': 'Games',
    'header.my.toys': 'My Toys',
    'header.account.settings': 'Account Settings',
    'header.faq': 'FAQ',
    'header.support': 'Support',
    'header.logout': 'Logout',
    'header.your.balance': 'Your Balance',
    'header.change.language': 'Change language',
    'header.english': 'English',
    'header.russian': 'Russian',
    'header.chinese': '中文',
    'header.terms.of.use': 'Terms of Use',
    'header.privacy.policy': 'Privacy Policy',
}
