export const buttons = {
    'btn.view.all': 'Показать всех',
    'btn.see.more': 'Показать больше',
    'btn.join': 'Присоединиться',
    'btn.collect.now': 'Collect',
    'btn.collected': 'В коллекции',
    'btn.buy.now': 'Купить',
    'btn.join.now': 'Присоединиться!',
    'btn.share': 'Поделиться',
    'btn.bring.to.life': 'Cмотреть в AR',
    'btn.to.figure': 'К игрушке',
    'btn.full.collection': 'Вся коллекция',
    'btn.confirm.sale': 'Подтвердить продажу',
    'btn.confirm': 'Подтвердить',
    'btn.save': 'Сохранить',
    'btn.marketplace': 'Маркетплейс',
    'btn.accept': 'Принять',
    'btn.report': 'Пожаловаться',
    'btn.cancel': 'Отмена',
    'btn.unblock': 'Разблокировать',
    'btn.reset': 'Сброс',
    'btn.go.to.main': 'Перейти на главную',
    'btn.select': 'Выбрать игрушку',
    'btn.open.next': 'Открыть',
    'btn.close': 'Закрыть',
    'btn.loading': 'Загрузка...',
    'btn.buy.ticket': 'Купить билет',
    'btn.change.photo': 'Изменить фото',
    'btn.change': 'Изменить',
    'btn.delete': 'Удалить',
    'btn.save.changes': 'Сохранить изменения',
    'btn.sell.now': 'Продать',
    'btn.view.more': 'Подробнее',
    'btn.burn.now': 'Сжечь',
    'btn.ok': 'OK',
    'btn.try.again': 'Попробовать снова',
    'btn.skip': 'Пропустить',
    'btn.collect': 'Собрать',
    'btn.go.to.cart': 'В корзину',
    'btn.go.to.shop': 'В магазин',
    'btn.play': 'Играть',
    'btn.explore': 'Перейти в коллекцию',
    'btn.create.board': 'Создать витрину',
    'btn.put.on.sale': 'Выставить на продажу',
    'btn.buy.gold': 'Купить золото',
    'btn.go.to.toys': 'К игрушкам',
    'btn.go.subscribe.now': 'Подписаться',
    'btn.go.watch.a.video': 'Посмотреть видео',
    'btn.go.complete.tasks': 'Выполнить задания',
    'btn.join.art.club': 'Присоединиться к Art клубу',
    'btn.go.to.explore': 'Перейти в "Поиск"',
    'btn.continue': 'Продолжить',
    'btn.cancel.selling': 'Отменить продажу',
    'btn.sell': 'Продать',
    'btn.buy': 'Купить',
    'btn.burn': 'Сжечь',
    'btn.apply': 'Применить',
    'btn.see.all': 'Смотреть все',
    'btn.cancel.subscription': 'Отменить подписку',
    'btn.support.chat': 'Чат с поддержкой',
    'btn.go.watch.ads': 'Посмотреть видео',
    'btn.send': 'Отправить',
    'btn.send.request': 'Отправить заявку',
    'btn.check': 'На проверке',
    'btn.load.more': 'Больше',
    'btn.download': 'Скачать',
    'btn.renew.subscription': 'Продлить подписку',
    'btn.make.offer': 'Сделать Предложение',
    'btn.claim': 'Забрать',
    'btn.sign': 'Подписать',
    'btn.signed': 'Подписано',
    'btn.connect.wallet': 'Подключить кошелек',
    'btn.claim.now': 'Забрать сейчас',
    'btn.transfer': 'Перевести',
    'btn.paid': 'Оплачено',
    'btn.get.premium': 'Получить премиум',
    'btn.invite.friends': 'Пригласить друзей и заработать',
    'btn.collect.toys': 'Собирать игрушки',
    'btn.buy.full.set': 'Купить полный набор',
    'btn.buy.one.more': 'Купить еще один блайндбокс',
    'btn.sell.blindbox': 'Продать мой блайндбокс',
    'btn.how.it.works': 'Как это работает',
    'btn.open': 'Открыть',
    'btn.not.available': 'Недоступно',
    'btn.go.to.blindbox': 'Перейти к блайндбоксу',
    'btn.edit.profile': 'Редактировать профиль',
    'btn.analytics': 'Аналитика',
    'btn.generate.qr': 'Сгенерировать QR',
    'btn.download.qr': 'Скачать QR',
    'btn.change.image': 'Изменить обложку',
    'btn.edit.draft': 'Редактировать черновик',
    'btn.on.moderation': 'На модерации',
    'btn.change.info': 'Изменить информацию',
    'btn.show.analytics': 'Показать аналитику',
    'btn.upload.photo': 'Загрузить фото',
    'btn.edit': 'Редактировать',
    'btn.buy.currency': 'Купить валюту',
    'btn.go.to.my.toys': 'Перейти в Мои игрушки',
    'btn.new.post': 'Новый пост',
    'btn.crypto': 'Криптовалюта',
    'btn.credit.card': 'Кредитная карта',
    'btn.withdraw': 'Вывести',
    'btn.go.to.chat': 'Перейти в Чат',
    'btn.login': 'Войти',
    'btn.go.to.feed.page': 'Перейти на страницу Ленты',
    'btn.wallet': 'Кошелек',
    'btn.connect.to.wallet': 'Подключить Кошелек',
    'btn.connect.to.facebook': 'Подключить Facebook',
    'btn.connect.to.google': 'Подключить Google',
    'btn.connect.to.telegram': 'Подключить Telegram',
    'btn.connect.to.vk': 'Подключить ВКонтакте',
    'btn.connect.to.discord': 'Подключить Discord',
    'btn.disconnect': 'Отключить',
    'btn.support': 'Поддержка',
    'btn.delete.account': 'Удалить аккаунт',
    'btn.saved': 'Сохранено',
    'btn.back': 'Назад',
    'btn.show.more': 'Показать больше',
    'btn.show.less': 'Показать меньше',
    'btn.publish': 'Опубликовать',
    'btn.upload.image': 'Загрузить изображение',
    'btn.upload.renders': 'Загрузить рендеры',
    'btn.remove': 'Удалить',
    'btn.add.more': 'Добавить ещё',
    'btn.create.toy': 'Создать игрушку',
    'btn.follow': 'Подписаться',
    'btn.following': 'Вы подписаны',
    'btn.unfollow': 'Отписаться',
    'btn.store': 'Магазин',
    'btn.generate': 'Сгенерировать',
    'btn.create.new': 'Создать новое',
    'btn.block.this.author': 'Заблокировать автора',
    'btn.report.this.author': 'Пожаловаться',
    'btn.go.to.author': 'Перейти к автору',
    'btn.block': 'Заблокировать',
}
