export const footer = {
    'footer.title': 'Погрузитесь в мир дизайнерских игрушек вместе с нами!',
    'footer.join': 'Присоединяйтесь к международному сообществу <br/> коллекционеров в Telegram!',
    'footer.choose.theme': 'Выберите тему:',
    'footer.dark': 'Темная',
    'footer.light': 'Светлая',
    'footer.link_1': 'О проекте',
    'footer.link_2': 'Тех. поддержка',
    'footer.link_3': 'Политика использования',
    'footer.link_4': 'FAQ',
    'footer.link_5': 'Для партнеров',
    'footer.link_6': 'Художникам',
    'footer.link_7': 'Условия использования',
    'footer.telegram.title': 'Присоединяйтесь к международному сообществу коллекционеров в Telegram!',
    'footer.telegram.placeholder': 'Выберите язык чата',
}
