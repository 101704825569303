export const paymentHistoryPage = {
    'payment.history.page.title.purchase': '购买',
    'payment.history.page.title.collected.drop': '已收集 {dropType} 掉落',
    'payment.history.page.title.purchase.offer': '购买报价',
    'payment.history.page.title.sale': '销售',
    'payment.history.page.title.commission': '佣金',
    'payment.history.page.title.burned': '烧毁',
    'payment.history.page.title.return': '返回',
    'payment.history.page.title.subscription': '订阅',
    'payment.history.page.title.sharing': '分享',
    'payment.history.page.title.referral': '推荐',
    'payment.history.page.title.games': '游戏',
    'payment.history.page.title.social.networks': '社交网络',
    'payment.history.page.title.registration': '注册',
    'payment.history.page.title.drop.skipped': '缩短掉落时间',
    'payment.history.page.title.accrual': '累计',
    'payment.history.page.title.royalty': '版税',
    'payment.history.page.title.withdrawal': '提现',
    'payment.history.page.title.error': '错误',
    'payment.history.page.title.migration': 'Migration',
    'payment.history.page.title.quest': '任务',
    'payment.history.page.text.purchase': '{toyName} {packName} 购买',
    'payment.history.page.text.collected.drop': '{toyName} 已收集',
    'payment.history.page.text.purchase.offer': '{toyName} 报价',
    'payment.history.page.text.gold.purchase': '金币购买',
    'payment.history.page.text.ruby.purchase': '红宝石购买',
    'payment.history.page.text.sale': '{toyName} 销售',
    'payment.history.page.text.commission': '关于 {toyName} 销售',
    'payment.history.page.text.burned': '{toyName} 已烧毁',
    'payment.history.page.text.blindbox.purchase': '{blindboxName} 购买',
    'payment.history.page.text.return': '{toyName} 已返回',
    'payment.history.page.text.subscription': '订阅获得奖励金币',
    'payment.history.page.text.sharing.confirmation': '分享确认奖励',
    'payment.history.page.text.sharing.post': '分享帖子奖励',
    'payment.history.page.text.board.purchase': '{boardName} 购买',
    'payment.history.page.text.games.won': '游戏获胜',
    'payment.history.page.text.games.lost': '游戏输了',
    'payment.history.page.text.verification': '',
    'payment.history.page.text.registration': '注册奖励',
    'payment.history.page.text.games.purchased': '已购买游戏',
    'payment.history.page.text.drop.skipped': '随机掉落计时器跳过',
    'payment.history.page.text.account.connection': '账户连接奖励',
    'payment.history.page.text.accrual.ruby': '管理员应计红宝石',
    'payment.history.page.text.accrual.gold': '管理员应计金币',
    'payment.history.page.text.return.ruby': '管理员返还红宝石',
    'payment.history.page.text.return.gold': '管理员返还金币',
    'payment.history.page.text.accrual.gold.promocode': '促销代码应计金币',
    'payment.history.page.text.accrual.ruby.promocode': '促销代码应计红宝石',
    'payment.history.page.text.royalty': '购买 {toyName} 的版税',
    'payment.history.page.text.withdrawal.ruby': '红宝石提现',
    'payment.history.page.text.error': '提现被拒绝',
    'payment.history.page.text.referral.award': '{referralAward}',
    'payment.history.page.text.board': '展示板',
}
