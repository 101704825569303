export const report = {
    'report.reason.title': 'Report',
    'report.reason.subtitle': 'Why are you reporting this {target}?',
    'report.reason.text': 'Your report is anonymous. Do not worry. Please call emergency service if someone in danger.',
    'report.reason.dislike': 'I just don’t like it',
    'report.reason.bullying': 'Bullying or unwanted contact',
    'report.reason.selfHarm': 'Suicide, self-injury or eating disorders',
    'report.reason.violence': 'Violence, hate or explotioation',
    'report.reason.restrictedItems': 'Selling or promoting restricted items',
    'report.reason.adultContent': 'Nudity or sexual activity',
    'report.reason.scam': 'Scam, fraud of spam',
    'report.reason.falseInformation': 'False information',
    'report.block.subtitle': 'Thanks for your feedback',
    'report.block.text': 'We use these reports to show you less of this kind of content in the future',
    'report.block.question': 'Do you want to block author?',
    'report.block.yes': 'Yes, block this author, please',
    'report.block.no': 'I don’t want to block this author',
    'report.target.post': 'post',
    'report.target.author': 'author',
}
