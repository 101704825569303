export const authPage = {
    'auth.page.title': 'Get started',
    'auth.page.tab.login': 'Login',
    'auth.page.tab.register': 'Register',
    'auth.page.email.input.label': 'Email',
    'auth.page.email.input.placeholder': 'Enter your email',
    'auth.page.checkbox.label': 'I agree to the processing of my personal data according to <privacy>Privacy Policy</privacy> and <terms>Terms of Use</terms>',
    'auth.page.having.trouble': 'Having trouble?',
    'auth.page.code.title': 'Check your Email <br/> to confirm the account',
    'auth.page.code.text': 'We sent you a 4 digits code',
    'auth.page.code.resend': 'Resend code',
    'auth.page.continue.with': 'Continue with',
    'auth.page.sing.in.wallet': 'Sign in Wallet',
    'auth.page.connect.wallet': 'Connect wallet',
    'auth.page.incorrectly.email': 'Address entered incorrectly',
}
