export const buttons = {
    'btn.view.all': 'View all',
    'btn.see.more': 'See more',
    'btn.join': 'Join',
    'btn.collect.now': 'Collect now',
    'btn.collected': 'Collected!',
    'btn.buy.now': 'Buy now',
    'btn.join.now': 'Join now!',
    'btn.share': 'Share',
    'btn.bring.to.life': 'Bring to life',
    'btn.to.figure': 'Go to toy',
    'btn.full.collection': 'Full collection',
    'btn.confirm.sale': 'Confirm sale',
    'btn.confirm': 'Confirm',
    'btn.save': 'Save',
    'btn.marketplace': 'Marketplace',
    'btn.accept': 'Accept',
    'btn.report': 'Report',
    'btn.cancel': 'Cancel',
    'btn.reset': 'Reset',
    'btn.go.to.main': 'Go to main',
    'btn.select': 'Select toys',
    'btn.open.next': 'Open next',
    'btn.close': 'Close',
    'btn.loading': 'Loading...',
    'btn.buy.ticket': 'Buy a ticket',
    'btn.change.photo': 'Change photo',
    'btn.change': 'Change',
    'btn.delete': 'Delete',
    'btn.save.changes': 'Save changes',
    'btn.sell.now': 'Sell now',
    'btn.view.more': 'View more',
    'btn.burn.now': 'Burn now',
    'btn.ok': 'OK',
    'btn.try.again': 'Try again',
    'btn.skip': 'Skip',
    'btn.collect': 'Collect',
    'btn.go.to.cart': 'Go to cart',
    'btn.go.to.shop': 'Go to shop',
    'btn.play': 'Play',
    'btn.explore': 'Explore',
    'btn.create.board': 'Created board',
    'btn.put.on.sale': 'Put on sale',
    'btn.buy.gold': 'Buy gold',
    'btn.go.to.toys': 'Go to toys',
    'btn.go.subscribe.now': 'Subscribe now',
    'btn.go.watch.a.video': 'Watch a video',
    'btn.go.complete.tasks': 'Complete tasks',
    'btn.join.art.club': 'Join Art Club',
    'btn.go.to.explore': 'Go to Explore',
    'btn.continue': 'Continue',
    'btn.cancel.selling': 'Cancel selling',
    'btn.sell': 'Sell',
    'btn.buy': 'Buy',
    'btn.burn': 'Burn',
    'btn.apply': 'Apply',
    'btn.see.all': 'See all',
    'btn.cancel.subscription': 'Cancel Subscription',
    'btn.support.chat': 'Support Chat',
    'btn.send': 'Send',
    'btn.send.request': 'Send request',
    'btn.check': 'Check',
    'btn.download': 'Download',
    'btn.load.more': 'Load more',
    'btn.renew.subscription': 'Renew subscription',
    'btn.claim': 'Claim',
    'btn.make.offer': 'Make Offer',
    'btn.sign': 'Sign',
    'btn.signed': 'Signed',
    'btn.connect.wallet': 'Connect Wallet',
    'btn.claim.now': 'Claim now',
    'btn.transfer': 'Transfer',
    'btn.paid': 'Paid',
    'btn.get.premium': 'Get Premium',
    'btn.invite.friends': 'Invite Friends and Earn',
    'btn.collect.toys': 'Collect Toys',
    'btn.buy.full.set': 'Buy full set',
    'btn.buy.one.more': 'Buy one more blindbox',
    'btn.sell.blindbox': 'Sell my blindbox',
    'btn.how.it.works': 'How it works',
    'btn.open': 'Open',
    'btn.not.available': 'Not available',
    'btn.go.to.blindbox': 'Go to blindbox',
    'btn.edit.profile': 'Edit profile',
    'btn.analytics': 'Analytics',
    'btn.generate.qr': 'Generate QR',
    'btn.download.qr': 'Download QR',
    'btn.change.image': 'Change Toy Image',
    'btn.edit.draft': 'Edit draft',
    'btn.on.moderation': 'On moderation',
    'btn.change.info': 'Change info',
    'btn.show.analytics': 'Show Analytics',
    'btn.upload.photo': 'Upload Photo',
    'btn.edit': 'Edit',
    'btn.buy.currency': 'Buy currency',
    'btn.go.to.my.toys': 'Go to My toys',
    'btn.new.post': 'New post',
    'btn.crypto': 'Crypto',
    'btn.credit.card': 'Credit Card',
    'btn.withdraw': 'Withdraw',
    'btn.go.to.chat': 'Go to Chat',
    'btn.login': 'Login',
    'btn.go.to.feed.page': 'Go to Feed page',
    'btn.wallet': 'Wallet',
    'btn.connect.to.wallet': 'Connect to Wallet',
    'btn.connect.to.facebook': 'Connect to Facebook',
    'btn.connect.to.google': 'Connect to Google',
    'btn.connect.to.telegram': 'Connect to Telegram',
    'btn.connect.to.vk': 'Connect to VK',
    'btn.connect.to.discord': 'Connect to Discord',
    'btn.disconnect': 'Disconnect',
    'btn.support': 'Support',
    'btn.delete.account': 'Delete account',
    'btn.unblock': 'Unblock',
    'btn.saved': 'Saved',
    'btn.back': 'Back',
    'btn.show.more': 'Show more',
    'btn.show.less': 'Show less',
    'btn.publish': 'Publish',
    'btn.upload.image': 'Upload Image',
    'btn.upload.renders': 'Upload Renders',
    'btn.remove': 'Remove',
    'btn.add.more': 'Add More',
    'btn.create.toy': 'Create Toy',
    'btn.follow': 'Follow',
    'btn.following': 'Following',
    'btn.unfollow': 'Unfollow',
    'btn.store': 'Store',
    'btn.generate': 'Generate',
    'btn.create.new': 'Create New',
    'btn.report.this.author': 'Report this author',
    'btn.block.this.author': 'Block this author',
    'btn.go.to.author': 'Go to author',
    'btn.block': 'Block',
}
