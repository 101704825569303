import React, { FC } from 'react';
import cn from 'classnames';
import ResponsiveModal, { Props } from 'react-modal';
import s from './modal.module.scss';
interface ModalProps extends Omit<Props, 'children' | 'onRequestClose' | 'shouldCloseOnOverlayClick'> {
  children: React.ReactNode;
  onClose: () => void | Promise<void>;
  type?: 'dark' | 'white';
  shouldCloseOnOverlayClick?: boolean;
}
const ModalMemo: FC<ModalProps> = ({
  children,
  onClose,
  className,
  overlayClassName,
  portalClassName,
  parentSelector,
  type = 'dark',
  closeTimeoutMS = 300,
  shouldCloseOnOverlayClick = false,
  ...props
}) => <ResponsiveModal onRequestClose={onClose} shouldCloseOnOverlayClick={shouldCloseOnOverlayClick} closeTimeoutMS={closeTimeoutMS} portalClassName={cn(s.modal, portalClassName)} overlayClassName={{
  base: cn(s.modal__overlay, typeof parentSelector !== 'undefined' && s.modal__overlay_parent, typeof overlayClassName === 'object' && overlayClassName?.base),
  afterOpen: cn(s.modal__overlay_open, typeof overlayClassName === 'object' && overlayClassName?.afterOpen),
  beforeClose: cn(s.modal__overlay_close, typeof overlayClassName === 'object' && overlayClassName?.beforeClose)
}} className={{
  base: cn(s.modal__body, typeof className === 'object' && className?.base),
  afterOpen: cn(s.modal__body_open, typeof className === 'object' && className?.afterOpen),
  beforeClose: cn(s.modal__body_close, typeof className === 'object' && className?.beforeClose)
}} parentSelector={parentSelector} ariaHideApp={false} bodyOpenClassName={'_lock'} {...props} data-sentry-element="ResponsiveModal" data-sentry-component="ModalMemo" data-sentry-source-file="Modal.tsx">
        {children}
    </ResponsiveModal>;
export const Modal = React.memo(ModalMemo);