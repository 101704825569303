import { dropsKeys } from '../en/drops'

export const drops: Record<dropsKeys, string> = {
    'drops.DAILY': 'Ежедневный',
    'drops.WEEKLY': 'Недельный',
    'drops.ARTCLUB': 'Артклуб',
    'drops.GIFT': 'Подарок',
    'drops.SECRET': 'Секретный',
    'drops.BLINDBOX': 'Блайндбокс',
    'drops.SECRET_PERSONAL': 'Секретный личный',
    'drops.ONBOARDING': 'Подарок новичка',
    'drops.RETRO': 'Ретро',
    'drops.PARTNER': 'Партнёрский',
    'drops.COLLECTION_BONUS': 'Бонус за коллекцию',
    'drops.BUY': 'Платный дроп',

    'drops.QUEST': '',
    'drops.BLINDBOX_FULLSET': '',
    'drops.BLINDBOX_OPEN': '',
    'drops.BLINDBOX_RESERVATION': '',
    'drops.DIGITAL': '',
}
