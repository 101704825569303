export const toys = {
    'toys.title': 'Начните собирать <span>свою коллекцию</span> сейчас',
    'toys.collected.title': 'Собрано сегодня',
    'toys.blindbox.title': 'БЛАЙНДБОКС',
    'toys.partner.title': 'ДРОП ПАРТНЕРА',
    'toys.real.title': 'НАСТОЯЩАЯ ИГРУШКА',
    'toys.real.text': 'Продажа начнётся через',
    'toys.subscription.only': 'Только по <br/> подписке',
    'toys.blindbox.text': 'Первый бесплатно',
    'toys.collect.free': 'Собирайте бесплатные игрушки каждые 24 часа',
    'toys.welcome.title': 'Добро пожаловать в ArToys!',
    'toys.rarity.title': '<span>Таблица</span> редкости',
    'toys.rarity.subtitle': 'У каждой нашей игрушки есть свой тираж:',
    'toys.about.us.title': 'Люди <span>о нас</span>',
    'toys.toys.title': 'Ознакомьтесь с нашими <span>игрушками</span>',
    'toys.leaders.title': 'Топ лидеров <br /> за',
    'toys.leaders.collected': 'Собрано',
    'toys.leaders.earned': 'Заработано',
    'toys.leaders.burned': 'Сожжено',
    'toys.choose.hours': 'Выберите, сколько часов необходимо пропустить?',
    'toys.choose.hours.error': 'У вас недостаточно золота. <br/> Купите золото или выберите более короткий промежуток времени.',

    'toys.retro.title': 'СЛУЧАЙНАЯ',

    'toys.month.price': 'Только 9.99$/Месяц',
    'toys.ruby.for.friends': 'Руби для друзей',
    'toys.premium': 'Премиум',
    'toys.toys.collected': 'Игрушки <br/> Собраны',
    'toys.friends.invited': 'Друзья <br/> Приглашены',
    'toys.free.toys': 'БЕСПЛАТНЫЕ ИГРУШКИ',
    'toys.top.offers': 'ЛУЧШИЕ ПРЕДЛОЖЕНИЯ',
    'toys.set.gifts': 'НАБОР ПОДАРКОВ',
    'toys.collected.all.description': 'Вы собрали все игрушки',
    'toys.daily.title': 'ДНЕВНАЯ',
    'toys.weekly.title': 'НЕДЕЛЬНАЯ',
    'toys.artclub.title': 'АРТ КЛУБ',
    'toys.premium.label': 'Без рекламы с Премиум',
    'toys.skip': 'Пропустить',
    'toys.subscription.description': 'С подпиской вы можете продавать свои игрушки на рынке',
    'toys.subscription.title': 'Подписка',
    'toys.welcome.answer1':
        'ArToys — это центр для коллекционеров игрушек, художников и брендов. Мы предлагаем уникальный опыт с легким доступом, ежедневными сюрпризами и активным сообществом. Создано страстными коллекционерами игрушек для коллекционеров.',
}
