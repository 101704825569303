import { Dictionary } from '@hooks/useTranslation'
import { SpriteIconProps } from '@components/Sprites/SpriteIcon'
import { LinkProps } from 'next/link'
import { type PaymentValueTypes, RarityTypeRoutes, type SortOrderRoutes, type SortStoreItemsRoutes, type toy } from '@specs/specs'
import { NextRouter } from 'next/router'
import { stringify } from 'query-string'
import { PurchaseTypes } from '@prisma/client'

const BASE_URL = ''
export const EXPLORE_BASE_URL = '/explore'

export const PUBLIC_PAGES = [
    'ERROR_404',
    'ERROR_500',
    'ERROR_503',
    'SIGN_IN',
    'SIGN_IN_ERROR',
    'TOY_SELLERS',
    'USER',
    'USER_TOYS',
    'UPLOAD_IMAGE',
    'BANNED',
    'FEED_POSTS',
    'FEED_TOYS',
    'FEED_PHYSICAL',
    'SIGN_IN',
    'EXPLORE',
    'EXPLORE_TOYS',
    'EXPLORE_TOYS_PHYSICAL',
    'EXPLORE_COLLECTIONS',
    'EXPLORE_COLLECTION',
    'EXPLORE_ARTISTS',
    'FAQ',
    'PRIVACY',
    'TERMS',
    'TOY',
    'TOY_PREVIEW',
    'POST',
] as const satisfies Readonly<Array<LinksKeys>>

export const LinksEnum = {
    FEED_POSTS: `${BASE_URL}/`,
    FEED_TOYS: `${BASE_URL}/toys`,
    FEED_PHYSICAL: `${BASE_URL}/physical`,

    POST: `${BASE_URL}/posts/[slug]`,
    POST_CREATE: `${BASE_URL}/posts/create/[[...id]]`,
    SIGN_IN: `${BASE_URL}/auth`,
    SIGN_IN_ERROR: `${BASE_URL}/auth/error`,
    EXPLORE: `${BASE_URL}${EXPLORE_BASE_URL}`,
    EXPLORE_TOYS: `${BASE_URL}${EXPLORE_BASE_URL}/toys`,
    EXPLORE_TOYS_PHYSICAL: `${BASE_URL}${EXPLORE_BASE_URL}/toys/physical`,
    EXPLORE_COLLECTIONS: `${BASE_URL}${EXPLORE_BASE_URL}/collections`,
    EXPLORE_COLLECTION: `${BASE_URL}${EXPLORE_BASE_URL}/collections/[slug]`,
    EXPLORE_ARTISTS: `${BASE_URL}${EXPLORE_BASE_URL}/artists`,

    // GAMES: `${BASE_URL}/games`,
    SETTINGS: `${BASE_URL}/settings`,
    USER_KYC: `${BASE_URL}/user/kyc`,
    TOY: `${BASE_URL}/toy/[slug]`,
    TOY_PREVIEW: `${BASE_URL}/toy/[slug]/preview`,
    TOY_SELL: `${BASE_URL}/toy/[slug]/sell`,
    TOY_SELLERS: `${BASE_URL}/toy/[slug]/sellers`,
    TOY_GENERATED_CODES: `${BASE_URL}/toy/[slug]/generated_codes`,
    CART_TOYS: `${BASE_URL}/cart`,
    // CART_CURRENCY: `${BASE_URL}/cart/currency`,
    PAYMENT_GOLD: `${BASE_URL}/payment/gold`,
    PAYMENT_RUBY: `${BASE_URL}/payment/ruby`,
    PAYMENT_WITHDRAW: `${BASE_URL}/payment/withdraw`,
    PAYMENT_HISTORY: `${BASE_URL}/payment/history`,
    PAYMENT_NIHAO_GATEWAY_FORM: `${BASE_URL}/payment/nihaoPay/payment-gateway-form`,
    PAYMENT_CART_STRIPE: `${BASE_URL}/payment/stripe/payment-gateway-form`,
    PAYMENT_NIHAO_IPN: `${BASE_URL}/payment/nihaoPay/ipn`,
    UPLOAD_IMAGE: `${BASE_URL}/upload-image`,
    TERMS: `${BASE_URL}/terms`,
    PRIVACY: `${BASE_URL}/privacy`,

    USER_TOY_DRAFT: `${BASE_URL}/user/toy-draft`,
    USER: `${BASE_URL}/user/[slug]/posts`,
    USER_TOYS: `${BASE_URL}/user/[slug]/toys`,

    // TODO MVP-2 заменить моковые страницы
    BANNED: `${BASE_URL}/banned`,
    FAQ: `${BASE_URL}/faq`,

    TOY_TASK_COMPETITION: `${BASE_URL}/toy/[slug]/task/competition`,
    TOY_TASK_ADS: `${BASE_URL}/toy/[slug]/task/ads`,
    TOY_TASK_SUBSCRIPTION: `${BASE_URL}/toy/[slug]/task/subscription`,

    ERROR_404: `${BASE_URL}/404`,
    ERROR_500: `${BASE_URL}/500`,
    ERROR_503: `${BASE_URL}/503`,

    SUPPORT: 'https://support.r.toys',
    BECOME_A_PARTNER: 'https://links.r.toys/b2b',
    APPLY_AS_AN_ARTIST: 'https://form.asana.com/?k=8OI8KyO7HugzqZoq_DA4FA&d=1201324350011219',
    JOIN_ART_CLUB: 'https://nft.chikoroko.art',

    // SOCIALS
    TELEGRAM: 'https://t.me/rtoysapp',
    INSTAGRAM: 'https://www.instagram.com/rtoysapp',
    TWITTER: 'https://x.com/rtoysapp',
    YOUTUBE: 'https://www.youtube.com/channel/UCClS_tCFvza8U7kAvht_fIQ',
    FACEBOOK: 'https://www.facebook.com/profile.php?id=61553876019859',

    // API
    API_VERIFY: '/api/verify/[provider]',
    API_VERIFY_WALLET: '/api/verify/wallet',
    TOY_DRAFT_UPLOAD_HANDLER: '/api/cdn/upload',
    API_GA4_AD_FORM_COOKIE: '/api/ga4/adForm/cookie',
    API_GA4_CLIENT_ID: '/api/ga4/clientId',

    // 8th Wall
    EIGHTH_WALL: 'https://ar.r.toys',
} as const

export type LinksKeys = keyof typeof LinksEnum

export const getRtoystUrl = (url: (typeof LinksEnum)[LinksKeys], slug?: string | number) => {
    const base = process.env.NEXT_PUBLIC_BASE_URL || window.location.origin

    const formattedUrl = slug ? url.replace('[slug]', slug.toString()) : url

    return `${base}${formattedUrl}`
}

export interface INavLink extends LinkProps {
    text: Dictionary
    icon: SpriteIconProps
    active_icon: SpriteIconProps
    onlyAuthLink?: boolean
    isDisabled?: boolean
}

export interface PageQueries {
    slug?: string
    secret?: string
    purchasedToysQuery?: string[] | string
    toysSort?: SortOrderRoutes
    priceSortCurrency?: Extract<PaymentValueTypes, 'GOLD' | 'RUBY'>
    storeItemsSort?: SortStoreItemsRoutes
    query?: string
    paymentStatus?: 'success' | 'error'
    collectToyStatus?: 'success'
    toyForSaleId?: toy['id']
    code?: string
    profilePageFilter?: 'all' | 'created' | 'collected' | 'under_moderation' | 'on_sale'
    toysRarity?: RarityTypeRoutes
    purchaseType?: PurchaseTypes
    page?: string
}

export const getToysSortQuery = (storeSort: PageQueries['toysSort']): NonNullable<PageQueries['toysSort']> => storeSort ?? 'newest'
export const getStoreItemsSortQuery = (storeItemsSort: PageQueries['storeItemsSort']): NonNullable<PageQueries['storeItemsSort']> => storeItemsSort ?? 'new'

export const replaceQuery = async (router: NextRouter, newQueries: PageQueries) => {
    const as = router.asPath.split('?')[0]
    const query = stringify({ ...router.query, ...newQueries } satisfies PageQueries)
    await router.replace({ query }, as, { shallow: true })
}

export const isPublicPage = (pathname: string) => PUBLIC_PAGES.some((page) => LinksEnum[page] === pathname)
