import { ConcatS } from '@helpers/types'
import { DropMechanics } from '@prisma/client'

export type dropsKeys = ConcatS<['drops', DropMechanics], '.'>

export const drops: Record<dropsKeys, string> = {
    'drops.DAILY': 'Daily',
    'drops.WEEKLY': 'Weekly',
    'drops.ARTCLUB': 'ArtClub',
    'drops.GIFT': 'Gift',
    'drops.SECRET': 'Secret',
    'drops.BLINDBOX': 'Blindbox',
    'drops.SECRET_PERSONAL': 'Secret personal',
    'drops.ONBOARDING': 'Onboarding',
    'drops.RETRO': 'Retro',
    'drops.PARTNER': 'Partner',
    'drops.COLLECTION_BONUS': 'Collection bonus',
    'drops.BUY': 'Buyable',

    'drops.QUEST': '',
    'drops.BLINDBOX_FULLSET': '',
    'drops.BLINDBOX_OPEN': '',
    'drops.BLINDBOX_RESERVATION': '',
    'drops.DIGITAL': '',
}
