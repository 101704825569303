export const withdrawPage = {
    'withdraw.page.title': 'Withdraw',
    'withdraw.page.tab.withdraw': 'withdraw',
    'withdraw.page.tab.history': 'History',
    'withdraw.page.country.input.title': 'Country',
    'withdraw.page.country.input.placeholder': 'Choose your country',
    'withdraw.page.account.holder.input.title': 'Account Holder (Name)',
    'withdraw.page.account.holder.input.placeholder': 'Enter account holder',
    'withdraw.page.bank.name.input.title': 'Bank Name',
    'withdraw.page.bank.name.input.placeholder': 'Enter bank name',
    'withdraw.page.account.number.input.title': 'Account Number',
    'withdraw.page.account.number.input.placeholder': 'Enter account number',
    'withdraw.page.iban.input.title': 'IBAN',
    'withdraw.page.iban.input.placeholder': 'Enter IBAN',
    'withdraw.page.swift.input.title': 'SWIFT Code',
    'withdraw.page.swift.input.placeholder': 'Enter SWIFT code',
    'withdraw.page.manually.title': 'Manually',
    'withdraw.page.min.withdrawal.description': 'Minimum withdrawal threshold: <br/> {count} Rubies',
    'withdraw.page.input.amount.ruby.placeholder': 'Enter the number of rubies',
    'withdraw.page.kyc.title': 'Get verified first',
    'withdraw.page.your.balance': 'Your balance',
    'withdraw.page.summary.title': 'Total',
    'withdraw.page.summary.support': 'Having trouble? Contact Rtoys Team',
}
