export const toys = {
    'toys.title': '立即开始  <span>你的收集</span> ',
    'toys.collected.title': '今天已领取',
    'toys.blindbox.title': '盲盒',
    'toys.partner.title': '合作伙伴掉落',
    'toys.real.title': '实体玩具',
    'toys.real.text': '销售开始于',
    'toys.subscription.only': '仅限 <br/> 订阅',
    'toys.blindbox.text': '第一个免费',
    'toys.collect.free': '每 24 小时免费领取玩具',
    'toys.welcome.title': '欢迎来到 <br/> 我们的社区！',
    'toys.rarity.title': '<span>稀有度</span> 图表',
    'toys.rarity.subtitle': '我们的每种玩具都有其流通量：',
    'toys.about.us.title': '人员 <span>关于我们</span>',
    'toys.toys.title': '探索我们的 <span>玩具</span>',
    'toys.leaders.title': '最高领先者 <br/> 以',
    'toys.leaders.collected': '已收集',
    'toys.leaders.earned': '已赚取',
    'toys.leaders.burned': '已燃烧',
    'toys.choose.hours': '请选择，你需要多少个小时？',
    'toys.choose.hours.error': '你目前没有足够的金币。 <br / > 请添加金币或选择更短的时间。',
    'toys.retro.title': '随机 玩具',
    'toys.month.price': '仅需 9.99 美元/每月',
    'toys.ruby.for.friends': '好友的Ruby宝石',
    'toys.premium': '高级',
    'toys.toys.collected': '玩具 <br/> 已收集',
    'toys.friends.invited': '好友 <br/> 已邀请',
    'toys.free.toys': '免费玩具',
    'toys.top.offers': '顶级优惠',
    'toys.set.gifts': '系列礼物',
    'toys.collected.all.description': '你已收集所有玩具',
    'toys.daily.title': '每日玩具',
    'toys.weekly.title': '每周玩具',
    'toys.artclub.title': '艺术俱乐部',
    'toys.premium.label': '高级帐户无广告',
    'toys.skip': '跳过',
    'toys.subscription.description': '订阅后你可以在市场上出售你的玩具',
    'toys.subscription.title': '订阅',
    'toys.welcome.answer1': 'ArToys 是玩具收藏家、艺术家和品牌商的聚集地。我们提供与众不同的体验、便捷的访问、每日的惊喜和活跃的社区。由热情的玩具收藏家为收藏家而创建。',
}
