import React, { FC, ReactNode, useEffect, useRef } from 'react';
export const AcceptImageContext = React.createContext<{
  webp?: boolean;
} | null | undefined>(null);
interface AcceptImageProviderProps {
  children: ReactNode;
  accept: {
    webp: boolean;
  } | null | undefined;
}
const AcceptImageProvider: FC<AcceptImageProviderProps> = ({
  children
}) => {
  const value = useRef({
    webp: true
  });
  const support_format_webp = () => {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    } else {
      return false;
    }
  };
  useEffect(() => {
    value.current.webp = support_format_webp();
  }, []);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AcceptImageContext.Provider value={value.current} data-sentry-element="unknown" data-sentry-component="AcceptImageProvider" data-sentry-source-file="AcceptProvider.tsx">
            {/*<AcceptImageContext.Provider value={accept}>*/}
            {children}
        </AcceptImageContext.Provider>
  );
};
export default AcceptImageProvider;