export const goldPlans = {
    'gold.plans.text_1': 'Small steps are the right way to big achievements. This great pack is sure to help kickstart your journey.',
    'gold.plans.text_2': 'This pack is for passionate collectors who know the game and what they want!',
    'gold.plans.text_3': 'Who will be the coolest and richest ChikoRokers? Maybe you? Your toys will definitely look the coolest with this pack!',
    'gold.plans.text_4': 'You are ChikoRoko royalty! We are preparing your crown as we speak! Live long and prosper!',

    'gold.plans.level_1': 'Beginner',
    'gold.plans.level_2': 'Collector level',
    'gold.plans.level_3': 'Super-collector level',
    'gold.plans.level_4': 'True chikoroker level',

    'gold.plans.text_0': "Your starter pack for traveling the world of ChikoRoko! Your first steps start here. This discount is given only once, so don't miss your chance!",
    'gold.plans.level_0': 'Starter pack',
}
