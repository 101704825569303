export const createToy = {
    'create.toy.title': 'Create Toy',
    'create.toy.general.information.title': 'General Information',
    'create.toy.digital.toy': 'Digital Toy',
    'create.toy.digital.twin': 'Digital Twin',
    'create.toy.upload.image.uploaded': 'Uploaded',
    'create.toy.preferred.formats.description': 'Preferred formats: {formats}',
    'create.toy.additional.files': 'Additional Files (optional)',
    'create.toy.toy.name.label': 'Toy Name',
    'create.toy.toy.name.placeholder': 'Type here',
    'create.toy.toy.name.description': 'Create the name of your toy',
    'create.toy.description.label': 'Description (optional)',
    'create.toy.description.placeholder': 'Tell us about your toy',
    'create.toy.alternative.collect.label': 'Alternative Collect',
    'create.toy.alternative.collect.description':
        'An alternative collect is a method of obtaining toys on a platform by performing specific actions, such as subscribing to an author, watching a video, or completing a quest.',
    'create.toy.circulation.label': 'Circulation',
    'create.toy.circulation.description': 'The smaller the quantity the greater the rarity',
    'create.toy.price.title': 'Price',
    'create.toy.price.description': 'Enter value from {minPrice} to your max',
    'create.toy.communication.method': 'Preferred Method of Communication',
    'create.toy.default.email.label': 'Default E-mail',
    'create.toy.email.label': 'E-mail',
    'create.toy.required.email.label': 'E-mail (required)',
    'create.toy.telegram.label': 'Telegram',
    'create.toy.instagram.label': 'Instagram',
    'create.toy.forgot.error': 'You forgot the “@”',
    'create.toy.privacy.text':
        'By submitting this form, you agree to our <privacy>Privacy Policy</privacy> and <terms>Terms of Use</terms>. Your information will be kept confidential and will not be shared with third parties without your consent.',
    'create.toy.digital.toy.modal.title': 'Digital Toy',
    'create.toy.digital.toy.modal.description':
        "This type of toy can be purchased by other users from the user's page and the explore page through a purchase modal (not via the shopping cart). Offers from the artist are always prioritized.",
    'create.toy.digital.twin.modal.title': 'Digital Twin',
    'create.toy.digital.twin.modal.description':
        'This type of toy can be collected by other users through scanning a QR code, which creates a secret link for collection. Artists place these QR codes with the physical toys.',
    'create.toy.upload.render.error.max.files': 'You can upload a maximum of 2 files.',
    'create.toy.upload.render.error.only.one': 'You can upload only 1 {type} file.',
    'create.toy.something.is.wrong': 'Something is wrong',
    'create.toy.uploading.progress': 'Uploading is in progress...',
    'create.toy.might.take.bit.time': 'This might take a bit of time',
}
