import React, { LegacyRef, useState } from 'react';
import s from './button.module.scss';
import cn from 'classnames';
import { SpriteIcon, SpriteIconProps } from '@components/Sprites/SpriteIcon';
import { AllowedId } from '@services/statistic/googleTagIdService';
export enum colorEnum {
  'Black',
  'White',
  'Transparent',
  'Zhen Zhu Bai Pearl',
  'Red',
  'Light-Red',
  'Transparent-Red',
}
type colorType = keyof typeof colorEnum;
export enum sizeEnum {
  'large',
  'medium',
  'small',
  'extra_small',
}
type sizeType = keyof typeof sizeEnum;
export interface ButtonProps {
  id: AllowedId; // Для поиска в DOM для аналитики
  children?: React.ReactNode;
  color: colorType;
  size: sizeType;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icons?: {
    left?: SpriteIconProps;
    right?: SpriteIconProps;
  };
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  loading?: boolean;
  buttonRef?: LegacyRef<HTMLButtonElement>;
}
export const Button = ({
  className,
  onClick,
  children,
  color,
  size,
  icons,
  buttonRef,
  type = 'button',
  disabled = false,
  loading = false,
  id
}: ButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const clickHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (typeof onClick === 'undefined') return;
    setIsLoading(true);
    await onClick(e);
    setIsLoading(false);
  };
  return <button id={id} type={type} className={cn(s.button, ({
    Black: s.button_Black,
    White: s.button_White,
    Transparent: s.button_Transparent,
    'Zhen Zhu Bai Pearl': s.button_zhenZhuBaiPearl,
    Red: s.button_Red,
    'Light-Red': s.button_LightRed,
    'Transparent-Red': s.button_TransparentRed
  } satisfies Record<colorType, string>)[color], ({
    large: s.button_large,
    medium: s.button_medium,
    small: s.button_small,
    extra_small: s.button_extra_small
  } satisfies Record<sizeType, string>)[size], (loading || isLoading) && s.button_loading, disabled && s.button_disabled, className)} onClick={clickHandler} disabled={loading || isLoading || disabled} ref={buttonRef} data-sentry-component="Button" data-sentry-source-file="Button.tsx">
            {typeof icons?.left !== 'undefined' && <SpriteIcon {...icons.left} />}
            {children && <span>{children}</span>}
            {typeof icons?.right !== 'undefined' && <SpriteIcon {...icons.right} />}
        </button>;
};