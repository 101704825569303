export const errors = {
    'error.default': 'Что-то пошло не так. Попробуйте еще раз позже.',
    'error.metamask': 'MetaMask не установлен!',
    'main.drops.notFound': 'Дроп не найден',
    'main.drops.noToy': 'В этом дропе нет игрушек',
    'main.drops.notAvailable': 'Все игрушки уже собраны',
    'main.drops.alreadyOwned': 'У Вас уже есть эта игрушка',
    'main.drops.artClub': 'Только для участников Арт-клуба',
    'main.drops.region.restricted': 'Этот дроп недоступен в Вашем регионе',
    'main.drops.wrongSecret': 'Неверный код для секретного дропа',
    'main.blindbox.limit.exhausted': 'Лимит открытия исчерпан',
    'main.blindbox.balance.notenough': 'Недостаточно Золота',
    'main.blindbox.toys.empty': 'Все игрушки разобраны',
    'main.blindbox.color.dublicate': 'Этот цвет уже открыт',
    'main.bonusToy.alreadyOwned': 'Бонусная игрушка уже получена',
    'main.bonusToy.notBonus': 'Это не бонусная игрушка',
    'main.bonusToy.notAvailable': 'Больше нет доступных игрушек',
    'main.bonusToy.notAllCollected': 'Не все игрушки собраны',
    'main.profiles.shortId.conflict': 'Ник уже занят',
    'main.profiles.shortId.length.min': 'Длина ника должна быть более 5 символов',
    'main.profiles.shortId.length.max': 'Длина ника не должна превышать 20 символов',
    'main.profiles.shortId.filter': 'Ник может состоять только из символов и цифр',
    'main.rack.not.owner': 'Редактировать полку может только владелец',
    'main.rack.free.limit.exhausted': 'Только 3 полки доступны бесплатно',
    'main.rack.balance.notenough': 'Недостаточно золота',
    'main.rack.profile.not.found': 'Профиль не найден. Пожалуйста, войдите в систему снова',
    'main.leaderboard.stats.notFound': 'Статистика пользователя не найдена. Это нормально для новых пользователя',
    'main.profiles.notFound': 'Профиль не найден',
    'main.bonusToy.notfound': 'Игрушка не найдена',
    'burn.not.owner': 'Вы должны быть владельцем',
    'burn.toy.notfound': 'Игрушка не найдена',
    'burn.toy.one.restricted': 'Невозможно сжечь игрушку редкости "One and only"',
    'burn.config.rarity.notfound': 'Ошибка: редкость не найдена',
    'burn.config.rate.notfound': 'Ошибка: ставки редкости не найдены',
    'burn.toy.supplied.null': 'Ошибка: игрушки отсутствуют',
    'burn.toy.limit.exhausted': 'Вы можете сжечь не более 50 игрушек в день',
    'burn.toy.store.exists': 'Вы не можете сжечь игрушку, которая находится в продаже. Снимите игрушку с продажи',
    'main.unauthurised': 'Сначала войдите в систему',
    'main.upload.no.nontent-type': 'Отсутствует заголовок',
    'main.upload.not.image': 'Не является изображением',
    'main.upload.not.supported': 'Такой тип файла не поддерживается',
    'main.rack.worng.price': 'Несоответствие цены',
    'cloud.intent.error': 'Ошибка во внешнем сервисе. Невозможно инициализировать загрузку.',
    'cloud.intent.not.found': 'Загруженный файл не найден по указанному идентификатору',
    'cloud.intent.draft': 'Загрузка еще не завершена',
    'burn.toy.rack.member': 'Для начала удалите игрушку из витрины',
    'main.store.toy.restricted': 'Данный предмет не предназначен для продажи',
    'main.store.purchase.price.mismatch': 'Цена была изменена',
    'main.store.purchase.item.not.found': 'Товар на продажу не найден. Вероятно, его уже купили.',
    'main.kyc.required': 'Сначала необходимо пройти верификацию',
    'main.profiles.shortId.limit': 'Вы можете изменить свое имя пользователя только один раз в 24 часа',
    'main.store.notFound': 'Товар не найден в магазине',
    'main.store.price.negative': 'Цена не может быть равной или меньше 0',
    'main.store.price.fractional': 'Цена не может иметь дробную часть',
    'main.store.ownership.not.found': 'Вы должны быть владельцем',
    'main.store.dublicate': 'Эта игрушка уже находится на продаже',
    'system.ban.dublicate': 'Заблокировано',
    'system.profile.notfound': 'Профиль не найден',
    'system.transaction.error': 'Ошибка системы. Пожалуйста, попробуйте еще раз или свяжитесь с службой технической поддержки',
    'store.item.not.available': 'Игрушка с этой ценой и номером больше не доступна.',
    'store.item.ended': 'Извините, вы опоздали. Игрушки больше нет.',
    'cart.is.full': 'Извините, ваша корзина заполнена! Вы можете добавить только 15 предметов в корзину',
    'main.rack.variation.not.found': 'Вариация витрины с предоставленным идентификатором не найдена',
    'main.competitions.no.available.': 'Нет доступных квестов.',
    'main.competitions.not.completed': 'Еще не все действия были завершены.',
    'main.competitions.already.completed': 'Этот квест уже завершен',
    'main.competitions.wrong.mechanics': 'Соревнуйтесь с другими пользователями. Доступно только для ежедневных и еженедельных дропов',
    'main.competitions.service.error': 'Внешний сервис недоступен. Пожалуйста, попробуйте позже',
    'payments.product.not.found': 'Ошибка системы. Запрошенный продукт не настроен. Пожалуйста, свяжитесь с технической поддержкой',
    'payments.subscription.not.found': 'У этого пользователя нет активного плана подписки',
    'payments.provider.not.supported': 'Этот провайдер не поддерживается',
    'main.drops.retro.timer': '(change me) Время Retro Drop еще не наступило',
    'drops.retro.skipped.dublicate': 'Retro drop уже пропущен',
    'payments.stripe.product.delete.error': 'Произошла ошибка при удалении продукта в Stripe',
    'payments.stripe.product.not.found': 'Не удалось найти подходящий продукт в Stripe',
    'payments.stripe.price.not.found': 'Не удалось найти подходящую цену в Stripe',
    'main.subscription.required': 'This action requires active subscription',
    'promocode.generate.error': 'Не удалось сгенерировать промокоды',
    'promocode.transaction.error': 'Не удалось применить промокоды',
    'promocode.cannot.find.active': 'Не удалось найти активный промокод',
    'main.ads.notWatched': 'Рекламное видео не было просмотренно',
    'main.ads.wrong.mechanics': 'Доступно только для ежедневных и еженедельных дропов',
    'main.draft.donotFind': 'Черновик не найден',
    'main.draft.trx.error': 'Не удалось обработать черновик',
    'main.balance.red': 'У вас недостаточно баланса',
    'main.store.purchase.balance.red': 'У вас недостаточно баланса',
    'system.idempotency.error': 'Эта операция уже выполнена',
    'personal.account.myToy.upload.model.need.to.upload': 'Ошибка загрузки модели',
    'personal.account.myToy.upload.model.need.to.allFileds': 'Необходимо заполнить основные поля',
    'payout.balance.limit.exhausted': 'Минимальная сумма вывода - 100 рубинов. Пожалуйста, повторите попытку позже',
    'gold.give.error': 'Нельзя обновить больше чем пришло.',
    'swapUserEmails.old.user.not.found': 'старый аккаунт не найден',
    'swapUserEmails.new.user.not.found': 'новый аккаунт не найден',
    'swapUserEmails.cannot.swap.regular.users': 'не удалось поменять местами профили пользователей с обычной почтой',
    'swapUserEmails.cannot.swap.accounts.users': 'не удалось поменять местами профили пользователей с профилями содержащими аккаунты',
    'swapUserEmails.cannot.swap.regular.account.users': 'не удалось поменять местами профили пользователей со старым аккаунтом google | apple и новым с обычной почтой',
    'swapUserEmails.cannot.swap.account.regular.users': 'не удалось поменять местами профили пользователей с новым аккаунтом google | apple и старым с обычной почтой',
    'swapUserEmails.first.package.error': 'ошибка в первом пакете транзакции',
    'swapUserEmails.second.package.error': 'ошибка во втором пакете транзакции',
    'swapUserEmails.profiles.not.found': 'не удалось получить профили пользователей',
    'swapUserEmails.profile.artists.swap': 'не удалось объединить художников пользвоателей',
    'swapUserEmails.profile.avatar.swap': 'не удалось объединить аватарки пользвоателей',
    'kyc.always.green': 'Kyc always Green',
    'payments.subscription.isPendingFinish': 'Подписка уже была отключена',
    'share.not.valide.code': 'This is not valide code to this post',
    'share.not.exist': 'Не удалось найти SharePost',
    'toy.not.exist': 'Не удалось найти игрушку',
    'artist.not.exist': 'Не удалось найти артиста',
    'cannot.create.share.post': 'Не удалось создать SharePost',
    'share.cannot.transfer.100.gold': 'невозможно перевести 100 золота',
    'share.cannot.transfer.25.gold': 'невозможно перевести 25 золота',
    'share.cannot.transfer.wright.reward': 'не удалось записать информацию о подтверждении',
    'share.cannot.found.any.posts': 'share.cannot.found.any.posts',
    'share.cannot.apply.ur.own.code': 'Вы не можете подтвердить свой код',
    'openEdition.toy.is.used.in.drop': 'Игрушка уже используется в другом дропе, ее нельзя использовать в механике openShare',
    'openEdition.is.not.found': 'У игорушки не было найдено активного openEdition статуса',
    'rarity.not.found': 'Редкость не найдена',
    'openEdition.incorrect.time.range': 'Неправильный временной диапазон для открытого издания',
    'elastic.update.index.error': 'Ошибка обновления индекса в Elastic',
    'elastic.update.index.success': 'Успешное обновление индекса в Elastic',
    'collection.pack.save.rates.error': 'Не удалось сохранить ставки редкости пакета',
    'collection.pack.save.rates.success': 'Успешное сохранение ставок редкости пакета',
    'partner.line.collection.dont.match': 'Коллекция партнера не соответствует',
    'partner.dont.find': 'Партнер не найден',
    'partner.line.length.dont.metch': 'Длины строк должны быть такими же, как у остальных строк партнеров, убедитесь, что вы ввели правильные значения',
    'partner.pack.delay': 'Прошло как минимум 2 минуты с момента последней покупки',
    'main.partners.line.notcollected': 'Не каждая игрушка из линии собрана',
    dateNotAllowed: 'Дата публикации еще не наступила',
    'convert.usd.error': 'Не удалось конвертировать USD в другую валюту',
    'convert.currency.do.not.found': 'Валюта не найдена',
    'offers.price.min.gold': 'Цена должна быть больше 50 золота',
    'offers.price.min.ruby': 'Цена должна быть больше 1 рубина',
    'offers.instanceid.not.found': 'Этот номер игрушки не собран',
    'offers.offer.not.found': 'Предложение не найдено',
    'offers.is.not.owner': 'Это предложение было создано другим пользователем. Невозможно выполнить операцию',
    'offers.ownership.not.found': 'Собственность не найдена',
    'partner.line.lineMechanics.dont.match': 'lineMechanics не соответствует',
    'shippingRequest.create.error': 'Не удалось отправить запрос на доставку',
    'partner.line.mechanic.dont.exist': 'Механика партнера не существует',
    'partner.line.rarites.dont.match': 'Редкости линии партнера не соответствуют',
    'ownership.not.owner': 'Вы должны быть владельцем этого актива',
    'ownership.already.minted': 'NFT уже создан',
    'system.wallet.not.connected': 'Вы должны подключить кошелек для выполнения этой операции',
    'wallet.connected.to.another.account': 'Извините, вы не можете подключить этот кошелек. Пожалуйста, подключите кошелек, который вы предоставили ранее.',
    'system.contract.error': 'Ошибка взаимодействия с блокчейном. Пожалуйста, обратитесь в службу поддержки',
    'store.nft.not.owner': 'Эта игрушка имеет созданный NFT. Адрес владельца должен совпадать с адресом вашего подключенного кошелька, чтобы выполнить эту операцию.',
    'ownership.mint.partner.required': 'В настоящее время майнтинг разрешен только для коллекций партнеров',
    'quest.not.completed': 'Требования к квесту не выполнены',
    'quest.already.completed': 'Квест уже выполнен',
    'quest.finished': 'Квест уже завершен',
    'quest.profile.already.have.subscription': 'Этот квест доступен только для пользователей без подписки',
    'offersBanner.not.found': 'Баннер предложений данного типа не найден',
    'blindbox.is.not.exist': 'доступных блайндбоксов не найдено',
    'blindbox.totalProbability.is.not.correct': 'проблема с вероятностями выпадения, обратитесь в службу поддержки',
    'blindbox.collect.noToy': 'не удалось связать блайндбокс с случайной игрушкой',
    'blindbox.collect.notAvailable': 'Все игрушки уже собраны',
    'blindbox.collect.trx.error': 'Ошибка при обработке Блайндбокса',
    'not.optimal.ruby.set': 'Не удалось сформировать оптимальный набор продуктов',
    'toy.mint.disabled': 'Чеканка этой игрушки невозможна',
    'main.invoice.notFound': 'Счет не найден',
    'main.coupon.notFound': 'Купон не найден',
    'main.form.fields.required': 'Это поле обязательно для заполнения',
    'main.not.qr.exists': 'Это имя уже используется',
    'personal.account.myToy.toy.name.min': 'Длина названия игрушки должна быть больше 5 символов',
    'personal.account.myToy.toy.name.max': 'Длина названия игрушки должна быть меньше 20 символов',
    'coupon.noVerify.requires.subscription': 'Невозможно проверить купон. Требуется подписка.',
    'coupon.validFrom.not.come': 'Дата начала действия купона еще не наступила.',
    'coupon.validUntil.end': 'Срок действия купона истек.',
    'coupon.alredy.used': 'Купон уже использован.',
    'coupon.purchaseLimit.miss': 'Превышен лимит покупок по купону.',
    'coupon.minimumSpendThreshold.miss': 'Не достигнут минимальный порог расходов для купона.',
    'coupon.currencyType.dont.match': 'Тип валюты некоторых продуктов неверен.',
    'coupon.not.reusable': 'Купон нельзя повторно использовать для этого продукта.',
    'toy.mint.no.contract': 'Нет настроенного контракта для этой игрушки',
    'main.social.delete.error': 'Чтобы отвязать свою социальную сеть, добавьте альтернативный способ входа в аккаунт.',
    'main.store.price.too.high': 'Число слишком велико. Попробуйте еще раз',
    'post.not.author': 'Это не ваш пост для редактирования',
    'upload.image.413': 'Размер запроса превышает допустимый предел',
    'personal.account.myToy.upload.model.error': 'Ошибка загрузки модели {type}',
    'create.toy.invalid.form': 'Некорректная форма',
    'create.toy.toy.name.min.error': 'Длина названия игрушки должна быть больше {min} символов',
    'create.toy.toy.name.max.error': 'Длина названия игрушки должна быть меньше {max} символов',
    'create.toy.upload.image.error': 'Не удалось загрузить. Пожалуйста, проверьте ваш файл',
    'create.toy.price.error': 'Введите цену выше минимальной',
    'create.toy.circulation.error': 'Необходимо указать тираж',
    'crop.image.error': 'Ошибка при обрезке изображения',
    'no.file.provided': 'Файл не предоставлен',
    'upload.failed': 'Ошибка загрузки',
    'please.check.your.file': 'Пожалуйста, проверьте ваш файл {type}',
    'generate.qr.codes.max.length': 'Максимальное количество QR-кодов — 200',
    'error.block.alreadyBlocked': 'Вы уже заблокировали этого пользователя',
}
