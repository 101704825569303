export const paymentsPage = {
    'payments.pages.currency.title': 'Купить валюту',
    'payments.pages.tab.history': 'История',
    'payments.pages.checkout.currency.modal.title': 'Оформление покупки',
    'payments.pages.checkout.currency.modal.to.buy': 'Купить',
    'payments.pages.checkout.currency.modal.price.in.dollars': 'Цена в долларах',
    'payments.pages.withdraw.card.title': 'Вывести',
    'payments.pages.withdraw.card.description': 'Вы всегда можете конвертировать рубины в доллары на нашей платформе (минимальная сумма вывода — 100 рубинов)',
    'payments.pages.history.will.be.soon': 'Этот раздел скоро появится',
    'payments.pages.payment.toast.success.text': 'Ваш баланс пополнен',
    'payments.pages.payment.toast.error.text': 'Произошла ошибка, попробуйте оплатить еще раз.',
}
