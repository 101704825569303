export const quiz = {
    'quiz.message': 'Please take a short survey about the usability of the application.',
    'quiz.go': 'Ok',
    'quiz.survey': 'Survey',
    'quiz.end.title': 'Thank you for your response!',
    'quiz.end.text': 'Your opinion is important to us, we will definitely listen to it to improve our product.',
    'quiz.how.convenient': 'How convenient is the product for you to use?',
    'quiz.comment.label': 'Please leave your comments here',
    'quiz.comment.placeholder': 'Tell us what you liked',
    'quiz.question.1': 'How long have you been using R.Toys',
    'quiz.answer.1.1': 'Less than a month',
    'quiz.answer.1.2': '1-6 Months',
    'quiz.answer.1.3': 'More than 6 months',
    'quiz.question.2': 'How often do you use R.Toys',
    'quiz.answer.2.1': 'Every day',
    'quiz.answer.2.2': 'Several times a week',
    'quiz.answer.2.3': 'Several times a month',
    'quiz.answer.2.4': 'Less often',
    'quiz.question.3': 'How did you hear about R.Toys?',
    'quiz.answer.3.1': 'Advertisement',
    'quiz.answer.3.2': 'Recommendations from friends or acquaintances',
    'quiz.answer.3.3': 'Social networks',
    'quiz.answer.3.4': 'Other sources',
    //
    'quiz.greetings': 'Help us improve R.Toys!',
    'quiz.description': `We're researching how collectors like you discover and buy art toys. Take our`,
    'quiz.time': ' 5–7 min survey',
    'quiz.survey_link': 'Survey link',
    'quiz.take_part': 'Or join a ',
    'quiz.interview': '20–30 min video chat ',
    'quiz.take_part_2': 'to share your insights! (Participants get an exclusive digital art toy!)',
    'quiz.telegram': 'Telegram link',
}
